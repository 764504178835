import * as constants from '../constants/deleteproduct.constants';

export const handleDeleteProduct = (payload) => ({
  type: constants.DELETE_PRODUCT,
  payload
});

export const deleteProductSuccessful = (response) => ({
  type: constants.DELETE_PRODUCT_SUCCESSFUL,
  response
});

export const deleteProductFailed = (payload) => ({
  type: constants.DELETE_PRODUCT_FAILED,
  payload
});
