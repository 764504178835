import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  MenuItem, Select, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import selectDepartmentDetails from '../../redux/selectors/department.selector'
import * as actions from '../../redux/actions/department.actions'

import AddDepartment from './AddDepartment';
import { isEmpty } from 'lodash';

const Department = props =>{
const dispatch = useDispatch()
const {value, handleChange} = props;
const [deptBool, setDeptBool] = useState(false);
const { departmentDetails } = useSelector(selectDepartmentDetails);
const [selectValue, setSelectValue] = useState(value !== '' ? value : 'department');
const {data} = departmentDetails;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
      width: '100%',
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '9px 12px',
      textTransform:'capitalize',
      color: theme.palette.text.primary,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily:'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
}));
useEffect(()=>{
    if(!data){
        dispatch(actions.getDepartment());
    }
}, [data, dispatch]) // eslint-disable-line

const handleClose = (pData) =>{
  if(pData !== null && !isEmpty(pData)){
    const sData = [...data];
    sData.push(pData);
    // console.log('handleclose ', pData, data)
    dispatch(actions.getDepartmentSuccessful(sData));
  }
  setDeptBool(false);
}
const handleSelectChange = (e) =>{
  if(e.target.value === 'adddepartment') {
    setDeptBool(true);
    return
  }
    setSelectValue(e.target.value);
    handleChange(e)
}

return (
    <>
    {deptBool && <AddDepartment handleClose={handleClose} deptData = {data} />}
    <Select size='small' variant='outlined' onChange={handleSelectChange} value={selectValue}
        sx={{...props.style, fontSize:11, fontFamily:'Inter', color:'text.secondary', fontWeight:400}}
        input={<BootstrapInput sx={{marginTop:'0px !important'}}/>} 
        MenuProps={{className:'custom-scrollbar', style: {maxHeight:300}}} 
    >
      {/* {console.log('department ',data)} */}
        { data && data.map((item, index) =>{
            const {dept_id, dept_name} = item;
            return <MenuItem key={`department${index}`} sx={{textTransform:'capitalize'}} value={dept_id}>{dept_name}</MenuItem>
        })
        }
        <MenuItem value='adddepartment'>Add Department</MenuItem>
    </Select>
    </>
)}

export default Department;