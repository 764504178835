import * as constants from '../constants/authentication.constants';

export const initialState = {
  
  userValidation: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
  productsCreated: false,
  isSessionExpired:false
};

export const authenticationReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.VALIDATE_LOGIN:
      return {
        ...state,
        userValidation: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          errorMessage: null
        },
        productsCreated: false
      };
    case constants.LOGIN_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        userValidation: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          errorMessage: null,
          data: action.response
        }
      };
    case constants.LOGIN_FAILED:
      return {
        ...state,
        userValidation: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          errorMessage: payload,
          data: null
        }
      };
    case constants.RESET_AUTH_STATE:
      return {
        ...state,
        userValidation: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          data: null
        }
      };
      case constants.TOGGLE_SESSION_EXPIRED_STATE:
        return {
          ...state,
          isSessionExpired: action.payload
      }
    default:
      return state;
  }
};
