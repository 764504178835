import React, { useState, useRef } from 'react';
// import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Grid } from '@mui/material';
import { setItem } from '../../utils/storage';
import * as brandAction from '../../redux/actions/brands.actions';
import * as categoryAction from '../../redux/actions/categories.actions';
import * as departmentAction from '../../redux/actions/department.actions';

import LeftPanel from '../LeftPanel';
import RightPanel from '../RightPanel';


const MyProduct = props => {
    const [filteringData, setFilteringData] = useState([])
    const [categoringData, setCategoryData] = useState([])
    const [brandFilterData, setBrandFilterData] = useState([])
    const [locationFilterData, setLocationFilterData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [selectedLoc, setSelectedLoc] = useState(null)
    const [page, setPage] = useState(0);
    const [selectedQaUser, setQaUser] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const productRef = useRef()
    const dispatch = useDispatch();
    // const params = useParams();
    const getFilterdData = (filteredProduct, data) =>{
        setFilteredData(filteredProduct);
        setOriginalData(data);
    }
    const handleDeptFilter = (pStr, pData) =>{
        setFilteringData(pData);
        productRef.current.handleFilters(pData, categoringData, brandFilterData, locationFilterData );
    }
    const handleLocationFilter = (pStr, pData) => {
        // console.log('handleLocationFilter', pData)
        setLocationFilterData(pData);
        // productRef.current.handleFilters(filteringData, categoringData, brandFilterData, pData );
    }
    const handleCategoryFilter = (pStr, pData) => {
        // console.log('handleCategoryFilter ', pData)
        setCategoryData(pData)
        productRef.current.handleFilters(filteringData, pData, brandFilterData, locationFilterData );
    }
    const handleBrandFilter = (pStr, pData) => {
        const sData = pData.map(item => escape(item));
        setBrandFilterData(pData)
        productRef.current.handleFilters(filteringData, categoringData, sData, locationFilterData );
    }
    const handleQaUser = (event, userList) =>{
        setItem('qaUser', event.target.value);
        // console.log(userList.filter(item => item.id === event.target.value)[0].currency);
        setItem('currency', userList.filter(item => item.id === event.target.value)[0].currency)
        dispatch(brandAction.getBrands(event.target.value));
        dispatch(categoryAction.getCategories(event.target.value));
        dispatch(departmentAction.getDepartment(event.target.value));
        setQaUser(event.target.value);
    }
    const removeAllFilters = () =>{
        setLocationFilterData([])
        setBrandFilterData([])
        setCategoryData([])
        setFilteringData([])
        setSelectedLoc(null)
        productRef.current.handleFilters([], [], [], [] );
        console.log('removeall');
    }
    return (
        <Grid sx={{height:'auto', px:'0px !important', mt:0}}>
            <Grid container spacing={0} sx={{display:'flex'}}>
                <LeftPanel 
                    handleDeptFilter={handleDeptFilter} 
                    handleBrandFilter={handleBrandFilter}
                    handleCategoryFilter={handleCategoryFilter}
                    handleLocationFilter = {handleLocationFilter}
                    removeAllFilters = {removeAllFilters}
                    selectedLoc = {selectedLoc}
                    filteredData = {filteredData}
                    originalData = {originalData}
                    selectedQaUser = {selectedQaUser}
                    
                />
                <RightPanel
                    ref= {productRef}
                    filteringData = {filteringData}
                    brandFilterData = {brandFilterData}
                    categoringData = {categoringData}
                    locationFilterData = {locationFilterData}
                    setSelectedLoc = {setSelectedLoc}
                    getFilterdData = {getFilterdData}
                    handleQaUser = {handleQaUser}
                    
                    page = {page}
                    setPage = {setPage}
                    setRowsPerPage = {setRowsPerPage}
                    rowsPerPage = {rowsPerPage}

                />
            </Grid>
        </Grid>
    )
}


export default MyProduct;