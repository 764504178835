import * as constants from '../constants/refreshProduct.constants';

export const getRefreshProduct = (payload) => ({
  type: constants.REFRESH_PRODUCT,
  payload
});

export const getRefreshSuccessful = (response) => ({
  type: constants.REFRESH_PRODUCT_SUCCESSFUL,
  response
});

export const getRefreshFailed = (payload) => ({
  type: constants.REFRESH_PRODUCT_FAILED,
  payload
});
export const getRefreshReload = (payload) => ({
  type: constants.REFRESH_PRODUCT_RELOAD,
  payload
});
