import * as constants from '../constants/productprevnext.constants';

export const getProductPrevNext = (payload) => ({
  type: constants.PRODUCT_PREVNEXT_GET,
  payload
});

export const productPrevNextSuccessful = (response) => ({
  type: constants.PRODUCT_PREVNEXT_SUCCESSFUL,
  response
});

export const productPrevNextFailed = (payload) => ({
  type: constants.PRODUCT_PREVNEXT_FAILED,
  payload
});
export const productPrevNextReset = (payload) => ({
  type: constants.PRODUCT_PREVNEXT_RESET,
  payload
});
