import * as constants from '../constants/currency.constants';

export const handleUpdateCurrency = (payload) => ({
  type: constants.UPDATE_CURRENCY,
  payload
});

export const updateCurrencySuccessful = (response) => ({
  type: constants.UPDATE_CURRENCY_SUCCESSFUL,
  response
});

export const updateCurrencyFailed = (payload) => ({
  type: constants.UPDATE_CURRENCY_FAILED,
  payload
});
