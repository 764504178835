import React from 'react';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import CircularPrgs from '../../components/CircularProgress';

const SummaryDetails = props => {

    const {summary, circularSize, themeName} = props;

    // console.log('summary', summary)
    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#232D3B',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        // color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow: 'none'
      }));

    const chartStyle = {backcolor: themeName === 'light'? '#CECECE' :'#515151', padding:'0px 8px'};
    const summeryStyle = {marginLeft:2, padding:'1.6em', width:'-webkit-fill-available', maxWidth:'100%'}

    return  (
        <Grid item xs= {12} sm={12} md={12}
            className='summary'>
                <Item sx={{padding:'1.6em', width:'-webkit-fill-available', maxWidth:'100%'}}>
                    <CircularPrgs sx={{color: "#FF6767", ...chartStyle}} thickness={4} size={circularSize} marginbottom={4.3} variant="determinate" value={100} labelvalue={summary['categoryCount']} label='Categories' />
                </Item>
                <Item sx={summeryStyle}>
                    <CircularPrgs sx={{color: "#DAC934", ...chartStyle}} thickness={4} size={circularSize} marginbottom={4.3} variant="determinate" value={100} labelvalue={summary['brandCount']} label='Brands' />
                </Item>
                <Item sx={summeryStyle}>
                    <CircularPrgs sx={{color: "#559EFF", ...chartStyle}} thickness={4} size={circularSize} marginbottom={4.3} variant="determinate" value={100} labelvalue={summary['productCount']} label='Products' />
                </Item>
                <Item sx={summeryStyle}>
                    <CircularPrgs sx={{color: "#297462", ...chartStyle}} thickness={4} size={circularSize} marginbottom={4.3} variant="determinate" value={100} labelvalue={summary['competitorCount']} label='Competitors' />
                </Item>
        </Grid>
    )
}

export default SummaryDetails;