import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/deletemarketplace.actions';
import * as marketAction from '../actions/marketplace.actions';
import * as constants from '../constants/deletemarketplace.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* deleteMarketPlace(request = {}) {
  const { payload = {} } = request;
  console.log(payload);
  const apiConfig = getApiConfig()
  try {
    const response = yield call(NetworkApi.delete, paths.deletemarketplaceURL, payload,
      apiConfig, );
  //  console.log(response);

    if (response && !response.details) {
          yield put(marketAction.getMarketPlace())
          yield put(actions.deleteMarketPlaceSuccessful(response));
    } else {
      console.log('error ', response)
      const message = response?.details ? (
        response?.details
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.deleteMarketPlaceFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
        error?.message
    ) : (
      ''
    );
    
    yield put(actions.deleteMarketPlaceFailed(message));
  }
}

export function* marketPlaceDelete() {
  yield takeLatest(constants.DELETE_MARKET_PLACE, deleteMarketPlace);
}
