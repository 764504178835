import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/addbulkproduct.actions';
import * as constants from '../constants/addbulkproduct.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}

export function* handleBulkProduct(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  //const { FileList } = payload;
  // console.log(payload[0]);

  let formData = new FormData();
  formData.append('file', payload[0])
  try {
    const response = yield call(NetworkApi.post, paths.addBulkProductURL, formData, apiConfig);
    // console.log('bulk product', response, response.status)
    if (response && !response.details && response.status === 'success') {
        yield put(actions.bulkProductSuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.details ? (
        response?.details
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.bulkProductFailed(message));
    }

  } catch (error) {
    // console.log(error?.response?.statusText, ' error ',error);
    const message = error?.response?.statusText ? (
      error?.response?.statusText
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.bulkProductFailed(message));
  }
}


export function* postBulkProduct() {
  yield takeLatest(constants.VALIDATE_BULK_PRODUCT, handleBulkProduct);
}
