import React from 'react';
import { Grid, Typography } from "@mui/material";

const Loader = (props) =>{
    return (
        <Grid item sx={{display:'flex',flexFlow:'column', justifyContent:'center', alignItems:'center'}}>
            <div style={{display:'flex'}}>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            </div>
            <Typography variant='' component={'p'} >Loading, Please wait.</Typography>
        </Grid>
    )
}

export default Loader;