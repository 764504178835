import React from 'react';
import {List, ListItem, Grid, Typography, Table, TableRow, TableHead, TableCell, TableBody} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DonutChart from '../../components/charts/DonutChart';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';

const MarketPlace = props => {

    // const [marketLabel, setMarketLabel] = useState([])

    const {data} = props;
    // console.log('compett ', data)
    const handleOpenUrl = (purl) => {
        // console.log('open ', purl)
        purl = purl.indexOf('http') !== -1 ? purl : `https://${purl}`
        window.open(purl, '_blank')
    }

    return (
        <List sx={{width:'100%', padding:0}}>
            { !isEmpty(data) && data.map((mItem, index) => {

                const marketLabel = Object.keys(mItem)[0];
                // const marketValues = Object.values(Object.values(mItem)[0].flat())[0]
                const marketValues = mItem[marketLabel][0]
                const {Less, more, Equal, logo, url, LastUpdated, decreased_today, decreased_week, increased_today, increased_week} = marketValues;
                const totalMarketValue = Number(Less) + Number(more) + Number(Equal)
                // const
                //  console.log('mItem ', url);
                return <ListItem key={`mItem${index}`} 
                    sx={{backgroundColor:'background.paper'}} className={'market'}>
                    <Grid item style={{display:"flex", width:"100%", justifyContent:'space-between', alignItems:'center'}}>
                        <Grid item xs={12} sm={3} md={4} sx={{display:'flex', marginRight:2.5}}>
                            <div 
                                onClick={url ? e => {handleOpenUrl(url)} : null}
                                style={{cursor: url && 'pointer'}}
                            >
                                <img src={logo === null ? "assets/noimage.png": logo} alt="logo" className='market-logo' />
                            </div>
                            <div style={{marginLeft:24}}>
                                <Typography className='productDetails' 
                                    onClick={url ? e => {handleOpenUrl(url)} : null}
                                    sx={{ cursor: url && 'pointer', textAlign:'left'}}>{marketLabel}</Typography>
                                <div style={{marginTop:'1.2em'}}>
                                    <Typography className='typo fontweight-500' sx={{textAlign:'left !important'}}>Availability: {totalMarketValue}</Typography>
                                    <Typography 
                                        onClick={url ? e => {handleOpenUrl(url)} : null}
                                        className='typo anchor' 
                                        sx={{textAlign:'left', cursor: url && 'pointer', textDecoration:'underline'}}>View Products
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{marginLeft:1.2, marginRight:1.2, display:'flex', justifyContent:'center', flexFlow:'column'}}>
                            <Table>
                            <TableHead sx={{backgroundColor:'background.default'}}>
                                <TableRow>
                                    <TableCell sx={{padding:'8px 16px'}}></TableCell>
                                    <TableCell align="center" sx={{padding:'8px 16px'}} className='tableText'>{'Increased'}</TableCell>
                                    <TableCell align="center" sx={{padding:'8px 16px'}} className='tableText'>{'Decreased'}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{padding:'8px 16px'}} className='tableText' component="th" scope="row">Today</TableCell>
                                    <TableCell sx={{padding:'8px 16px', textAlign:'center'}} className='tableText' >{increased_today}</TableCell>
                                    <TableCell sx={{padding:'8px 16px', textAlign:'center'}} className='tableText' >{increased_week}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{padding:'8px 16px'}} className='tableText' component="th" scope="row">This Week</TableCell>
                                    <TableCell sx={{padding:'8px 16px', textAlign:'center'}} className='tableText' >{decreased_today}</TableCell>
                                    <TableCell sx={{padding:'8px 16px', textAlign:'center'}} className='tableText' >{decreased_week}</TableCell>
                                </TableRow>
                            </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12} sm={3} md={4} sx={{display:'flex', alignItems:'center'}}>
                            <DonutChart marketValues={marketValues} totalMarketValue={totalMarketValue}/>
                            <Table>
                                <TableHead sx={{backgroundColor:'background.default'}}>
                                    <TableRow>
                                        <TableCell sx={{padding:'8px'}}  className='tableText smallText'></TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText smallText'>{'Total Products'}</TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText normalText'>{totalMarketValue}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{padding:'8px'}} component="th" scope="row"><CircleIcon sx={{fontSize:'1.5em', color:'#53D28C'}} /></TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText smallText' >Lower Price</TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText normalText' >{marketValues.Less}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{padding:'8px'}} component="th" scope="row"><CircleIcon sx={{fontSize:'1.5em', color:'#FF6767'}} /></TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText smallText' >Higher Price</TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText normalText' >{marketValues.more}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{padding:'8px'}} component="th" scope="row"><CircleIcon sx={{fontSize:'1.5em', color:'#FFA132'}} /></TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText smallText' >Equal Price</TableCell>
                                        <TableCell sx={{padding:'8px 16px'}}  className='tableText normalText' >{marketValues.Equal}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid> 
                    <Typography variant='p' component={'p'} className={'footer-text'}>
                        <span style={{ opacity:0.7, fontStyle:'italic'}}>Last Updated on </span>
                        {moment(LastUpdated).format('MM DD YYYY, h:mm:ss a')}
                        </Typography>
                </ListItem>
            })}
        </List>
    )

}

export default MarketPlace;