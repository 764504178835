import { useState, useEffect, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getItem, setItem } from '../../utils/storage';

import selectProducts from '../../redux/selectors/myproduct.selector';
import * as actions from '../../redux/actions/productDetails.actions';

import * as prdactions from '../../redux/actions/productDetails.actions';
import selectProductsDetails from '../../redux/selectors/productdetails.selector';

import selectQaUserDetails from '../../redux/selectors/qauser.selector';
import * as qaActions from '../../redux/actions/qaUser.actions';

import selectAapproveDetails from '../../redux/selectors/qaapprove.selector';
import * as qaApproveAction from '../../redux/actions/qaapprove.actions'

import { groupBy, isEmpty, isNull, isUndefined } from 'lodash';


const UseMyProduct = ({page, rowsPerPage ,filteringData, categoringData, brandFilterData, locationFilterData}) => {
    
    const [productDetails, setProductDetails] = useState([])
    const [selectedUser, setSelectedUser]= useState('');
    const [marketPlaceLabel, setMarketPlaceLabel] = useState([])
    const [isSearched, setIsSearched] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [selectLoc, setSelectLoc] = useState(null)
    const [getLoaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const pageList = getItem('pageList');
    const userToken = getItem('token');
    const userRole = getItem('role');
    // const [userList, ] = useState([{id:1, email:'orbioadmin@gmail.com'}]); 
    const { products } = useSelector(selectProducts);
    const { qaUserDetails } = useSelector(selectQaUserDetails);
    const { productDetails : prdDetails } = useSelector(selectProductsDetails)
    const { data: prdData } = prdDetails;
    const {data : userList} = qaUserDetails;
    const {data, isLoading, isFailure, message: errorMessage} = products;
    const qaapproveDetails = useSelector(selectAapproveDetails)
    const {isSuccessful: qaSuccess, isFailure : qaFailure} = qaapproveDetails.qaapproveDetails
    
    useEffect(() =>{
        if(prdData){
            dispatch(prdactions.productDetailsReset())
        }
    }, [prdData, dispatch])
    useEffect(() =>{
        if(qaSuccess){
            setTimeout(() => {
                dispatch(qaApproveAction.approveAllReset())
            }, 1000)
            
        }
    }, [qaSuccess, dispatch])
    
// console.log('selectAapproveDetails ', qaapproveDetails.qaapproveDetails)

    const getProductList = useCallback((pValue='') =>{
        
        if(userToken){
            setProductDetails([])
            // console.log(pValue,'locationFilterData.toString() ', filteringData, brandFilterData)
            if(pValue.indexOf('category_id__in') === -1){
                pValue = `${pValue}&category_id__in=${categoringData.toString()}`
            }
            if(pValue.indexOf('prd_brand__in') === -1){
                console.log('iuoo', encodeURI(brandFilterData))
                pValue = `${pValue}&prd_brand__in=${brandFilterData.toString().toLowerCase()}`
            }
            if(pValue.indexOf('dept_id__in') === -1){
                pValue = `${pValue}&dept_id__in=${filteringData.toString()}`
            }
            if(pValue.indexOf('page_size') === -1){
                pValue = `${pValue}&page_size=${rowsPerPage}`
            }
            if(pValue.indexOf('page') === -1){
                pValue = `${pValue}&page=${page}`
            }
            pValue ? setIsSearched(true) : setIsSearched(false);
            //  console.log('getProductList', userRole, selectedUser, getItem('qaUser'))
            setItem('pageList', JSON.stringify(pValue));
            // console.log('pValue', pValue);
            setLoaded(true);
            if(userRole === 2){
                if(getItem('qaUser') !== '' && pValue.indexOf('user_id') === -1){
                    // setItem('qaUser', selectedUser)
                    pValue = `${pValue}&user_id=${getItem('qaUser')}`
                }
                dispatch(actions.getQATotalProducts({pPath:pValue}))
            } else if(userRole !== 2) {
                dispatch(actions.getTotalProducts({pPath:pValue}))
            }
        }
    }, [dispatch, userToken]) // eslint-disable-line
    useEffect(() =>{
        // console.log('loadingData  ', data, isLoading, isFailure, userRole, getLoaded);
        // console.log('rowsPerPage ', rowsPerPage, data)
        if(!data && !loadingData && userRole !== 2){
            console.log('firstloading');
            
            let pages = page === 0 ? 1 : page+2;
            let rowsPage =  rowsPerPage;
            let searchStr = '';
            let category_id__in = '';
            let dept_id__in = '';
            let prd_brand__in = '';
            setLoadingData(prevState => !prevState)
            if(pageList !== undefined && pageList !== null && pageList !== '' && typeof(pageList) === 'string'){
                // console.log('pageList', pageList)
                const tempPageList = JSON.parse(pageList).split('&')
                tempPageList.map(item =>  {
                    // console.log(item.indexOf('page='), item.indexOf('page_size='))
                    if(item.indexOf('page=') > -1){
                        pages =  item.split("=")[1];
                    } else if(item.indexOf('page_size=') > -1){
                        rowsPage = item.split("=")[1];
                    } else if(item.indexOf('search=') > -1){
                        searchStr = item.split("=")[1];
                    } else if(item.indexOf('category_id__in=') > -1){
                        category_id__in = item.split("=")[1];
                    } else if(item.indexOf('dept_id__in=') > -1){
                        dept_id__in = item.split("=")[1];
                    } else if(item.indexOf('prd_brand__in=') > -1){
                        prd_brand__in = item.split("=")[1];
                    }
                    return item;
                });
                // console.log('pageList ', tempPageList)
            }
            // console.log('loading ', searchStr, prd_brand__in, dept_id__in, category_id__in);
            if(!getLoaded){ 
                if(searchStr === '' && prd_brand__in === '' && dept_id__in ==='' && category_id__in === ''){
                   getProductList(`page=${pages}&page_size=${rowsPage}`)
                    
                } else {
                    getProductList(`page=${pages}&page_size=${rowsPage}&prd_brand__in=${prd_brand__in.toString().toLowerCase()}&category_id__in=${category_id__in.toString()}&dept_id__in=${dept_id__in.toString()}`)
                }
            }
            
        }
    }, [getProductList, data, loadingData]) // eslint-disable-line

    useEffect(() =>{
        if(isEmpty(userList) && userRole === 2){
            dispatch(qaActions.getQAUser())
        }
        console.log(userList, selectedUser)
        if(userList !== undefined && !isEmpty(userList) && selectedUser === ''){
            const qaUser = getItem('qaUser');
            if(qaUser === '' || qaUser === undefined || qaUser === null){
                setItem('qaUser', userList[0].id)
                setItem('currency', JSON.stringify(userList[0].currency))
                setSelectedUser(prevState => userList[0].id);
            }
            let pages = page === 0 ? 1 : page+2;
            let rowsPage =  rowsPerPage;
            let searchStr = '';
            let category_id__in = '';
            let dept_id__in = '';
            let prd_brand__in = '';
            setLoadingData(prevState => !prevState)
            if(pageList !== undefined && pageList !== null && pageList !== ''){
                // console.log('pageList', pageList)
                const tempPageList = JSON.parse(pageList).split('&')
                tempPageList.map(item =>  {
                    // console.log(item.indexOf('page='), item.indexOf('page_size='))
                    if(item.indexOf('page=') > -1){
                        pages =  item.split("=")[1];
                    } else if(item.indexOf('page_size=') > -1){
                        rowsPage = item.split("=")[1];
                    } else if(item.indexOf('search=') > -1){
                        searchStr = item.split("=")[1];
                    } else if(item.indexOf('category_id__in=') > -1){
                        category_id__in = item.split("=")[1];
                    } else if(item.indexOf('dept_id__in=') > -1){
                        dept_id__in = item.split("=")[1];
                    } else if(item.indexOf('prd_brand__in=') > -1){
                        prd_brand__in = item.split("=")[1];
                    }
                    return item;
                });
                // console.log('pageList ', tempPageList)
            }
            // console.log('qaloading', page, rowsPerPage, getItem('qaUser'), userList[0].id);
            if(!getLoaded && searchStr === '' && prd_brand__in === '' && dept_id__in ==='' && category_id__in === ''){
               console.log('sdd');
                getProductList(`page=${pages}&page_size=${rowsPage}&user_id=${getItem('qaUser')}`)
            }
        }
    }, [userRole, userList, selectedUser]) // eslint-disable-line

    const setProductList = (filtData, cateData, brndData) =>{
        // console.log('using loc ', rowsPerPage, page)
        const pages = page === 0 ? 1 : page;
        const str = `page=${pages}&page_size=${rowsPerPage}&prd_brand__in=${brndData.toString().toLowerCase()}&category_id__in=${cateData.toString()}&dept_id__in=${filtData.toString()}`
        getProductList(str)
    }

    
    // const getDefaultLocation = (pData, pLocation) =>{ 
    //     let defaultItem = null;
    //         defaultItem = pData.filter(item =>{
    //             if(item.loc.loc_is_default) {
    //                return item
    //            }
    //        })
    //     return defaultItem;
    // }

    useEffect(() => {
        if(data !== null){
            //  console.log('data ')
             if(!isEmpty(locationFilterData)){
                setSelectLoc(Number(locationFilterData[0]));
             }
             setTimeout(()=> {
                setLoaded(true);
             }, 2000)
            const storeData = Array.from(data.results);
            let returnData = storeData.map(item => {
                item.deptname = !isNull(item.dept) && item.dept?.dept_name ? String(item.dept?.dept_name) : 'N/A';
                item.deptId = !isNull(item.dept) && item.dept?.dept_id ? item.dept?.dept_id : null;
                item.categoryname = !isNull(item.category) && item.category?.category_name ? String(item.category?.category_name) : 'N/A';
                item.cateId = !isNull(item.category) && item.category?.category_id ? item.category?.category_id : null;
                item.prd_variants = item.prd_variants !== 'nan' && item.prd_variants !== '' ? item.prd_variants : 'N/A'
                item.prd_url = item.prd_url !== 'nan' && item.prd_url !== '' ? item.prd_url : ''
                const activeLocation = [item.locations[0]] // getDefaultLocation(item.locations); //
                if(!isEmpty(activeLocation)){
                    item.filtered_sales_price = activeLocation[0].prd_sales_price !== null ? activeLocation[0].prd_sales_price : activeLocation[0].prd_mrp;
                    item.filtered_norm_price = activeLocation[0].prd_norm_price;
                    item.filtered_mrp = activeLocation[0].prd_mrp; 
                } else {
                    item.filtered_sales_price = item.locations[0].prd_sales_price !== null ? item.locations[0].prd_sales_price : item.locations[0].prd_mrp;
                    item.filtered_norm_price = item.locations[0].prd_norm_price;
                    item.filtered_mrp = item.locations[0].prd_mrp; 
                }
                
                item.activeLocation = activeLocation;
                const comp_market_Data = !isEmpty(item.competitors) && item.competitors.map(competitor => 
                    {
                        if(competitor.market_place !== null){
                            competitor.market_place_id =  competitor.market_place?.market_place_id;
                            competitor.market_place_name =  competitor.market_place?.market_place_name;
                        }
                        return competitor;
                    }
                )
                item.competitors = comp_market_Data !== false ? comp_market_Data : [];
                
                const filteredCompetitors = !isEmpty(item.competitors) && [groupBy(item.competitors, 'market_place_name')];
                item.filteredMarket = !isEmpty(item.competitors) && [...new Set(item.competitors.map(competitor => competitor.market_place_name).flat())]
                item.filteredCompetitors = filteredCompetitors;
                // console.log(item.competitors, filteredCompetitors, item.filteredMarket)
                item.filteredMarket && item.filteredMarket.map(marketLabel => {
                    let maxPrice = 0;
                    for(const [key, value] of Object.entries(filteredCompetitors[0])){
                          if(key === marketLabel && (key !== undefined || key !== null)){
                            // console.log(key , marketLabel)
                            // const maxMatchRate = Math.max(...value.map( competitorItem=> !isNull(competitorItem.comp_prd_score) && competitorItem.comp_prd_score))
                            const maxMatchRate = isEmpty(locationFilterData) ? 
                                Math.max(...value.map( competitorItem=> !isNull(competitorItem.comp_prd_score) && competitorItem.comp_prd_score)) 
                                : Math.max(...value.map( competitorItem => locationFilterData[0] === competitorItem.loc.loc_id && !isNull(competitorItem.comp_prd_score) && competitorItem.comp_prd_score))
                            const filters = value.filter( competitorItem => !isNull(competitorItem.comp_prd_score) && !isUndefined(competitorItem.comp_prd_score) && competitorItem.comp_prd_score === maxMatchRate && competitorItem.comp_prd_sales_price !== 0)
                            // console.log(maxMatchRate, filters);
                            if(!isEmpty(filters)){
                                const maxCompetitorPrice = Math.max(...filters.map(item => item.comp_prd_sales_price))
                                const maxCompetitorMrp = Math.max(...filters.map(item => item.comp_prd_mrp))
                                // console.log('maxCompetitorPrice', maxCompetitorPrice)
                                maxPrice = !isNull(maxCompetitorMrp) ? maxCompetitorPrice : maxCompetitorMrp
                            }
                          }
                    }
                    if(marketLabel !== undefined && marketLabel !== null && maxPrice !== null && maxPrice !== undefined){
                        item[`competitorPriceDiff${marketLabel}`] = Number(maxPrice-item.prd_sales_price).toFixed(2);
                        item[`competitorMaxPrice${marketLabel}`] = maxPrice !== false ? Number(maxPrice.toFixed(2)) : 'N/A';
                    } 
                    return maxPrice;
                 })
                return item;
            })
            returnData = returnData.filter(item => item !== undefined) 
            // console.log(returnData);
            const marketplaces = [...new Set(returnData.map(item => item.competitors.map(competitor => competitor.market_place_name)).flat())];
            marketplaces.map((mrkt, index) => {
                if(mrkt === undefined || mrkt === null){
                    marketplaces.splice(index, 1);
                }
                return mrkt;
            })
            setMarketPlaceLabel(marketplaces.sort());
            setProductDetails(returnData);
        }
    }, [data, locationFilterData]) // eslint-disable-line
    
    const handleApproveAll = () =>{
        dispatch(qaApproveAction.approveAll({user_id: getItem('qaUser')}))
    }
    
    const handleGetProductList = (event) =>{
        // console.log(event.target.value)
        // setItem('qaUser', event.target.value)
        setSelectedUser(event.target.value)
        const pages = page === 0 ? 1 : page;
        const str = `page=${pages}&user_id=${event.target.value}&page_size=${rowsPerPage}&prd_brand__in=${brandFilterData.toString().toLowerCase()}&category_id__in=${categoringData.toString()}&dept_id__in=${filteringData.toString()}`
        getProductList(str)
    }

    return {
        userRole,
        userList,
        selectedUser,
        filterdProducts: productDetails,
        data,
        isLoading,
        isFailure,
        qaSuccess, 
        qaFailure,
        errorMessage,
        isSearched,
        handleGetProductList,
        getProductList,
        setProductList,
        marketPlaceLabel,
        selectLoc,
        handleApproveAll,
        // handleFilterProduct
    }
}

export default UseMyProduct;