import * as constants from '../constants/addmarketloc.constants';

export const handleAddMarketLoc = (payload) => ({
  type: constants.ADD_MARKET_LOC,
  payload
});

export const addMarketLocSuccessful = (response) => ({
  type: constants.ADD_MARKET_LOC_SUCCESSFUL,
  response
});

export const addMarketLocFailed = (payload) => ({
  type: constants.ADD_MARKET_LOC_FAILED,
  payload
});
