import React, { useState } from "react";
import { Container, Grid } from "@mui/material";

import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

const Reports = (props) => {
  const [filteringData, setFilteringData] = useState([]);
  const [categoringData, setCategoryData] = useState([]);
  const [brandFilterData, setBrandFilterData] = useState([]);
  const [locationFilterData, setLocationFilterData] = useState([]);
  const [marketFilterData, setMarketFilterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState(null);

  const [currentLocation, setCurrentLocation] = useState(""); // state for saving current location from leftpanel
    console.log("currentLocation",currentLocation)

  const getFilterdData = (filteredProduct, data, pMarketData) => {
    setFilteredData(filteredProduct);
    setMarketData(pMarketData);
    setOriginalData(data);
  };
  const handleDeptFilter = (pStr, pData) => {
    setFilteringData(pData);
  };
  const handleMarketFilter = (pStr, pData) => {
    setMarketFilterData(pData);
  };
  const handleLocationFilter = (pStr, pData) => {
    // console.log('handleLocationFilter', pData)
    setLocationFilterData(pData);
  };
  const handleCategoryFilter = (pStr, pData) => {
    // console.log('handleCategoryFilter ', pData)
    setCategoryData(pData);
  };
  const handleBrandFilter = (pStr, pData) => {
    // console.log('handleBrandFilter ', pData)
    setBrandFilterData(pData);
  };

  const removeAllFilters = () => {
    setLocationFilterData([]);
    setBrandFilterData([]);
    setCategoryData([]);
    setFilteringData([]);
  };

  return (
  
    <Grid sx={{ height: "auto", px: "0px !important", mt: 0 }}>
      <Grid container spacing={0} sx={{ display: "flex" }}>
        <LeftPanel
          setCurrentLocation={setCurrentLocation} // for getting current location
          currentLocation={currentLocation} // for chhecked property
          handleDeptFilter={handleDeptFilter}
          handleBrandFilter={handleBrandFilter}
          handleCategoryFilter={handleCategoryFilter}
          handleLocationFilter={handleLocationFilter}
          handleMarketFilter={handleMarketFilter}
          removeAllFilters={removeAllFilters}
          selectedLoc={selectedLoc}
          filteredData={filteredData}
          originalData={originalData}
          marketData={marketData}
        />
        <RightPanel
          currentLocation={currentLocation} // sending location value to tableau filtering
          filteringData={filteringData}
          brandFilterData={brandFilterData}
          categoringData={categoringData}
          locationFilterData={locationFilterData}
          marketFilterData={marketFilterData}
          setSelectedLoc={setSelectedLoc}
          getFilterdData={getFilterdData}
        />
      </Grid>
    </Grid>
  );
};

export default Reports;
