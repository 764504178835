import React, { useState } from 'react';
import { Grid, Typography, Switch, Select, Checkbox, InputBase, Button, FormControlLabel, FormGroup, MenuItem } from '@mui/material';
import {styled} from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';


const NotificationSettings = props => {

    const {currentTheme, headingStyle} = props;
    const [ notificationShow, setNotificationShow ] = useState(false)
    
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      'label + &': {
        marginTop: theme.spacing(2),
      },
      '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 11,
        width: '100%',
        padding: '10px 12px',
        color: theme.palette.text.primary,
        transition: theme.transitions.create([
          'border-color',
          'background-color',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily:'Inter',
        '&:focus': {
          borderColor: theme.palette.primary.main,
        },
      },
    }));

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 50,
        height: 22,
        padding: 0,
        display: 'flex',
        '&:active': {
          '& .MuiSwitch-thumb': {
            width: 20,
          },
          '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
          },
        },
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(27px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
          width: 20,
          height: 20,
          borderRadius: 10,
          transition: theme.transitions.create(['width'], {
            duration: 200,
          }),
        },
        '& .MuiSwitch-track': {
          borderRadius: 30 / 2,
          opacity: 1,
          backgroundColor:
            currentTheme === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
          boxSizing: 'border-box',
        },
      }));
      const checkedIcons = () => {
        return <CheckIcon sx={{color:'text.primary', fontSize:14, borderRadius:1, border:'1px solid', borderColor:'text.primary', marginRight:0.5, backgroundColor:'background.default'}} />
      }
      const uncheckedIcons = () => {
        return <CheckIcon sx={{color:'transparent', fontSize:14, borderRadius:1, border:'1px solid #FFFFFF', borderColor:'text.primary', marginRight:0.5, backgroundColor:'none'}} />
      }


    const handleNotificationChange = () =>{
        // setNotificationShow( prevState => !prevState)
    }

    return (
        <>
            <Typography variant='h4' component={'h4'} className='typo headingText' sx={headingStyle}>Notification Settings</Typography>        
            <Grid item xs={12} sx={{pl:3, pt:5}}>
                        <Grid item sx={{display:'flex', mb:2, alignItems:'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{}}>Notification</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={{display:'flex', alignItems:'center'}}>
                                <Typography variant='body2' sx={{}}>Off</Typography>
                                    <AntSwitch defaultChecked sx={{ml:2, mr:2}} checked={notificationShow} onChange={handleNotificationChange} inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography variant='body2' sx={{}}>On</Typography>
                            </Grid>
                        </Grid>
                        { notificationShow && <>
                        <Grid item sx={{display:'flex', mb:2, alignItems:'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{}}>Frequency</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                    <Select size='small' variant='outlined' 
                                        input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                        value={'daily'} sx={{ width:200 ,fontSize:11, fontFamily:'Inter', color:'#E4E3E3', fontWeight:400}}>
                                        <MenuItem sx={{fontSize:11}} value={'daily'}>Custom/Daily</MenuItem>
                                    </Select>
                            </Grid>
                        </Grid>
                        <Grid item sx={{display:'flex', mb:2, alignItems:'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{}}>Email</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <Button variant='outlined' sx={{color:'text.primary', borderColor:'text.primary', fontSize:11, textTransform:'none'}} 
                                    startIcon={<AddIcon sx={{color:'inherit'}}/>}>Email Address</Button>
                            </Grid>
                        </Grid>
                        <Grid item sx={{display:'flex'}}>
                            <Grid item xs={12} sm={4}>
                                <Typography variant='body2' sx={{}}>Notification Type</Typography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <FormGroup>
                                    <FormControlLabel disableRipple 
                                        control={<Checkbox defaultChecked icon={uncheckedIcons()} checkedIcon={checkedIcons()} sx={{padding:'5px', color:'text.primary', backgroundColor:'transparent'}}/>} 
                                            label={<Typography variant='body2' sx={{}}>Price Change</Typography> }
                                            />
                                    <FormControlLabel disableRipple
                                        control={<Checkbox defaultChecked icon={uncheckedIcons()} checkedIcon={checkedIcons()} sx={{padding:'5px', color:'text.primary', backgroundColor:'transparent'}}/>}
                                        label= {<Typography variant='body2' sx={{}}>Scrapping Status</Typography>}
                                        />
                                    
                                    <FormControlLabel disableRipple
                                        control={<Checkbox defaultChecked icon={uncheckedIcons()} checkedIcon={checkedIcons()} sx={{padding:'5px', color:'text.primary', backgroundColor:'transparent'}}/>} 
                                        label= {<Typography variant='body2' sx={{}}>New products & match rate</Typography>}
                                        />
                                </FormGroup>
                            </Grid> 
                        </Grid>
                        
                        </>
                            }
                    </Grid>
        </>
    )

}

export default NotificationSettings;