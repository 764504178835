import * as constants from '../constants/qaaprove.constants';

export const initialState = {
  
  qaapproveDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
  isSessionExpired:false
};

export const qaApproveReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.QA_APPROVE:
      return {
        ...state,
        qaapproveDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          message: null
        },
      };
    case constants.QA_APPROVE_SUCCESSFUL:
     console.log('reducer', action)
      return {
        ...state,
        qaapproveDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          data: action.response
        }
      };
    case constants.QA_APPROVE_FAILED:
      return {
        ...state,
        qaapproveDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
        }
      };
      case constants.QA_APPROVE_RESET:
        return {
          ...state,
          qaapproveDetails: {
            isLoading: false,
            isSuccessful: false,
            isFailure: false,
            message: null,
            locdata: null
          }
        };
      
    default:
      return state;
  }
};
