import * as constants from '../constants/qaaprove.constants';

export const approveAll = (payload) => ({
  type: constants.QA_APPROVE,
  payload
});

export const approveAllSuccessful = (response) => ({
  type: constants.QA_APPROVE_SUCCESSFUL,
  response
});

export const approveAllFailed = (payload) => ({
  type: constants.QA_APPROVE_FAILED,
  payload
});

export const approveAllReset = (payload) => ({
  type: constants.QA_APPROVE_RESET,
  payload
});
