import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {FormControl, Typography, InputLabel, InputBase, Button} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { removeItem } from '../../utils/storage';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import selectPasswordDetails from '../../redux/selectors/changepassword.selector';
import { handleChangepassword,changePasswordFailed } from '../../redux/actions/changepassword.actions';
import { resetAuthState } from '../../redux/actions/authentication.actions'
import useActions from '../../redux/use-actions';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const passwordSchema = Yup.object().shape({
    oldpassword: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    newpassword: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })


const ChangePasswordPop = props => {
    const { handleFailed, headingStyle} = props;
    const [open, ] = React.useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const actions = useActions({ handleChangepassword, changePasswordFailed, resetAuthState });
    const { passwordDetails } = useSelector(selectPasswordDetails);
    const {isSuccessful} = passwordDetails;

//console.log('change ', isSuccessful);

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));

      const handleLogout = () => {
        dispatch(actions.resetAuthState())
        removeItem('token');
        navigate('/login')
    }
    useEffect(() =>{
      if(isSuccessful){
        setTimeout(() =>{
        actions.changePasswordFailed();
        handleLogout();
        }, 1000)
      }

    }, [isSuccessful, actions]) //eslint-disable-line  

    

  const handleSubmit = (values) => {
// console.log('handleSubmit', values);
actions.handleChangepassword(values);
  }
  const handleClose = () => {
    //setOpen(false);
    handleFailed(false)
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className='typo headingText' sx={headingStyle}>
            Change Password
        </DialogTitle>
        <DialogContent sx={{display:'flex', flexDirection:'column'}}>
          {isSuccessful && <Typography variant='body2'>Password changed successfully</Typography>}
                    <Formik
                    // enableReinitialize
                        initialValues={{ old_password: 'admin@123', new_password: 'Admin@123' }}
                        validationSchema={passwordSchema}
                        >
                    {({ errors, 
                        setFieldValue,
                        values 
                    }) => (
                        <Form style={{}} className='loginform'>
                            <FormControl variant="standard" sx={{mt:1, width:'100%'}} >
                                <InputLabel shrink htmlFor='oldpsw' sx={{color:'text.primary'}}>
                                    Old password
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.old_password} 
                                    type= 'password'
                                    placeholder='Enter your old password'
                                    id='oldpsw'
                                    onChange={e => setFieldValue('old_password', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle">{errors.old_password}</Typography>
                            </FormControl>

                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='password' sx={{color:'text.primary'}}>
                                    New Password
                                    </InputLabel>
                                <BootstrapInput 
                                    type='password'
                                    value={values.new_password} 
                                    placeholder='Enter your new password '
                                    id='password'
                                    onChange={e => setFieldValue('new_password', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle">{errors.new_password}</Typography>
                            </FormControl>
                            <FormControl sx={{ m: 0, mt:3, mb:2, display:'flex', justifyContent:'space-evenly', flexFlow:'row' }}>
                                <Button sx={{ width:140, backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} onClick={() => handleSubmit(values)}>Change</Button>
                                <Button sx={{width:140, backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} onClick={() => handleClose()}>Cancel</Button>
                            </FormControl>
                        </Form>
                    )}
                    </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default ChangePasswordPop