import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/addlocations.actions';
import * as constants from '../constants/addlocations.constants';
import * as locActions from '../actions/locations.actions'
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* handleAddLocation(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  const {market_place_id} = payload;
  // console.log('payload ', payload)

  try {
    const response = yield call(NetworkApi.post, paths.locationsURL, payload, apiConfig);
    // console.log( 'add location ',response)
    if (response && !response?.detail) {
      if(market_place_id !== null && market_place_id !== undefined){
          const obj = {market_place_id, loc_id:Number(response[0].loc_id)};
          const mpLocationResponse = yield call(NetworkApi.post, paths.addMarketLocURL, obj, apiConfig)
          if(mpLocationResponse && !mpLocationResponse?.detail){
            yield put(actions.addLocationSuccessful(response));
            locActions.getLocations()
          }else {
            const message = mpLocationResponse?.detail ? (
              mpLocationResponse?.detail
            ) : (
              ''
              // <FormattedMessage id="notProcess" />
            );
            yield put(actions.addLocationFailed(message));
          }
      } else {
          yield put(actions.addLocationSuccessful(response));
          locActions.getLocations()
      }
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.addLocationFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
      error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.addLocationFailed(message));
  }
}

export function* addLocation() {
  yield takeLatest(constants.ADD_LOCATION, handleAddLocation);
}
