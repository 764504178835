import * as constants from '../constants/addcategory.constants';

export const handleAddCategory = (payload) => ({
  type: constants.ADD_CATEGORY,
  payload
});

export const addCategorySuccessful = (response) => ({
  type: constants.ADD_CATEGORY_SUCCESSFUL,
  response
});

export const addCategoryFailed = (payload) => ({
  type: constants.ADD_CATEGORY_FAILED,
  payload
});

export const addCategoryReset = (payload) => ({
  type: constants.ADD_CATEGORY_RESET,
  payload
});
