import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/addcategory.actions';
import * as constants from '../constants/addcategory.constants';
// import * as locActions from '../actions/categories.actions'
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* handleAddCategory(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  // const {category_name} = payload;
  // console.log('payload ', payload)

  try {
    const response = yield call(NetworkApi.post, paths.categoriesURL, payload, apiConfig);
    // console.log( 'add location ',response)
    if (response && !response?.detail) {
      yield put(actions.addCategorySuccessful(response));
      // yield put(locActions.getCategories())
      
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.addCategoryFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.status ? (
      error?.response?.data?.status
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.addCategoryFailed(message));
  }
}

export function* addCategory() {
  yield takeLatest(constants.ADD_CATEGORY, handleAddCategory);
}
