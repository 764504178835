import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
    import datalabels from 'chartjs-plugin-datalabels';
import { Pie} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    datalabels,
    Legend);



const PieChart = props => {
    
    const labels = ["Cheaper", "Higher", "Equal"];
    const {less, more, equal} = props.productStats;
  // console.log('props.data', props.data, props.productStats)
    const pieData = {
        labels,
        datasets: [
            {
              data: [less, more, equal],
              datalabels: {
                anchor: 'end',
                align:'end',
                offset:-40
              },
              backgroundColor: ['#3FCD7F', '#FF6767', '#DAC934'],
              hoverBackgroundColor: ['#3FCD7F', '#FF6767', '#DAC934'],
              border:0
            }
          ]
    };

    const option = {
        responsive: true,
        maintainAspectRatio: true,
        border:0,
        borderWidth:0,
        line:0,
        stroke:0,
        scales:{
          x:{
            ticks:{
              maxRotation: 0,
              display:false
            }
          }
        },
        
        plugins: {
          legend: {
            display: false,
          },
          aspectRatio: 4 / 3,
          datalabels: {
            formatter: Math.round,
            display: function(context) {
              var dataset = context.dataset;
              // var count = dataset.data.length;
              var value = dataset.data[context.dataIndex];
              // console.log('context',value, count, (count * 1.5))
              return value; //> count * 1.5;
            },
            color: '#FFF',
          }
        }
    }


return (
    <Pie options={option} width={'50%'} height={'50%'} data={pieData} />
)

}
export default PieChart;