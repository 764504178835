import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/productLocations.actions';
import * as constants from '../constants/productLocations.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* getProductLocations(request = {}) {
  const apiConfig = getApiConfig()
  try {
    const response = yield call(NetworkApi.get, paths.productLocationURL, {}, apiConfig);
    // console.log('locations', response);
    if (response) {
      if(!response.details){
        yield put(actions.getProductLocationsSuccessful(response));
      } else {
        yield put(actions.getProductLocationsFailed(response.details));
      }
    } else {
      console.log('error ', response)
      const message = response? (
        response
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.getProductLocationsFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.message ? (
      error?.response?.data?.message
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.getProductLocationsFailed(message));
  }
}


export function* getPrdctLocations() {
  yield takeLatest(constants.PRODUCT_LOCATION_GET, getProductLocations);
}
