import * as constants from '../constants/addmarketrules.constants';

export const handleAddMarketrule = (payload) => ({
  type: constants.ADD_MARKET_RULE,
  payload
});

export const addMarketruleSuccessful = (response) => ({
  type: constants.ADD_MARKET_RULE_SUCCESSFUL,
  response
});

export const addMarketruleFailed = (payload) => ({
  type: constants.ADD_MARKET_RULE_FAILED,
  payload
});

export const addMarketruleReset = (payload) => ({
  type: constants.ADD_MARKET_RULE_RESET,
  payload
});