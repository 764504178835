import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { Grid, Typography, Collapse, Divider, IconButton, Button, InputBase, FormControl, InputLabel, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import selectAddDepartmentDetails from '../../redux/selectors/adddepartment.selector'
import { handleAddDepartment, addDepartmentReset } from '../../redux/actions/adddepartment.actions'
import useActions from '../../redux/use-actions';
import CloseIcon from '@mui/icons-material/Close'

import CompModal from '../Modal'
import { isEmpty } from 'lodash';

const marketSchema = Yup.object().shape({
  dept_name: Yup.string().required('Required'),
    // url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })



const AddDepartment = props =>{
    const {deptData } = props;
    const dispatch = useDispatch()
    const { addDepartmentDetails } = useSelector(selectAddDepartmentDetails);
    const { data, isSuccessful, isFailure } =  addDepartmentDetails;
  const actions = useActions({ handleAddDepartment, addDepartmentReset });
const [errorBool, setErrorBool] = useState(false)

//  console.log('addDepartment', data, isSuccessful)
useEffect(()=>{
  let times = null;
    if(isSuccessful){
  times = setTimeout(() =>{
    setErrorBool(false)
    //  console.log('data ', data)
    props.handleClose(data)
    dispatch(actions.addDepartmentReset())
    clearTimeout(times);
}, 2000)
    }
  return () => clearTimeout(times)
}, [isSuccessful]) // eslint-disable-line

  useEffect(() =>{
    if(isFailure){

    }
  }, [isFailure])

    const formStyle = {width:'100%'};

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));
    
    const checkName = (pName) => {
        return deptData.find(item => item.dept_name === pName)
    }
    const handleSubmit = (values) =>{
      //  console.log(values);
       const name = checkName(values.dept_name)
       if(isEmpty(name)){
        setErrorBool(false)
        actions.handleAddDepartment(values);
       } else {
        setErrorBool(true)
       }
    }
    return (
        <CompModal {...props} modalOpen={true}>
                <Typography variant="h5" component={'h5'}>Add Department</Typography>
                <Divider />
                <Collapse in={errorBool}>
                
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorBool(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 1 }}
        >
          This department is already taken.
        </Alert>
      </Collapse>

                <Grid item>
                <Formik
                   // enableReinitialize
                    initialValues={{ dept_name: '', dept_desc: '',  dept_is_active: true, 
                    }}
                    validationSchema={marketSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form className='loginform' style={{width:'100%'}}>
                      {isFailure && <Typography variant="body2" component={'p'}>There is some issue with adding department. </Typography> }
                        {isSuccessful && <Typography variant="body2" component={'p'}>Department added successfully.</Typography> }
                        <Grid item xs={12} sx={{display:'flex',}}>
                            <Grid item xs={12} md={12} xl={12} sx={{mr:1}}>
                                <FormControl variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='deptname' sx={{color:'text.primary'}}>
                                    Department Name*
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.dept_name} 
                                    placeholder='Enter Department name'
                                    id='deptname'
                                    onChange={e => setFieldValue('dept_name', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.dept_name}</Typography>
                            </FormControl>

                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='desc' sx={{color:'text.primary'}}>
                                    Department Description
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.dept_desc} 
                                    placeholder='Enter department description'
                                    id='desc'
                                    onChange={e => setFieldValue('dept_desc', e.target.value)}
                                />
                                {/* <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_url}</Typography> */}
                            </FormControl>
                            </Grid>
                        </Grid>
                    
                        <FormControl sx={{ m: 0, mt:3, mb:2, width:'100%', alignItems:'center' }}>
                            <Button sx={{width:'70%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                            onClick={() => handleSubmit(values)}>Add</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>

                </Grid>
        </CompModal>
    )
}

export default AddDepartment;