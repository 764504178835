import {useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupBy} from 'lodash';
import {isEmpty, trim } from 'lodash';
import { getItem, setItem } from '../../utils/storage';

import * as brandAction from '../../redux/actions/brands.actions';
import selectBrandDetails from '../../redux/selectors/brands.selector'

import * as categoryAction from '../../redux/actions/categories.actions';
import selectCategoriesDetails from '../../redux/selectors/categories.selector'

import * as departmentAction from '../../redux/actions/department.actions';
import selectDepartmentDetails from '../../redux/selectors/department.selector'


const UseLeft = ({ originalData, removeAllFilters, handleDeptFilter, handleCategoryFilter, handleBrandFilter, handleLocationFilter}) =>{

  const dispatch = useDispatch()
  const {brandDetails} = useSelector(selectBrandDetails)
  const {data : brandsData, isSuccessful:brandSuccess} = brandDetails;

  const {categoryDetails} = useSelector(selectCategoriesDetails)
  const {data : categoriesData} = categoryDetails;

  const {departmentDetails} = useSelector(selectDepartmentDetails)
  const {data : departmentData} = departmentDetails;

  const qaUser = getItem('qaUser');
  const userRole = Number(getItem('role'));
 

  // const {productlocationDetails} = useSelector(selectProductLocationsDetails)
  // const {prdlocdata : locationData} = productlocationDetails;

    const [departmentFilterData, setDepartmentFilterData] = useState([])
    const [categoryFilterData, setCategoryFilterData] = useState([])
    const [brandFilterData, setBrandFilterData] = useState([])
    const [locationFilterData, setLocationFilterData] = useState([])
  
  useEffect(() =>{
    
    if(!brandsData){
      if(userRole === 2 && qaUser !== undefined && qaUser !== null){
        // console.log('qaUser ',qaUser);
        dispatch(brandAction.getBrands(qaUser));
      } else {
        dispatch(brandAction.getBrands());
      }
    }
  }, [brandsData, userRole, qaUser]) // eslint-disable-line
  useEffect(() =>{
    if(!categoriesData){
      if(userRole === 2 && qaUser !== undefined && qaUser !== null){
        dispatch(categoryAction.getCategories(qaUser));
      } else {
        dispatch(categoryAction.getCategories());
      }
    }
  }, [categoriesData, userRole, qaUser]) // eslint-disable-line
  useEffect(() =>{
    if(!departmentData){
      if(userRole === 2 && qaUser !== undefined && qaUser !== null){
        dispatch(departmentAction.getDepartment(qaUser));
      } else {
        dispatch(departmentAction.getDepartment());
      }
    }
  }, [departmentData, userRole, qaUser]) // eslint-disable-line
  
    const getLocationData = useCallback((lcData) => {
      let locData = [];
      // console.log(originalData, 'lcData ', lcData, locationFilterData)
      lcData?.results?.map(loc => {
      return loc.competitors.map(locItem => {
        return locData.push(locItem.loc);
        })
      })
      let groupData = groupBy(locData, 'loc_id');
      // console.log('groupData ', groupData)
      const generatedData = [];
      for(const [key,value] of Object.entries(groupData)){
        // console.log('key', key, typeof(key));
        if(key !== undefined && key !== null && key !== 'undefined'){
          const locationChecked = locationFilterData.filter(item => item.value === key);
          generatedData.push({name:value[0].loc_name, value:Number(key), checked: !isEmpty(locationChecked) ? locationChecked[0].checked : false });
        }
      }
      setLocationFilterData(generatedData)
    }, []) //eslint-disable-line

    const getsBrandData = useCallback((brandsData) =>{
      if(brandsData && isEmpty(brandsData?.brands) )return;
      const generatedBrandData = [];
      brandsData?.brands.sort().map(item => {
        const brandChecked = brandFilterData.filter(brnd => brnd.value === item)
        if( trim(item).length > 0 )
        return generatedBrandData.push({name:item, value:item, checked: !isEmpty(brandChecked) ? brandChecked[0].checked : false })  
      })
      setBrandFilterData(generatedBrandData)
    }, [brandsData]) // eslint-disable-line

    const getCategoryData = useCallback((categoriesData) =>{
      if(isEmpty(categoriesData) )return;
      
      const generatedCategoryData = [];
      categoriesData.sort().map(item =>{
        const cateChecked = categoryFilterData.filter(cateItem => Number(cateItem.value) === Number(item.category_id));
        generatedCategoryData.push({name:item.category_name, value:item.category_id, checked: !isEmpty(cateChecked) ? cateChecked[0].checked : false });  
      })
      setCategoryFilterData(generatedCategoryData)
    }, [categoriesData]) // eslint-disable-line
    const getDepartmentData = useCallback((departmentData) =>{
      if(isEmpty(departmentData) )return;
      const generatedDepartmentData = [];
      departmentData.sort().map(item =>{
        const departChecked = departmentFilterData.filter(deptitem => Number(deptitem.value) === Number(item.dept_id));
        generatedDepartmentData.push({name:item.dept_name, value:Number(item.dept_id), checked: !isEmpty(departChecked) ? departChecked[0].checked : false });
      })
      setDepartmentFilterData(generatedDepartmentData);
    }, [departmentData]) // eslint-disable-line
  useEffect(() =>{
    getLocationData(originalData);
  }, [getLocationData, originalData]) // eslint-disable-line
    useEffect(() =>{
      getsBrandData(brandsData);
      getCategoryData(categoriesData)
      getDepartmentData(departmentData)
    
       
    }, [,getDepartmentData, getCategoryData, getsBrandData])  // eslint-disable-line
    const removeAll = (pArr) => {
      const tempArr = [...pArr];
      tempArr.map(item => item.checked = false)
      return tempArr;
    }
    const handleRemoveFilter = () =>{
    //  const categoryData = removeAll(categoryFilterData);
    const pageList = `page=&page_size=&prd_brand__in=&category_id__in=&dept_id__in=`;
     setItem('pageList', JSON.stringify(pageList));
     setCategoryFilterData(removeAll(categoryFilterData));
     setBrandFilterData(removeAll(brandFilterData));
     setDepartmentFilterData(removeAll(departmentFilterData));
     setLocationFilterData(removeAll(locationFilterData));
     removeAllFilters();
    }
    const handleLocationCheck = (e) =>{
    // console.log(e.target.id, e.target.value)
      const tempArr = [...locationFilterData];
        tempArr.map(item => {
          // console.log(item, e.target.id)
          if(Number(item.value) === Number(e.target.id)) {
            item.checked = e.target.checked;
          } else {
            item.checked = false;
          }
          return item
        })
      // console.log(tempArr);
      const pageList = `page=&page_size=&prd_brand__in=&category_id__in=&dept_id__in=`;
      setItem('pageList', JSON.stringify(pageList));
      setLocationFilterData(tempArr);
      const filterd = tempArr.filter(item => {return item.checked && item.value}).map(item => item.value)
      // console.log(filterd)
      handleLocationFilter('loc', filterd);
    }
    const handleBrandChecks = (e) =>{
      const tempArr = [...brandFilterData];
      tempArr.map(item => {
        if(item.name === e.target.id) {
          item.checked = e.target.checked;
        }
        return item
      })
      const pageList = `page=&page_size=&prd_brand__in=&category_id__in=&dept_id__in=`;
      setItem('pageList', JSON.stringify(pageList));
      setBrandFilterData(tempArr);
      const filterd = tempArr.filter(item => {if(item.checked) return item.name}).map(item => item.name)
      // console.log('filterd ', filterd)
      handleBrandFilter('brand', filterd);
    }


    const handleDepartmentChecks = (e) =>{
        const tempArr = [...departmentFilterData];
        tempArr.map(item => {
          if(item.name === e.target.id) {
            item.checked = e.target.checked;
          }
          return item
        })
        const pageList = `page=&page_size=&prd_brand__in=&category_id__in=&dept_id__in=`;
        setItem('pageList', JSON.stringify(pageList));
        setDepartmentFilterData(tempArr);
        const filterd = tempArr.filter(item => {
          if(item.checked) return item.value
        }).map(item => item.value)
        // console.log('filtered', filterd);
        handleDeptFilter('dept', filterd);
    }
      const handleCategoryChecks = (e) =>{
        const tempArr = [...categoryFilterData];
        tempArr.map(item => {
          if(item.name === e.target.id) {
            console.log('handle category ', item.checked, e.target.checked);
            item.checked = e.target.checked;
          }
          return item
        })
        const pageList = `page=&page_size=&prd_brand__in=&category_id__in=&dept_id__in=`;
        setItem('pageList', JSON.stringify(pageList));
        setCategoryFilterData(tempArr);
        const filterd = tempArr.filter(item => (item.checked && item.value)).map(item => item.value)
        handleCategoryFilter('category', filterd);
      }


return {
    categoryFilterData, 
    departmentFilterData,
    brandFilterData, 
    locationFilterData,
    handleDepartmentChecks,
    handleCategoryChecks,
    handleBrandChecks,
    handleLocationCheck,
    handleRemoveFilter,
    // isDeptExpanded,
    // isCategoryExpanded,
    // isBrandExpanded,
    // isLocationExpanded
}

}

export default UseLeft;