import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/changepassword.actions';
import * as constants from '../constants/changepassword.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}

export function* handleChangepassword(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  console.log(payload);
  try {
    const response = yield call(NetworkApi.put, paths.passwordURL, payload, apiConfig);
    if (response && !response?.detail) {
        yield put(actions.changePasswordSuccessful(response));
      
    } else {
      console.log('error ', response)
      const message = response?.data?.detail ? (
        response?.data?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.changePasswordFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
      error.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.changePasswordFailed(message));
  }
}


export function* postChangepassword() {
  yield takeLatest(constants.PASSWORD_MODIFY, handleChangepassword);
}
