import * as constants from '../constants/marketLocation.constants';

export const initialState = {
  
  marketLocationDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    mktlocdata: null,
    message: null,
  },
  isSessionExpired:false
};

export const marketLocationReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.GET_MARKET_LOCATION:
      return {
        ...state,
        marketLocationDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          mktlocdata: null,
          errorMessage: null
        },
      };
    case constants.MARKET_LOCATION_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        marketLocationDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          mktlocdata: action.response
        }
      };
    case constants.MARKET_LOCATION_FAILED:
      return {
        ...state,
        marketLocationDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          mktlocdata: null
        }
      };
      case constants.MARKET_LOCATION_RESET:
      return {
        ...state,
        marketLocationDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          message: null,
          mktlocdata: null
        }
      };
      
    default:
      return state;
  }
};
