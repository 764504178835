export const MYPRODUCT_GET = 'MYPRODUCT_GET';
export const MYPRODUCT_SUCCESSFULL = 'MYPRODUCT_SUCCESSFULL';
export const MYPRODUCT_FAILED = 'MYPRODUCT_FAILED';
export const MYPRODUCT_RESET = 'MYPRODUCT_RESET';


export const PRODUCTDETAILS_GET = 'PRODUCTDETAILS_GET';
export const PRODUCTDETAILS_SUCCESSFULL = 'PRODUCTDETAILS_SUCCESSFULL';
export const PRODUCTDETAILS_FAILED = 'PRODUCTDETAILS_FAILED';
export const PRODUCTDETAILS_RESET = 'PRODUCTDETAILS_RESET';


export const COMPETITORS_GET = 'COMPETITORS_GET';
export const COMPETITORS_SUCCESSFULL = 'COMPETITORS_SUCCESSFULL';
export const COMPETITORS_FAILED = 'COMPETITORS_FAILED';
export const COMPETITORS_RESET = 'COMPETITORS_RESET';


export const LOCATIONS_GET = 'LOCATIONS_GET';
export const LOCATIONS_SUCCESSFULL = 'LOCATIONS_SUCCESSFULL';
export const LOCATIONS_FAILED = 'LOCATIONS_FAILED';
