import * as constants from '../constants/changepassword.constants';

export const handleChangepassword = (payload) => ({
  type: constants.PASSWORD_MODIFY,
  payload
});

export const changePasswordSuccessful = (response) => ({
  type: constants.PASSWORD_SUCCESSFUL,
  response
});

export const changePasswordFailed = (payload) => ({
  type: constants.PASSWORD_FAILED,
  payload
});
