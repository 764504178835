import * as constants from '../constants/adddepartment.constants';

export const handleAddDepartment = (payload) => ({
  type: constants.ADD_DEPARTMENT,
  payload
});

export const addDepartmentSuccessful = (response) => ({
  type: constants.ADD_DEPARTMENT_SUCCESSFUL,
  response
});

export const addDepartmentFailed = (payload) => ({
  type: constants.ADD_DEPARTMENT_FAILED,
  payload
});

export const addDepartmentReset = (payload) => ({
  type: constants.ADD_DEPARTMENT_RESET,
  payload
});
