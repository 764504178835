import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/productprevnext.actions';
import * as constants from '../constants/productprevnext.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* getProductPrevNext(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  // console.log(payload)
  try {
    const response = yield call(NetworkApi.post, paths.productPrevNextURL, payload, apiConfig);
    //  console.log( 'add location ',response)
    if (response) {
      yield put(actions.productPrevNextSuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.status ? (
        response?.status
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.productPrevNextFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.status ? (
      error?.response?.data?.status
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.productPrevNextFailed(message));
  }
}

export function* handleProductPrevNext() {
  yield takeLatest(constants.PRODUCT_PREVNEXT_GET, getProductPrevNext);
}
