import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/updatelocation.actions';
import * as constants from '../constants/updatelocation.constants';
import * as locActions from '../actions/productLocations.actions'
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* handleUpdateLocation(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  //  console.log('handleUpdateLocation', 'payload ', payload)

  try {
    const response = yield call(NetworkApi.put, paths.updateLocationURL, payload, apiConfig);
    // console.log( 'add location ',response)
    if (response && !response?.detail) {
        yield put(actions.updateLocationSuccessful(response));
        yield put(locActions.getProductLocations())
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.updateLocationFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
      error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.updateLocationFailed(message));
  }
}

export function* updateLocation() {
  yield takeLatest(constants.UPDATE_LOCATION, handleUpdateLocation);
}
