import React, { useState, useContext, useEffect } from 'react';
import {Grid, Typography, Button, Stack, Paper, IconButton} from '@mui/material';

import {styled} from '@mui/material/styles'

import { FileUploader } from "react-drag-drop-files";
import {CustomThemeContext} from '../../../theme/CustomThemeProvider'
import CircularStatic from '../../../components/circularLoader';
import { } from './style.css';
import PlayCircleFilledIcon  from '@mui/icons-material/PlayCircleFilled';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

import UseAddProduct from './useAddBulkProduct';

const fileTypes = ["XLSX", "XLS"];

const BulkUpload = props => {

    const {currentTheme} = useContext(CustomThemeContext)
    const [file, setFile] = useState(null);
    const [error, setError] = useState(false);
    const [loader, showLoader] = useState(false);
    const { handleBulkProductSubmit, isSuccessful, isFailure, errorMessage } = UseAddProduct();
    const handleChange = (file) => {
      setFile(file);
      setError(false);
    };

    useEffect(() =>{

        if(isFailure || isSuccessful){
            setFile(null)
        }
    }, [isFailure, isSuccessful])

    const handleSubmit = (values) =>{
        if(!values){
            setError(true)
            return
        }
        setError(false)
        showLoader(true);
        handleBulkProductSubmit(values);
    }

    const Item = styled(Paper)(({ theme }) => ({
       // backgroundColor: theme.palette.type === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        border:'1px solid ',
        borderColor: theme.palette.text.primary
      }));

      const btnStyle = {fontSize:12, textDecoration:'underline', color:'text.secondary', textTransform:'inherit'}


return (
        <Grid item sx={{mt:1}}>
            {/* <Typography variant='h4' component={'h4'} sx={{fontSize:15, textAlign:'center', fontWeight:500}}>Bulk Upload</Typography> */}

            <Grid item xs={12} sx={{display:'flex', pt:5, flexFlow:'column', alignItems:'center'}}>
                {
                     !loader &&
                     <><FileUploader
                    multiple={true}
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    className='drop_area'
                />
                <Typography variant='body2' component={'p'} sx={{mt:2, fontSize:13 }}>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</Typography>
                </>
                }
                
                {loader && <CircularStatic style={{marginTop:'8px'}} showLoader={showLoader} loader={loader} isSuccessful={isSuccessful} isFailure={isFailure} />}
                { !loader &&
                    <Button sx={{width:'40%',mt:3, backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}}
                        onClick={e => handleSubmit(file)}    
                    >Add products</Button>
                }
                { error && <Typography variant='body2' component={'p'}>Select file before uploading...</Typography> }
                { isSuccessful && <Typography variant='body2' component={'p'}>Products uploaded successfully.</Typography> }
                { isFailure && errorMessage && <Typography variant='body2' component={'p'}>There are some issue with file. Please check and upload again.</Typography>}
            </Grid>

            <Grid item xs={12} sx={{mt:0}}>
                <Typography variant='h4' component={'h4'} sx={{ fontSize:16, fontWeight:500}}>Need Help?</Typography>

                <Stack spacing={2} sx={{mt:3}}>
                    <Item sx={{display:"flex", alignItems:'center'}}>
                        <Grid item xs={2} >
                            <IconButton >
                                <PlayCircleFilledIcon  sx={{color:'#1C3B6E', fontSize:50}} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'left'}} >
                            <Typography variant='h4' component={'h4'} sx={{ fontSize:16, fontWeight:500}} > How to prepare your import file.</Typography>
                            <Typography variant='body2' sx={{fontSize:11}} > This video guide will show you how to prepare your import file.</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <Button variant='text' sx={{...btnStyle, fontSize:16}} > View import guide</Button>
                        </Grid>
                    </Item>

                    <Item sx={{display:"flex", alignItems:'center'}}>
                        <Grid item xs={2} >
                            <IconButton >
                                <BrowserUpdatedIcon  sx={{color:'#1C3B6E', fontSize:50}} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign:'left'}} >
                            <Typography variant='h4' component={'h4'} sx={{ fontSize:16, fontWeight:500}} > Download Sample import file.</Typography>
                            <Typography variant='body2' sx={{fontSize:11}}  > Would you like to see how import file looks like? Download sample file and test import process..</Typography>
                        </Grid>
                        <Grid item xs={4} >
                            <a href='/assets/Bulk_uplaod_field.xlsx' style={{...btnStyle, color:currentTheme === 'dark'?'#FFFFFF' : '#272727', fontSize:16}} target={'_blank'}>Download sample file</a>
                            {/* <Button variant='text' sx={{...btnStyle, fontSize:16}} > Download sample file</Button> */}
                        </Grid>
                    </Item>
                    
                    
                </Stack>
            </Grid>

        </Grid>
)


}

export default BulkUpload;