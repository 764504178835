import * as constants from '../constants/locations.constants';

export const getLocations = (payload) => ({
  type: constants.LOCATION_GET,
  payload
});

export const getLocationsSuccessful = (response) => ({
  type: constants.LOCATION_SUCCESSFUL,
  response
});

export const getLocationsFailed = (payload) => ({
  type: constants.LOCATION_FAILED,
  payload
});
export const getLocationsReset = (payload) => ({
  type: constants.LOCATION_RESET,
  payload
});
