import * as constants from '../constants/sellers.constants';

export const initialState = {
  
  sellerDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
};

export const sellersReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.SELLER_GET:
      return {
        ...state,
        sellerDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          errorMessage: null
        },
        productsCreated: false
      };
    case constants.SELLER_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        sellerDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          data: action.response
        }
      };
    case constants.SELLER_FAILED:
      return {
        ...state,
        sellerDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
        }
      };
      case constants.SELLER_RESET:
      return {
        ...state,
        sellerDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          message: null,
          data: null
        }
      };
    default:
      return state;
  }
};
