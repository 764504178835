import * as constants from '../constants/department.constants';

export const getDepartment = (payload) => ({
  type: constants.DEPARTMENT_GET,
  payload
});

export const getDepartmentSuccessful = (response) => ({
  type: constants.DEPARTMENT_SUCCESSFUL,
  response
});

export const getDepartmentFailed = (payload) => ({
  type: constants.DEPARTMENT_FAILED,
  payload
});
