import * as constants from '../constants/productPriceVariation.constants';

export const getProductsPriceVariation = (payload) => ({
  type: constants.PRODUCTPRICE_GET,
  payload
});
export const productsPriceVariationSuccessful = (response) => ({
    type: constants.PRODUCTPRICE_SUCCESSFULL,
    response
  });
  
  export const productsPriceVariationFailed = (payload) => ({
    type: constants.PRODUCTPRICE_FAILED,
    payload
  });
  export const productsPriceVariationReset = (payload) => ({
    type: constants.PRODUCTPRICE_RESET,
    payload
  });
