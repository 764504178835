
import React, { } from 'react';
import {FormControl, InputBase, InputLabel} from '@mui/material';
import { styled } from '@mui/material/styles';
// import { values } from 'lodash';

const Input = props => {

    const {labelName, placeHolderValue, onChange} = props;


    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));

return (
    <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
        { labelName && <InputLabel shrink htmlFor={labelName} sx={{color:'text.primary'}}>
            {labelName}
            </InputLabel>
        }
        <BootstrapInput 
           // defaultValue={inputValue} 
            // value={value} 
            placeholder={placeHolderValue} 
            id={labelName} 
            onChange={onChange}
        />
      </FormControl>
    )


}

export default Input;