import * as constants from '../constants/addseller.constants';

export const handleAddSeller = (payload) => ({
  type: constants.ADD_SELLER,
  payload
});

export const addSellerSuccessful = (response) => ({
  type: constants.ADD_SELLER_SUCCESSFUL,
  response
});

export const addSellerFailed = (payload) => ({
  type: constants.ADD_SELLER_FAILED,
  payload
});

export const addSellerReset = (payload) => ({
  type: constants.ADD_SELLER_RESET,
  payload
});
