import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  MenuItem, Select, InputBase } from '@mui/material';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import selectCategoriesDetails from '../../redux/selectors/categories.selector'
import * as actions from '../../redux/actions/categories.actions'

import AddCategory from './addCategory'

const Categories = props =>{
const dispatch = useDispatch()
const {value, handleChange} = props;
const [cateBool, setCateBool] = useState(false);
const { categoryDetails } = useSelector(selectCategoriesDetails);
const [selectValue, setSelectValue] = useState(value !== '' ? value : 'category');
const {data} = categoryDetails;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
      width: '100%',
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '9px 12px',
      textTransform:'capitalize',
      color: theme.palette.text.primary,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily:'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
}));
useEffect(()=>{
    if(!data){
        dispatch(actions.getCategories());
    }
}, [data, dispatch])

const handleClose = (pData) =>{
  if(pData !== null && !isEmpty(pData)){
    const sData = [...data];
    sData.push(pData);
    // console.log('handleclose ', pData, data)
    dispatch(actions.getCategoriesSuccessful(sData));
  }
  setCateBool(false);
}

const handleSelectChange = (e) =>{
    if(e.target.value === 'addcategory') {
      setCateBool(true)
      return
    };
    setSelectValue(e.target.value);
    handleChange(e)
}

return (
    <>
    {cateBool && <AddCategory handleClose={handleClose} cateData = {data} />}
    <Select size='small' variant='outlined' onChange={handleSelectChange} value={selectValue}
         sx={{...props.style, fontSize:11, fontFamily:'Inter', color:'text.secondary', fontWeight:400}}
         input={<BootstrapInput sx={{ marginTop:'0px !important' }} />}
         MenuProps={{className:'custom-scrollbar', style: {maxHeight:300}}} 
    >
        {/* <MenuItem value='category'>Select Category</MenuItem> */}
        { data && data.map((item, index) =>{
            const {category_id, category_name} = item;
            return <MenuItem key={`category${index}`} sx={{textTransform:'capitalize'}} value={category_id}>{category_name}</MenuItem>
        })
        }
        {/* <ListSubheader>Not Found</ListSubheader>*/}
        <MenuItem value='addcategory'>Add Category</MenuItem> 
    </Select>
    </>
)}

export default Categories;