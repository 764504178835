import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InputBase, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

import selectLocationDetails from '../../redux/selectors/locations.selector'
import * as actions from '../../redux/actions/locations.actions'
import AddLocations from './addLocations';

const Locations = props =>{
const dispatch = useDispatch()
const { handleChange, multiple} = props;
const { locationDetails } = useSelector(selectLocationDetails);
const [selectValue, setSelectValue] = useState(['']);
const [addLocationBool, setAddLocation] = useState(false)
const { data } = locationDetails;

// console.log('marketPlace ', data);

useEffect(()=>{
    if(!data){
        dispatch(actions.getLocations());
    }
}, [data, dispatch])

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '8px 12px',
      color: theme.palette.text.primary,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily:'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));

const handleSelectChange = (event) =>{
    const { target: { value }, } = event;
    console.log('value ', typeof(value))
    if(typeof(value) === 'object' && value.includes('addlocations')){
        setAddLocation(true)
        return null;
    }
    if(typeof(value) === 'string' && value === 'addlocations'){
        setAddLocation(true)
        return null;
    }
    setSelectValue(
        typeof value === 'string' ? value.split(',') : value,
    );
    handleChange(event)
}

const handleClose = () =>{
    setAddLocation(false);
}


return (
    <>
        {addLocationBool && <AddLocations modalOpen={addLocationBool} handleClose={handleClose} />}
    <Select size='small' multiple={multiple} variant='outlined'
        input={<BootstrapInput/>}
        MenuProps={{className:'custom-scrollbar', style: {maxHeight:200}}}
        onChange={handleSelectChange} value={selectValue}
         sx={{...props.style, fontSize:11, fontFamily:'Inter', color:'text.secondary', fontWeight:400}}
    >
        {/* <MenuItem value='location'>Select Location</MenuItem> */}
        { data && data.map((item, index) =>{
            const {loc_id, loc_name} = item;
            return <MenuItem key={`market${index}`} value={loc_id}>{loc_name}</MenuItem>
        })
        }
        <MenuItem value='addlocations'>Add Location</MenuItem>
    </Select>
    </>
)}

export default Locations;