import * as constants from '../constants/matchrate.constants';

export const handleMatchRate = (payload) => ({
  type: constants.UPDATE_MATCHRATE,
  payload
});

export const matchrateSuccessful = (response) => ({
  type: constants.MATCHRATE_SUCCESSFUL,
  response
});

export const matchrateFailed = (payload) => ({
  type: constants.MATCHRATE_FAILED,
  payload
});
export const matchrateReset = (payload) => ({
  type: constants.MATCHRATE_RESET,
  payload
});

