import * as constants from '../constants/downloadxls.constants';

export const handleDownloadXls = (payload) => ({
  type: constants.DOWNLOAD_XLS,
  payload
});

export const downloadXlsSuccessful = (response) => ({
  type: constants.DOWNLOAD_XLS_SUCCESSFUL,
  response
});

export const downloadXlsFailed = (payload) => ({
  type: constants.DOWNLOAD_XLS_FAILED,
  payload
});
export const downloadXlsReset = (payload) => ({
  type: constants.DOWNLOAD_XLS_RESET ,
  payload
});
