import * as constants from '../constants/qaUser.constants';

export const getQAUser = (payload) =>({
  type: constants.GET_QA_USER,
  payload
})

export const qaUserSuccessful = (response) => ({
    type: constants.QA_USER_SUCCESSFUL,
    response
  });
  
  export const qaUserFailed = (payload) => ({
    type: constants.QA_USER_FAILED,
    payload
  });
  export const qaUserReset = (payload) => ({
    type: constants.QA_USER_RESET,
    payload
  });
  