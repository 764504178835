import * as constants from '../constants/addmarketrules.constants';

export const initialState = {
  
  addMarketruleDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
  isSessionExpired:false
};

export const addMarketruleReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.ADD_MARKET_RULE:
      return {
        ...state,
        addMarketruleDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          ruledata: null,
          errorMessage: null
        },
      };
    case constants.ADD_MARKET_RULE_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        addMarketruleDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          errorMessage: null,
          ruledata: action.response
        }
      };
    case constants.ADD_MARKET_RULE_FAILED:
      return {
        ...state,
        addMarketruleDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          errorMessage: payload,
          ruledata: null
        }
      };
      case constants.ADD_MARKET_RULE_RESET:
      return {
        ...state,
        addMarketruleDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          errorMessage: null,
          ruledata: null
        }
      };
      
      
    default:
      return state;
  }
};
