import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Grid, FormControl, OutlinedInput, InputAdornment,InputBase, Icon, Button, FormGroup, Typography, Select, MenuItem } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { getItem } from '../../utils/storage';
import Table from '../../components/Table/index';
import {styled} from '@mui/material/styles'
import Modal from '../../components/Modal'
import UseMyProduct from '../MyProduct/myproduct';
import DownloadXls from '../../components/Downloadxls';
import Loader from '../../components/loader';
import { Fragment } from 'react';

const RightPanel = forwardRef((props, ref) => {

  const {rowsPerPage, page, handleQaUser, getFilterdData, filteringData, categoringData, brandFilterData, locationFilterData, setSelectedLoc } = props;

  const { userRole, userList, filterdProducts, data, isLoading, isFailure, qaSuccess, handleApproveAll, handleGetProductList, setProductList, getProductList, marketPlaceLabel, selectLoc } =  UseMyProduct({page, rowsPerPage, filteringData, categoringData, brandFilterData, locationFilterData})
  
  const [approvePop, showApprovePop] = useState(false)


  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(0),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '8px 12px',
      color: theme.palette.text.primary,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily:'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
}));
  
  
  useImperativeHandle(ref, (e) =>({
    handleFilters(filtData, cateData, brndData ){
      //  console.log(filteringData, categoringData, brandFilterData, locationFilterData)
      setTimeout(() => {
        setProductList(filtData, cateData, brndData);
      }, 100)
      
    }
  }))
  useEffect(() =>{
    if(!isEmpty(filterdProducts)){
      // console.log(filterdProducts, 'data', data)
      getFilterdData(filterdProducts, data);
    }
  }, [filterdProducts, getFilterdData, data]) // eslint-disable-line

  useEffect(() =>{
      setSelectedLoc(selectLoc);
  }, [selectLoc]) // eslint-disable-line

  const OutlinedBox = styled(OutlinedInput)(({theme})=>{
    return {
        '.MuiOutlinedInput-notchedOutline':{
            borderColor: '#7B7B7B'
        }
    }
  })
  
  const handleProductList = () =>{
    getProductList();
  }
  const handleSearchProduct = () => {
    const searchStr = `search=${document.getElementById('searchTextBox').value}` 
    getProductList(searchStr)
  } 
  const handleSearchEvent = (event) =>{
    event.preventDefault();
    if(event.keyCode === 13) handleSearchProduct()
  }
  const cancelApproveAll = () =>{
    showApprovePop(false);
  }
  const confirmApproveAll = () =>{
    showApprovePop(false);
    handleApproveAll()
  }
  const openApproveAll = () => {
    showApprovePop(true);
  }
  
    return (
      <Fragment>
        {approvePop && <Modal width={300} modalOpen={approvePop} handleClose={cancelApproveAll}>
          <Typography>
            Are you sure to approve all products?
          </Typography>
          <Grid item xs={12} sx={{mt:3, display:'flex', justifyContent:'center'}}>
          <Button onClick={cancelApproveAll}
          sx={{backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}>
              Cancel</Button>
          <Button onClick={confirmApproveAll} sx={{ml:1, backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}
            >Okay</Button>
          </Grid>
          </Modal>}
      
        <Grid item xs={10} sm={10} md={10} sx={{padding:2, maxWidth:'85% !important', flexBasis:'85% !important', width:'85% !important'}}>
            <Grid item sx={{display:'block'}}>
            {!isEmpty(filterdProducts) ? 
            <>
            <Box sx={{display:'flex', justifyContent:'space-between'}}>
              <FormGroup sx={{flexDirection:'row', alignItems:'center'}}>
                <FormControl sx={{ m: 0, mr:1, mb:2, width: '30ch' }} variant="outlined">
                  <OutlinedBox size='small'
                    id="searchTextBox"
                    type='text' sx={{ fontSize:11, backgroundColor:'background.paper', color:'text.secondary'}}
                    onKeyUp = {handleSearchEvent}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon
                          aria-label="search icon"
                          edge="end"
                        >
                          <SearchOutlined sx={{color:'text.secondary'}} />
                        </Icon>
                      </InputAdornment>
                    }
                    label=""
                  />
                </FormControl>
                <FormControl sx={{ m: 1, mt:0, mb:2, width: '7em' }}>
                  <Button sx={{backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}
                    onClick={handleSearchProduct}
                  >Search</Button>
                </FormControl>
                <FormControl sx={{ m: 1, mt:0, mb:2, width: '7em' }}>
                  <Button sx={{backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}
                    onClick={handleProductList}
                  >Refresh</Button>
                </FormControl>
              </FormGroup>
              {/* {console.log('qaSuccess ', qaSuccess)} */}
              {userRole === 2 && <>
              <Grid item sx={{display:'flex', flexFlow:'column'}}>
              {qaSuccess && <Typography variant='' component={'p'} >All Product approved successfully.</Typography>}
              <Grid item sx={{display:'flex', alignItems:'center'}}>
              <Select size='small' 
                  input={<BootstrapInput sx={{marginTop:'0px !important', height:36, width:180}}/>}
                  value={getItem('qaUser')}
                  // defaultValue={}
                  className='custom-scrollbar'
                  MenuProps={{className:'custom-scrollbar', style: {maxHeight:200}}}
                  style={{marginLeft:1}}
                  onChange={(e) => {handleQaUser(e, userList); handleGetProductList(e);}}>
                  {!isEmpty(userList) && userList.map(item =>{
                    return <MenuItem key={`qauser_${item.id}`} value={item.id}>{item.email}</MenuItem>
                  })}
              </Select>
              <Button sx={{m: 1, mt:0, mb:0, backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}} onClick={openApproveAll}>Approve All</Button>
              </Grid>
              </Grid>
              </>
              }
              <DownloadXls {...props} />
            </Box>
            <Grid container sx={{}}>
                  <Table
                    getProductList= {getProductList}
                    data = {data}
                    userRole={userRole}
                    productData={filterdProducts}
                    marketPlaceLabel= {marketPlaceLabel}
                    {...props}
                  />
            </Grid>
            </>
            : <>
                {<Grid item sx={{height:400, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  { 
                      isLoading && (!isFailure) ? 
                      <Loader />
                      : <Typography variant='' component={'p'} >Products are not available</Typography>
                    
                   }

                  { ((isEmpty(filterdProducts) && !isEmpty(data)) || isFailure)  &&
                  <Button
                    sx={{ mt:1, backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                    onClick={handleProductList}>Refresh</Button>
                  }
                </Grid>
                }
                </>
            }
          </Grid>
        </Grid>
        </Fragment>
    )
}, ()=> true)

export default RightPanel;