import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, FormGroup, Typography, Grid, Divider, Button, FormControl, InputBase,InputLabel, Select, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
import Categories from '../Categories';
import Department from '../Department';
// import Locations from '../locations';


import CompModal from '../Modal'
import { isNaN } from 'lodash';

const editProductSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })

const [token] = getItems(['token',]);
// console.log("token==>",token)

const steps = [
    'Step 1',
    'Step 2'
  ];


const EditCompetitor = props => {
    
     const { productInformation, handleClose } = props;
    // const params = useParams()
    // const { id } = params;

    const [activeStep, setActiveStep] = useState(0);
    const [completed, ] = useState({}); 
    const [isSuccessful,setIsSuccessful] = useState(false)
    const [isError,setIsError] = useState("")

    const {
        comp_prd_id, 
        comp_category_name,
        comp_dept_name,
        comp_prd_name, 
        comp_prd_desc, 
        comp_prd_brand, 
        comp_prd_rating, 
        comp_prd_tot_reviews,
        comp_prd_spec,  
        category, 
        comp_prd_variants,  
        comp_prd_medium_image, 
        comp_prd_small_image, 
            dept, 
        comp_prd_upc, 
        comp_prd_asin, 
        comp_prd_uom, 
        comp_prd_url, 
        comp_prd_no_of_pieces, 
        comp_prd_size, 
        loc,   
        comp_prd_norm_price, 
        comp_prd_sales_price  } = productInformation;

    // console.log("productInformation==>",productInformation)
    // console.log(locations)
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));


    const totalSteps = () => {
        return steps.length;
      };
    const completedSteps = () => {
        return Object.keys(completed).length;
      };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
      };
    const allStepsCompleted = () => {
       return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
              // find the first step that has been completed
              steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
// handleSubmit
      const handleSubmit = async (values) => {
        try {
        //   console.log('values in competitor ==>', values);
          // Create the request payload
          const payload = {
          comp_prd_name: values.comp_prd_name || "",
          comp_prd_desc: values.comp_prd_desc || "",
          comp_prd_medium_image: values.comp_prd_medium_image || "",
          comp_prd_brand:values.comp_prd_brand || "",
          comp_prd_asin: values.comp_prd_asin || "",
          comp_prd_upc: values.comp_prd_upc || "",
          comp_prd_no_of_pieces: values.comp_prd_no_of_pieces || "",
          comp_prd_size: values.comp_prd_size || "",
          comp_prd_url: values.comp_prd_url || "",
          comp_prd_uom: values.comp_prd_uom || "",
          comp_category_name: values.comp_category_name || "",
          comp_dept_name: values.comp_dept_name || "",
          comp_prd_spec: values.comp_prd_spec || "",
          comp_prd_mrp: values.comp_prd_mrp || 0,
          comp_prd_sales_price: values.comp_prd_sales_price || 0,
          };
      
          const headers = {
            Authorization: `token ${token}`,
          };
      
          // Make the PUT request using Axios
          const response = await axios.put(`${paths.qaUpdateCompetitorURL}?comp_prd_id=${comp_prd_id}`, payload, { headers: headers });
            // console.log('PUT request successful==>', response.data);
            setIsSuccessful(true)
          // Refresh the screen after a timeout of 1000 milliseconds
          setTimeout(() => {
            window.location.reload(); // Refresh the current page
          }, 1000);
        } catch (error) {
          console.error('Error making PUT request:', error);
          setIsError(error.message)
        }
      };

     
    return (
        <CompModal  {...props}>
            <Grid item display="flex" >
            <Typography variant="h5" component={'h5'}>Edit Product</Typography>
            <Typography style={{ marginLeft: 'auto' }} variant="h6" component={'h6'}>Product Id : {comp_prd_id}</Typography>
            </Grid>
            <Divider />
            <Grid item sx={{mt:1}}>
                {isSuccessful && <Typography variant='body2'>Product edited successfully.</Typography>}
                {isError && <Typography variant='body2'>Product update failed.</Typography>}
            <Stepper activeStep={activeStep+1} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <Formik enableReinitialize
            initialValues={{
                comp_category_name: comp_category_name || "",
                comp_dept_name: comp_dept_name || "",
                comp_prd_tot_reviews:comp_prd_tot_reviews || 0,
                comp_prd_id: comp_prd_id || '',
                comp_prd_name: comp_prd_name || '',
                comp_prd_desc : (comp_prd_desc && comp_prd_desc.toLowerCase() !== 'nan' && comp_prd_desc) || '',
                comp_prd_medium_image: comp_prd_medium_image || '',
                comp_prd_small_image: comp_prd_small_image || '',
                comp_prd_brand: comp_prd_brand || '',
                category_id: category?.category_id || '',
                dept_id : dept.dept_id || '',
                loc_id: loc.loc_id || 'loc',
                loc_name:loc.loc_name || "",
                comp_prd_variants:comp_prd_variants || '',
                comp_prd_url: comp_prd_url || '',
                comp_prd_upc: (comp_prd_upc.toLowerCase() !== 'nan' && comp_prd_upc) || '',
                comp_prd_asin: (comp_prd_asin.toLowerCase() !== 'nan' && comp_prd_asin) || '',
                comp_prd_uom: comp_prd_uom || '',
                comp_prd_size: comp_prd_size || '',
                comp_prd_rating: comp_prd_rating || 0, 
                comp_prd_spec: comp_prd_spec || '',
                comp_prd_no_of_pieces: comp_prd_no_of_pieces || '',
                comp_prd_sales_price: comp_prd_sales_price || '',
                comp_prd_norm_price: comp_prd_norm_price || '',
                
            }}
            validationSchema={editProductSchema}
            >
                {({ errors, 
                    setFieldValue,
                    //handleSubmit,
                    values 
                }) => (
                <FormGroup sx={{flexFlow:'column'}}>
                    <Grid item xs={12} sx={{display:activeStep === 0 ? 'flex':'none'}}>
                        <Grid item xs={12} sm={6} sx={{mr:2, pr:2}}>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='comp_prd_name' sx={{color:'text.primary'}}>
                                Product Name
                                </InputLabel>
                            <BootstrapInput 
                                value={values.comp_prd_name} 
                                placeholder='Enter Product Name'
                                id='comp_prd_name'
                                onChange={e => setFieldValue('comp_prd_name', e.target.value)}
                            />
                            <Typography color="red" variant="subtitle">{errors.productName}</Typography>
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productDescription' sx={{color:'text.primary'}}>
                                Product Description
                                </InputLabel>
                            <BootstrapInput 
                                value={values.comp_prd_desc} 
                                placeholder='Enter Product Description'
                                id='productDescription'
                                onChange={e => setFieldValue('comp_prd_desc', e.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productImage' sx={{color:'text.primary'}}>
                                Product Image
                                </InputLabel>
                            <BootstrapInput 
                                value={values.comp_prd_medium_image} 
                                placeholder='Enter Product Image Url'
                                id='productImage'
                                onChange={e => setFieldValue('comp_prd_medium_image', e.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='productBrand' sx={{color:'text.primary'}}>
                                Brand
                                </InputLabel>
                            <BootstrapInput 
                                value={values.comp_prd_brand} 
                                placeholder='Enter Brand Name'
                                id='productBrand'
                                onChange={e => setFieldValue('comp_prd_brand', e.target.value)}
                            />
                        </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}sx={{mr:2, pr:2}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productCategory' sx={{position:'relative', color:'text.primary'}}>
                                    Category
                                    </InputLabel>
                                <Categories value={values.category_id}  handleChange={e => setFieldValue('category', e.target.value)}/>
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productDepartment' sx={{position:'relative', color:'text.primary'}}>
                                    Department
                                    </InputLabel>
                                    <Department value={values.dept_id} 
                                        handleChange={e =>  setFieldValue('dept', e.target.value)} />
                                
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productSpec' sx={{color:'text.primary'}}>
                                    SPEC
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_spec} 
                                    // placeholder='Enter Keywords'
                                    id='productSpec'
                                    onChange={e => setFieldValue('spec', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productURL' sx={{color:'text.primary'}}>
                                    URL
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_url} 
                                    placeholder='Enter URL'
                                    id='productURL'
                                    onChange={e => setFieldValue('comp_prd_url', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{display:activeStep === 1 ? 'flex':'none'}} >
                        <Grid item xs={12} sm={6} sx={{mr:2, pr:2}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productUPC' sx={{color:'text.primary'}}>
                                    UPC
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_upc} 
                                    placeholder='Enter UPC'
                                    id='productUPC'
                                    onChange={e => setFieldValue('comp_prd_upc', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productASIN' sx={{color:'text.primary'}}>
                                    ASIN
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_asin} 
                                    placeholder='Enter ASIN'
                                    id='productASIN'
                                    onChange={e => setFieldValue('comp_prd_asin', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productMeasure' sx={{color:'text.primary'}}>
                                    Unit of measurement
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_uom} 
                                    placeholder='Enter Unit of Measurement'
                                    id='productMeasure'
                                    onChange={e => setFieldValue('comp_prd_uom', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productSize' sx={{color:'text.primary'}}>
                                    Size
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_size} 
                                    placeholder='Enter Size'
                                    id='productSize'
                                    onChange={e => setFieldValue('comp_prd_size', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}sx={{mr:2, pr:2}}>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productQuantity' sx={{color:'text.primary'}}>
                                    Number of pieces
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_no_of_pieces} 
                                    placeholder='Enter Number of pieces'
                                    id='productQuantity'
                                    onChange={e => {
                                        setFieldValue('comp_prd_no_of_pieces', e.target.value)
                                        if(!isNaN(values.comp_prd_sales_price) ){
                                            setFieldValue('comp_prd_norm_price', Number(values.comp_prd_sales_price/e.target.value).toFixed(2))
                                        } 
                                    }}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='location' sx={{color:'text.primary'}}>
                                    Location
                                </InputLabel>
                                <BootstrapInput 
                                    readOnly
                                    value={values.loc_name} 
                                    id='location'
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productMRP' sx={{color:'text.primary'}}>
                                    MRP
                                </InputLabel>
                                <BootstrapInput 
                                    // value={values.prd_mrp}
                                    placeholder='Enter MRP'
                                    id='productMRP'
                                    onChange={e => setFieldValue('prd_mrp', e.target.value)}
                                />
                            </FormControl>
                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='productSalePrice' sx={{color:'text.primary'}}>
                                    Sale price
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_sales_price} 
                                    placeholder='Enter Sale Price'
                                    id='productSalePrice'
                                    onChange={e => {
                                        setFieldValue('comp_prd_sales_price', e.target.value)
                                        if(!isNaN(values.comp_prd_no_of_pieces)>0){
                                            setFieldValue('comp_prd_norm_price', Number(e.target.value/values.comp_prd_no_of_pieces).toFixed(2))    
                                        }
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mt:2, display:'flex', justifyContent:'space-between'}}>
                <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} > Back </Button>
                <Button id='updateButton' sx={{ display:activeStep === 1 ? 'flex': 'none', alignSelf:'center', width:'50%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}}
                        onClick={e => handleSubmit(values)} >Update</Button>
                <Button disabled={activeStep === totalSteps()-1} onClick={handleNext} sx={{ mr: 1 }}> Next </Button>
              </Grid>
                </FormGroup>
                )}
            </Formik>
        </Grid>
        </CompModal>
    )

}


export default EditCompetitor;