import React, { useContext } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { setItem } from '../../utils/storage';


// import {styled} from '@mui/material/styles';
// import { StarOutline } from '@mui/icons-material';

import {CustomThemeContext} from '../../theme/CustomThemeProvider';
import AddRules from './addRules';
import NotificationSettings from './notificationPage';
import GeneralSettings from './generalSettings';

const Settings = props => {

    const { currentTheme } = useContext(CustomThemeContext);
    const headingStyle = {color:'text.secondary'};
    setItem('pageList', null);

return (
    <Container maxWidth={'xl'} className='maincontainer' sx={{padding:0, marginTop:2}}>
        <Grid container spacing={0} sx={{display:'flex', flexFlow:'row', pb:2}}>
            <Grid item xs={12} sm={6} sx={{ backgroundColor:'background.paper', display:'flex', flexFlow:'column', justifyContent:'flex-start', padding:5}}>
                <Typography variant='h4' component={'h4'} className='typo headingText' sx={headingStyle}>Add Rules</Typography>
                <AddRules headingStyle={headingStyle} currentTheme={currentTheme} {...props} />
            </Grid>
            <Grid item xs={12} sm={6} >
                <Grid item sx={{ backgroundColor:'background.paper', display:'flex', ml:2, flexFlow:'column', justifyContent:'center', p:5,}}>
                    <GeneralSettings headingStyle={headingStyle} currentTheme={currentTheme} {...props} /> 
                </Grid>
                
                <Grid item sx={{ backgroundColor:'background.paper', display:'flex', ml:2, mt:2, flexFlow:'column', justifyContent:'center', p:5, pl:3}}>
                        <NotificationSettings headingStyle={headingStyle} currentTheme={currentTheme} {...props} />
                </Grid>
                
            </Grid>
        </Grid>
    </Container>
)


}

export default Settings;