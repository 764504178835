import React, { useEffect, useCallback, useState } from 'react';
import { useParams} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, Divider, Button, Select, MenuItem, FormControl, InputBase,InputLabel } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash'
import {handleAddCompetitor, addCompetitorFailed} from '../../../../redux/actions/addcompetitors.actions'
import addCompetitorsDetails from '../../../../redux/selectors/addcompetitors.selector'

import selectMarketPlaceDetails from '../../../../redux/selectors/marketplace.selector'
import {getMarketPlace} from '../../../../redux/actions/marketplace.actions'

import useActions from '../../../../redux/use-actions';

import CompModal from '../../../../components/Modal'
import MarketPlace from '../../../../components/MarketPlace';
// import Locations from '../../../../components/locations';


const AddCompetitorSchema = Yup.object().shape({
    // name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    comp_prd_url: Yup.string().required('Required'),
    market_place_id: Yup.string().required('Required'),
    loc_id: Yup.string().required('Required'),
    comp_prd_score: Yup.string().required('Required')
  })

const AddCompetitor = props => {
    const { handleReload, handleClose } = props;
    const params = useParams()
    const dispatch = useDispatch()
    const { id } = params;
    const { competitorDetails } = useSelector(addCompetitorsDetails);
    const { isSuccessful} = competitorDetails;

    const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
    const {data} = marketPlaceDetails;
    // console.log(data);
    const [locationList, setLocationList] = useState([])
    const [isFormError, setIsFormError] = useState(false);
    //  const [selectedLocation, setSelectedLocation] = useState(null)

    const actions = useActions({ getMarketPlace, handleAddCompetitor, addCompetitorFailed });

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));

    useEffect(()=>{
        if(!data){
            actions.getMarketPlace();
        }
    }, [data]) // eslint-disable-line
    useEffect(() => {
        if(isSuccessful){
            setTimeout(()=> {
                dispatch(actions.addCompetitorFailed())
                handleReload();
                handleClose()
            }, 1000)
        }
    }, [isSuccessful, dispatch, actions, handleReload, handleClose])

    const formStyle = {width:'100%'};
    
    const handleLocationList = (pId, setFieldValue) =>{
        setFieldValue('market_place_id', pId);

        const filteredData = data?.filter(item => item.market_place_id === pId)
        if(!isEmpty(filteredData)){
            setLocationList(filteredData[0].locations)
        }
    }
    const getMatchValue = useCallback(() => {
        const matchList = []
        for(let i=60;i<=100;i++){
            const mvalue= 0+ (i)
            matchList.push(<MenuItem key={`item_${i}`} value={mvalue}>{mvalue} %</MenuItem>)
        }
        return matchList;
    }, [])

    const handleSubmit= (values) =>{
        console.log('values ', values);
        setIsFormError(false);
        const {loc_id,  comp_prd_url, market_place_id} = values;
        if(loc_id === '' || comp_prd_url === '' || market_place_id === ''){
            setIsFormError(true)
            return;
        }
       actions.handleAddCompetitor(values)
    }
      

    return (
        <CompModal {...props}>
                <Typography variant="h5" component={'h5'}>Add Competitor</Typography>
                <Divider />
                {isSuccessful && <Typography variant="body2" component={'p'}>Competitor added successfully.</Typography>}
                <Grid item sx={{width:'100%'}}>
                
                <Formik
                   // enableReinitialize
                    initialValues={{ prd_id:Number(id), loc_id:'', comp_prd_score:100, comp_prd_url: '',market_place_id:''
                    }}
                    validationSchema={AddCompetitorSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form className='loginform' style={{width:'100%'}}>
                        <Grid item xs={12} sx={{display:'flex'}}>
                            
                            <Grid item xs={12} md={12} xl={12} sx={{mr:1, width:'100%'}}>
                            {isFormError && <Typography variant="body2" component={'p'}>All the Fields should not be empty.</Typography>}
                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='url' sx={{color:'text.primary'}}>
                                    URL*
                                </InputLabel>
                                <BootstrapInput 
                                    value={values.comp_prd_url} 
                                    placeholder='Enter your URL '
                                    id='url'
                                    onChange={e => setFieldValue('comp_prd_url', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.comp_prd_url}</Typography>
                            </FormControl>
                            
                            <FormControl id={'descript'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='marketplace' sx={{position:'relative', color:'text.primary'}}>
                                    Market Place*
                                </InputLabel>
                                <MarketPlace style={{marginTop:'0px'}} value={values.market_place_id} 
                                    handleChange={(e)=> handleLocationList(e, setFieldValue)} 
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_id}</Typography>
                            </FormControl>
                            <FormControl id={'descript'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='marketplace' sx={{position:'relative', color:'text.primary'}}>
                                    Location*
                                </InputLabel>
                                <Grid item xs={12} sm={8} sx={{display:'flex', flexFlow:'column'}}>
                                <Select size='small' variant='outlined' 
                                    input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                    sx={{textTransform:'capitalize'}} onChange={e => setFieldValue('loc_id', e.target.value)}>
                                    {!isEmpty(locationList) && locationList.map((locItem, index) => {
                                        return <MenuItem key={`loc_${index}`} value={locItem?.loc.loc_id} sx={{textTransform:'capitalize'}}>{`${locItem?.loc.loc_name}  (${locItem?.loc.loc_zip})`}</MenuItem>
                                    })}
                                </Select>
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.loc_id}</Typography>
                            </Grid>
                                {/* <Locations multiple={false} handleChange = {e => setFieldValue('loc_id', e.target.value)} /> */}
                                
                            </FormControl>
                            <FormControl id={'matchRate'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='matchRate' sx={{position:'relative', color:'text.primary'}}>
                                    Match Rate*
                                </InputLabel>
                                <Select size='small' 
                                    input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                    value={values.comp_prd_score}
                                    className='custom-scrollbar'
                                    MenuProps={{className:'custom-scrollbar', style: {maxHeight:200}}}
                                    style={{marginLeft:0}}
                                    onChange={e => setFieldValue('comp_prd_score', e.target.value)}>
                                    {getMatchValue()}
                                </Select>
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_id}</Typography>
                            </FormControl>
                            </Grid>
                            
                        </Grid>
                    
                        <FormControl sx={{ m: 0, mt:3, mb:2, width:'100%', alignItems:'center' }}>
                            <Button sx={{width:'70%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                            onClick={() => handleSubmit(values)}>Add</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>

                </Grid>
        </CompModal>
    )

}


export default AddCompetitor;