import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  MenuItem, Select } from '@mui/material'; //ListSubheader,

import selectCountryStateDetails from '../../redux/selectors/countrystate.selector';
import * as actions from '../../redux/actions/countrystate.actions'
// import AddMarketPlace from './addMarketPlace';

const CountryState = props =>{
const dispatch = useDispatch()
const {value, handleChange} = props;
const { countryStateDetails } = useSelector(selectCountryStateDetails);
const [selectValue, setSelectValue] = useState(value !== undefined ? value : 'country');
const {countrydata} = countryStateDetails;

// console.log('marketPlace ', data);

useEffect(()=>{
    if(!countrydata){
        dispatch(actions.getCountryState());
    }
}, [countrydata, dispatch])

const handleSelectChange = (e) =>{
    if(e.target.value === 'country'){
        return null;
    }
    // console.log(e.target.value)
    setSelectValue(e.target.value);
    handleChange(e)
}

return (
    <>
    <Select size='small' variant='outlined' onChange={handleSelectChange} value={selectValue}
         sx={{...props.style, fontSize:11,py:0.4, fontFamily:'Inter', color:'text.secondary', fontWeight:400}}
    >
        <MenuItem value='country'>Select Country</MenuItem>
        { countrydata && countrydata.map((item, index) =>{
            return <MenuItem key={`country${index}`} value={item.name}>{item.name}</MenuItem>
        })
        }
    </Select>
    </>
)}

export default CountryState;