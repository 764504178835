import * as constants from '../constants/addmarketloc.constants';

export const initialState = {
  
  addMarketLocDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    mktlocData: null,
    message: null,
  },
  isSessionExpired:false
};

export const addMarketLocReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.ADD_MARKET_LOC:
      return {
        ...state,
        addMarketLocDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          mktlocData: null,
          errorMessage: null
        },
      };
    case constants.ADD_MARKET_LOC_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        addMarketLocDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          errorMessage: null,
          mktlocData: action.response
        }
      };
    case constants.ADD_MARKET_LOC_FAILED:
      return {
        ...state,
        addMarketLocDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          errorMessage: payload,
          mktlocData: null
        }
      };
      
    default:
      return state;
  }
};
