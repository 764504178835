import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  FormControl,
  FormGroup,
  Button,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";

import UseReport from "./useReport";
// tableau changes
const RightPanel = (props) => {
  const {
    getFilterdData,
    marketFilterData,
    filteringData,
    categoringData,
    brandFilterData,
    locationFilterData,
    currentLocation,
  } = props;
  const { filterdProducts, data, marketData } = UseReport();
  const [tableauUrl, setTableauUrl] = useState(
    "https://public.tableau.com/views/pricecomparison_16859631940200/Dashboard2?:language=en-US&:display_count=n&:origin=viz_share_link"
  );
  // console.log("currentLocationl ==>", currentLocation);
  // console.log("filtered datas", filteringData, brandFilterData, categoringData, currentLocation);
  useEffect(() => {
    if (!isEmpty(filterdProducts)) {
      getFilterdData(filterdProducts, data, marketData);
    }
  }, [filterdProducts, data, marketData, getFilterdData]);

  // console.log(categoringData,filteringData,brandFilterData,locationFilterData);

  useEffect(() => {
    const applyLocationFilter = () => {
      const activeSheet = window.tableau.VizManager.getVizs()[0]
        ?.getWorkbook()
        ?.getActiveSheet();
      const filterField = "Location";
      let a = currentLocation;
const words = a.split(' ');
for (let i = 0; i < words.length; i++) {
  words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
}
a = words.join(' ');
const values = a
// console.log(values)

      if (activeSheet && values && values.length > 0) {
        activeSheet.applyFilterAsync(
          filterField,
          values,
          window.tableau.FilterUpdateType.REPLACE
        );
      } else if (activeSheet && activeSheet.clearFilterAsync) {
        activeSheet.clearFilterAsync(filterField);
      }
    };
    const applyCategoryFilter = () => {
      const activeSheet = window.tableau.VizManager.getVizs()[0]
        ?.getWorkbook()
        ?.getActiveSheet();
      const filterField = "Category";
      const values = categoringData;

      if (activeSheet && values && values.length > 0) {
        activeSheet.applyFilterAsync(
          filterField,
          values,
          window.tableau.FilterUpdateType.REPLACE
        );
      } else if (activeSheet && activeSheet.clearFilterAsync) {
        activeSheet.clearFilterAsync(filterField);
      }
    };

    const applyBrandFilter = () => {
      const activeSheet = window.tableau.VizManager.getVizs()[0]
        ?.getWorkbook()
        ?.getActiveSheet();
      const filterField = "Brand";
      const values = brandFilterData;

      if (activeSheet && values && values.length > 0) {
        activeSheet.applyFilterAsync(
          filterField,
          values,
          window.tableau.FilterUpdateType.REPLACE
        );
      } else if (activeSheet && activeSheet.clearFilterAsync) {
        activeSheet.clearFilterAsync(filterField);
      }
    };

    const applyDepartmentFilter = () => {
      const activeSheet = window.tableau.VizManager.getVizs()[0]
        ?.getWorkbook()
        ?.getActiveSheet();
      const filterField = "Department";
      const values = filteringData;

      if (activeSheet && values && values.length > 0) {
        activeSheet.applyFilterAsync(
          filterField,
          values,
          window.tableau.FilterUpdateType.REPLACE
        );
      } else if (activeSheet && activeSheet.clearFilterAsync) {
        activeSheet.clearFilterAsync(filterField);
      }
    };

    applyCategoryFilter();
    applyBrandFilter();
    applyDepartmentFilter();
    applyLocationFilter();
  }, [categoringData, brandFilterData, filteringData, currentLocation]);

  useEffect(() => {
    const url = tableauUrl;
    const options = {
      hideTabs: true,
      onFirstInteractive: function () {
        const workbook = viz.getWorkbook();
        const activeSheet = workbook.getActiveSheet();
        // Access workbook and activeSheet properties here
      },
    };

    let viz;

    const initTableau = () => {
      if (window.tableau.Viz) {
        const vizContainer = document.getElementById("tableauViz");
        viz = new window.tableau.Viz(vizContainer, url, options);
      } else {
        setTimeout(initTableau, 100);
      }
    };

    initTableau();

    return () => {
      if (viz) {
        viz.dispose();
      }
    };
  }, [tableauUrl]);

  return (
    <Grid item xs={10} sx={{ padding: 2 }}>
      <Grid
        item
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <FormGroup sx={{ flexDirection: "row", alignItems: "center" }}>
            <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
              <Button
                variant="text"
                sx={{
                  fontSize: 11,
                  textTransform: "none",
                  borderColor: "#1C3B6E",
                  color: "text.secondary",
                  justifyContent: "flex-start",
                }}
              >
                Price Comparison
              </Button>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body2"
                sx={{ ml: "5px", marginTop: "-14px" }}
              >
                |
              </Typography>
              <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
                <Button
                  variant="text"
                  sx={{
                    fontSize: 11,
                    textTransform: "none",
                    borderColor: "#1C3B6E",
                    color: "text.secondary",
                    justifyContent: "flex-start",
                  }}
                >
                  Market Rate
                </Button>
              </FormControl>
              <Typography
                variant="body2"
                sx={{ ml: "5px", marginTop: "-14px" }}
              >
                |
              </Typography>
            </Box>
            <FormControl sx={{ m: 1, mr: 0, mt: 0, mb: 2 }}>
              <Button
                variant="text"
                sx={{
                  fontSize: 11,
                  textTransform: "none",
                  borderColor: "#1C3B6E",
                  color: "text.secondary",
                  justifyContent: "flex-start",
                }}
              >
                Price Per Unit
              </Button>
            </FormControl>
            <Typography variant="body2" sx={{ ml: "5px", marginTop: "-14px" }}>
              {" "}
              |{" "}
            </Typography>
            <FormControl
              sx={{
                m: 1,
                mr: 0,
                mt: 0,
                mb: 2,
                minWidth: "35px",
                width: "50px",
              }}
            >
              <Button
                variant="text"
                sx={{
                  minWidth: 40,
                  fontSize: 11,
                  textTransform: "none",
                  borderColor: "#1C3B6E",
                  color: "text.secondary",
                  justifyContent: "flex-start",
                }}
              >
                CPI
              </Button>
            </FormControl>
          </FormGroup>
        </Box>

        <Box
          id="tableauViz"
          sx={{
            width: "100%",
            height: "900px",
            overflowY: "auto",
            overflowX: "auto",
          }}
        ></Box>
      </Grid>
    </Grid>
  );
};

export default RightPanel;
