import * as constants from '../constants/deleteallproduct.constants';

export const handleDeleteAllProduct = (payload) => ({
  type: constants.DELETE_ALL_PRODUCT,
  payload
});

export const deleteAllProductSuccessful = (response) => ({
  type: constants.DELETE_ALL_PRODUCT_SUCCESSFUL,
  response
});

export const deleteAllProductFailed = (payload) => ({
  type: constants.DELETE_ALL_PRODUCT_FAILED,
  payload
});
export const deleteAllProductReset = (payload) => ({
  type: constants.DELETE_ALL_PRODUCT_RESET,
  payload
});
