import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/addsingleproduct.actions';
import * as constants from '../constants/addsingleproduct.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}

// export function* resetProduct(){}


export function* handleSingleProduct(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  console.log(payload);
  try {
    const response = yield call(NetworkApi.post, paths.productURL, payload, apiConfig);
    console.log('error ', response)
    if (response && !response.detail && response.status === 'success') {
      yield put(actions.singleProductSuccessful(response));
    } else {
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.singleProductFailed(message));
    }

  } catch (error) {
    // console.log('catch error ', error)
    const message = error?.response?.statusText ? (
      error.response?.statusText
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.singleProductFailed(message));
  }
}


export function* postSingleProduct() {
  yield takeLatest(constants.VALIDATE_SINGLE_PRODUCT, handleSingleProduct);
}
