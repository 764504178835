import * as constants from '../constants/addlocations.constants';

export const handleAddLocation = (payload) => ({
  type: constants.ADD_LOCATION,
  payload
});

export const addLocationSuccessful = (response) => ({
  type: constants.ADD_LOCATION_SUCCESSFUL,
  response
});

export const addLocationFailed = (payload) => ({
  type: constants.ADD_LOCATION_FAILED,
  payload
});

export const addLocationReset = (payload) => ({
  type: constants.ADD_LOCATION_RESET,
  payload
});
