import React, {useState, useEffect} from 'react';
import { Container, Grid, Typography,Box, Stack, Paper, IconButton,} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {styled} from '@mui/material/styles'
import { isEmpty } from 'lodash';

import CompModal from '../../components/Modal';
import { getItem } from '../../utils/storage';
import { isUndefined, isNull } from 'lodash';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';


const BrandProductPop = props =>{

    const {productInformation} = props;
    const {isVisible, brand, productInfo} = productInformation;
    const [value, setValue] = useState('1');
    const [currency, setCurrency] = useState(
        {'currency_symbol':'$'}
    );

    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#232D3B',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center',
        // color: '#FFFFFF',
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        boxShadow:'none'
      }));

    const Tabe = styled(Tab)(({theme}) => {
        return {
            '.MuiTab-root':{
                '& .Mui-selected': {
                    backgroundColor: '#559EFF',
                    color:'#0E2B5A'
                }
            }
        }
    })

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleBrandClose = () => {
        props.handleClose();
    }

    useEffect(() =>{
        const userCurrency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof(getItem('currency')) === 'string' ? JSON.parse(getItem('currency')) : getItem('currency') : JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}]
        setCurrency(userCurrency[0]);
    }, [])

    useEffect(() =>{
        const indx = productInfo.filteredMarket.findIndex(item => item === brand);
        setValue(Number(indx+1).toString())
    }, [brand]) // eslint-disable-line

    // console.log(productInfo);
    return (
    <>
    <CompModal {...props} modalOpen = {isVisible} width={800}>
        <Container style={{width:750, paddingLeft:'0px',paddingRight:'0px'}}>
            <Grid item sx={{float:'right', position:'absolute', top:3, right:3}}>
                <IconButton onClick={handleBrandClose}  ><CloseRoundedIcon /> </IconButton>
            </Grid>
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                <img src={productInfo.prd_medium_image} alt={productInfo?.prd_name} style={{width:'100%', maxWidth:55, maxHeight:70, height:'auto', left:0, top:0, transform:"none"}} />
                <Typography variant='h5' component={'h5'} sx={{ml:1, fontSize:16, width:350, textAlign:'left'}}>{productInfo?.prd_name}</Typography>
                <Typography variant='h5' component={'h5'} sx={{ml:1, fontSize:16, width:100}}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency :'$' } {productInfo?.activeLocation[0]?.prd_sales_price.toFixed(2)}</Typography>
                <Typography variant='body2' sx={{fontSize:16, width:180}}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency :'$' } {productInfo?.activeLocation[0]?.prd_norm_price.toFixed(2)}/Unit</Typography>
            </Grid>
            <Grid>
            <TabContext value={value}>
            <Box sx={{ borderBottom: 2, borderColor: '#515151' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {productInfo.filteredMarket.map((item, index) => {
                       return <Tabe key={`tab_${index}`} 
                            sx={{color:'text.secondary', border:'none'}} 
                            label={item} value={`${Number(index+1)}`} 
                        />
                    })}
                </TabList> 
            </Box>
            {productInfo.filteredMarket.map((compet, index) => {
                // console.log('compet ', compet)
                return <TabPanel key={`competitor_${index}`} value={`${Number(index+1)}`} sx={{px:0, py:1, maxHeight:200}} >
                    <Stack spacing={0} xs={10} className='custom-scrollbar' sx={{marginTop:0, maxHeight:200, overflowY:'auto', overflowX:'hidden'}}>
                    {productInfo.competitors.map(item => 
                        {
                            const matchRate = item?.comp_prd_score ? `${item?.comp_prd_score} %` : 'N/A'
                            return String(item.market_place_name).toLowerCase() === String(compet).toLowerCase()  && Number(item?.comp_prd_sales_price) > 0 &&
                            <Item key= {`competitor${Math.random()}`} sx={{}}>
                                <img src={item.comp_prd_medium_image} alt={item?.comp_prd_name} style={{width:'100%', maxWidth:55, maxHeight:70, height:'auto', left:0, top:0, transform:"none"}}/>
                                <Typography variant='h5' component={'h5'} sx={{ml:1,fontSize:16, width:350, textAlign:'left'}}>{item?.comp_prd_name}</Typography>
                                <Typography variant='h5' component={'h5'} sx={{ml:1, fontSize:16, width:100, textAlign:'left'}}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency :'$'  } {item?.comp_prd_sales_price.toFixed(2)}</Typography>
                                <Typography variant='body2' sx={{fontSize:16, width:100}}>{!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency :'$'  } {item?.comp_prd_norm_price.toFixed(2)}/Unit</Typography>
                                <Typography variant='body2' sx={{fontSize:16, width:80}}>{matchRate}</Typography>
                            </Item>
                    })
                    }
                </Stack>
                </TabPanel>
            })}
        </TabContext>
        </Grid>

        </Container>


    </CompModal>
    
    </>
    )
}

export default BrandProductPop;