import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const CompModal = props => {
    const { children, modalOpen, handleClose } = props;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: props.width ? props.width : 550,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            keepMounted
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style}>
            {children}
            </Box>
        </Modal>
    )


}


export default CompModal;