import * as constants from '../constants/marketplace.constants';

export const initialState = {
  
  marketPlaceDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  }
};

export const marketPlaceReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.GET_MARKET_PLACE:
      return {
        ...state,
        marketPlaceDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          errorMessage: null
        }
      };
    case constants.MARKET_PLACE_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        marketPlaceDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          data: action.response
        }
      };
    case constants.MARKET_PLACE_FAILED:
      return {
        ...state,
        marketPlaceDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
        }
      };
      case constants.MARKET_PLACE_RESET:
      return {
        ...state,
        marketPlaceDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          message: null,
          data: null
        }
      };
    default:
      return state;
  }
};
