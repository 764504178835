import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { Button, Typography } from '@mui/material';
import { RefreshRounded } from "@mui/icons-material";

import selectRefreshProductDetails from '../../../redux/selectors/refreshProduct.selector'
import * as actions from '../../../redux/actions/refreshProduct.actions' 
import SelectMarket from './SelectMarket';
import { getItem } from '../../../utils/storage';

const RefreshProduct = props => {
const {id, handleRefresh, competitorInformation} = props;
const dispatch = useDispatch()
const {refreshProductDetails} = useSelector(selectRefreshProductDetails)
const {isSuccessful, isFailure, errorMessage} = refreshProductDetails;
const [marketIds, setMarketIds] = useState([])
const [locIds, setLocIds] = useState([])
const [maketLabels, setMaketLabel] = useState([])
const [clicked, setClicked] = useState(false)
const [isSelected, setIsSelected] = useState(false)
const [isError, setIsError] = useState(false)
const [selectedMarket,setSelectedMarket] = useState('selectmarket')
const currency = !isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof(getItem('currency')) === 'string' ? JSON.parse(getItem('currency')) : getItem('currency') : JSON.parse(getItem('currency')) : [{'currency_symbol':'$'}]

//  console.log('refresh ', isSuccessful)

useEffect(() =>{
 if(isSuccessful){
    setTimeout(() => {
        setClicked(false)
        setIsSelected(false)
        actions.getRefreshReload();
        handleRefresh();
    }, [1000])
 }
})

useEffect(() => {
    if(!isEmpty(competitorInformation)){
        const marketIdsa = [...new Set(competitorInformation.map(competitor => competitor.market_place_id).flat())]
        const marketLabel = [...new Set(competitorInformation.map(competitor => competitor.market_place_name).flat())]
        // console.log('marketLabel ', marketLabel);
        const locationIds = [...new Set(competitorInformation.map(competitor => competitor.loc.loc_id).flat())]
        // console.log(competitorInformation,' locationIds ', locationIds)
        setMarketIds(marketIdsa);
        setMaketLabel(marketLabel);
        console.log('locationIds ', locationIds)
        setLocIds(locationIds);
    }
}, [competitorInformation]) //

    const handleMarketPlace = (value) => {
        setIsError(false);
        setSelectedMarket(value)
        if(!value) {};
        const mIndex = maketLabels.findIndex(item => item === value)
        setIsSelected(true);
        //  console.log({loc_id:locIds[mIndex],prd_id:id, cuttoff_score:60, user_id:currency[0]?.user, market_place_ids:marketIds[mIndex], })
       console.log('locIds[mIndex] ', locIds[mIndex], mIndex, locIds, value);
        dispatch(actions.getRefreshProduct({prd_id:id, cuttoff_score:60, loc_id:locIds[mIndex] !== undefined ? locIds[mIndex] : locIds[0] , user_id:currency[0]?.user, market_place_ids:marketIds[mIndex] }))
        handleClose()
        
        
    }

    const handleClose = () =>{
        setClicked(false);
    }
    const handleRefreshHandler = () =>{
        setClicked(true);
        setSelectedMarket('selectmarket');
    }
    return (
        <>
        { clicked &&
            <SelectMarket 
                // handleSelectedMarket = {handleSelectedMarket}
                handleClose = {handleClose}
                selectedMarket={selectedMarket} 
                marketData={maketLabels} 
                handleSelectMarket={handleMarketPlace}
            />
        }
        {isFailure && errorMessage && <Typography variant='body2' sx={{width:150, ml:1}}>{errorMessage}</ Typography>}
        {isError && <Typography variant='body2' sx={{width:150, ml:1}}>Select MarketPlace for refresh product.</ Typography>}
        { !isSelected ?
            <Button variant='outlined' 
            sx={{width:'150px', fontSize:11, ml:1, borderColor:'text.primary', color:'text.primary', textTransform:'none'}} 
            onClick={handleRefreshHandler}
            startIcon={<RefreshRounded sx={{color:'text.primary'}} />}> Refresh</Button>
            :
            <Typography variant='body2' sx={{width:150, ml:1}}>Refreshing market place....</Typography>
        }
        </>
    )


} 

export default RefreshProduct