import React, {useState,  useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
// import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, MenuItem, Select, Typography,  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { visuallyHidden } from '@mui/utils';
import AlertDialogSlide from '../Dialog';
import MessageDialogSlide from '../Dialog/message'
import EditProduct from '../editProduct';
import BrandProductPop from '../../layout/MyProduct/BrandProductPop';
import ModifyPop from '../../layout/MyProduct/ProductDetails/ModifyPop';


import {handleDeleteProduct, deleteProductFailed} from '../../redux/actions/deleteproduct.actions';
import deleteProductSelector from '../../redux/selectors/deleteproduct.selector'
import useActions from '../../redux/use-actions';

import { getItem } from '../../utils/storage';
import { isUndefined, isNull, isEmpty } from 'lodash';
import Notification from '../notification';


const Index = props => {
  const {setRowsPerPage, rowsPerPage, page, setPage, data, userRole, getProductList, productData, marketPlaceLabel} = props;
  
  const [productList, setProductList] = useState([])
  const [deletePop, setDeletePop] = useState({
    isVisible:false,
    productId:null
  })
  const [modifyPopOption, setModifyPopOption] = useState(false)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [deleteMessagePop, setDeleteMessagePop] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')
  const [brandProduct, setBrandProduct] = useState({
    isVisible: false,
    productInfo:null,
    brand: null
  })
  const [editedProduct, setEditedProduct] = useState({
    isVisible:false, 
    productInformation: null
  })
  let [currency, ] = useState(!isNull(getItem('currency')) && !isUndefined(getItem('currency')) ? getItem('currency').length !== undefined ? typeof(getItem('currency')) === 'string' ? JSON.parse(getItem('currency'))[0] : getItem('currency')[0] : JSON.parse(getItem('currency')) : {'currency_symbol':'$'});
  const DELETE_MESSAGE = 'Deleting Product, Please wait...';
  const { deleteProductDetails } = useSelector(deleteProductSelector);
  const { isSuccessful, isFailure, errorMessage} = deleteProductDetails;
  const actions = useActions({ handleDeleteProduct, deleteProductFailed });
  const dispatch = useDispatch();
  
  const [productColumn, setProductColumn] = useState(
    [
      { id: 'prd_mod_name', 
        label: 'Name', 
        minWidth: 250,
        isEnable:true,
        align:'left', 
      },
      {id:'actionsData',
        label:'Action',
        minWidth:100,
        isEnable:false,
        align:'center',
      },
      { 
        id: 'filtered_sales_price', 
        label: 'Price', 
        minWidth: 90, 
        maxWidth: 90,
        isEnable:true, 
        align:'center',
          format: (value) => `${!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$'} ${value.toFixed(2)}`
      },
      {
        id: 'prd_brand',
        label: 'Brand',
        minWidth: 90,
        isEnable:true,
        align: 'center',
      },
      {
        id: 'categoryname',
        label: 'Category',
        minWidth: 80,
        isEnable:true,
        textTransform:'capitalize',
        align: 'center',
        format: (value) => !isNull(value) && !isUndefined(value) ? value : 'N/A'
      },
      {
        id: 'deptname',
        label: 'Department',
        minWidth: 80,
        isEnable:true,
        style:{textTransform:'uppercase'},
        textTransform:'uppercase',
        align: 'center',
        format: (value) => !isNull(value) && !isUndefined(value) ? value : 'N/A'
      },
      {
        id: 'prd_variants',
        label: 'Variants',
        minWidth: 80,
        isEnable:true,
        align: 'center',
        format: (value) => !isNull(value) && !isUndefined(value) && value.toLowerCase() !== 'nan' ? value : 'N/A'
      },
      {
        id: 'prd_no_of_pieces',
        label: 'Quantity',
        minWidth: 50,
        isEnable:true,
        align: 'center',
        format: (value) => value.toFixed(0),
      },
      {
        id: 'filtered_norm_price',
        label: 'PPU',
        minWidth: 80,
        isEnable:true,
        align: 'center',
        format: (value) => `${!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency :'$'} ${value.toFixed(2)}`
      },
      {
        id: 'prd_size',
        label: 'Size',
        minWidth: 80,
        isEnable:true,
        align: 'center',
        format: (value) => value.toFixed(2),
      },
      {
        id: 'notifications',
        label: 'Notification',
        isEnable:false,
        minWidth: 100,
        align: 'center',
      }
  ])

  const handleProduct = (item) => {
    setEditedProduct({
      isVisible: true,
      productInformation: item
    })
  }
  const handleBrandClose =() =>{
    setBrandProduct({
      isVisible: false,
      productInfo:null,
      brand: null
    })
  }
  const handleBrand = (item, pBrand) =>{
    setBrandProduct({
      isVisible: true,
      productInfo:item,
      brand: pBrand
    })
  }

  useEffect(() => {
    const createData = (args) => {
        const returnItems = args.map((item)=>{
            item.notifications = <Notification type='select' item={item} />
            item.actionsData = generateActionData(item);
            item.prd_mod_name = typeof(item.prd_name) === 'string' ? 
              item.prd_name
              : 
              item.prd_name;
          return item;
        })
      setProductList(returnItems)
    }
  
    createData(productData)
    let pageStr = ';'
    let pageLength = 0;
    if(productData.length<rowsPerPage && productData.length<10){
      pageLength = 0;
    }
    if(!isNull(data) && !isNull(data.next)){
      pageStr = data?.next.split('?')[1].split('&');
      const ss = pageStr.findIndex(item => {
        return item.search('page=') !== -1;
      })
      const pageSize = pageStr.findIndex(item => {
        return item.search('page_size=') !== -1;
      })
      setRowsPerPage(pageStr[pageSize].split('=')[1])
      pageLength = pageStr[ss].split('=')[1]-2
      setPage(pageLength)

    } else if(!isNull(data) && !isNull(data.previous)){
      pageStr = data?.previous.split('?')[1].split('&');
      const ss = pageStr.findIndex(item => {
        return item.search('page=') !== -1;
      })
      // console.log('ss ', ss, pageStr[ss])
      
      pageLength = ss !== -1 ? pageStr[ss].split('=')[1] : 1
      setPage(Number(pageLength))
      const pageSize = pageStr.findIndex(item => {
        return item.search('page_size=') !== -1;
      })
      setRowsPerPage(pageStr[pageSize].split('=')[1])
    } else if(!isNull(data) && isNull(data.previous) && isNull(data.next) && getItem('pageList') !== null){
      console.log(getItem('pageList'))
      const tempPageList = JSON.parse(getItem('pageList')).split('&');
      tempPageList.map( item => {
        if(item.indexOf('page=') > -1){
          setPage(0);
        } else if(item.indexOf('page_size=') > -1){
          setRowsPerPage(item.split("=")[1]);
        }
        return item;
      })
    } 
    else {
      setPage(0)
    }
  }, [productData]); // eslint-disable-line
    useEffect(() =>{
     if(isFailure && errorMessage !== '' && deleteMessagePop){
        setDeleteMessage('There are some error. Try again to delete...');
     } else if(isSuccessful){
        setDeleteMessage('Product deleted successfully...');
        setTimeout(() =>{
          setDeleteMessagePop(false)
          dispatch(actions.deleteProductFailed())
          getProductList();
        }, 500)
     }
    }, [isSuccessful, isFailure, errorMessage, deleteMessagePop]) // eslint-disable-line

    const addDifference = (pValue, pRow, pColumnId) => {
      const pSalesValue = pRow['filtered_sales_price'];
      const brandName = pColumnId.split('competitorMaxPrice')[1]
      const compSalesValue = Number(pValue.split(" ")[1]);
      const prdSalesValue = Number(pSalesValue);
      if(compSalesValue < prdSalesValue){
        const diff = Number(prdSalesValue - compSalesValue).toFixed(2);
        // console.log(diff, (prdSalesValue - compSalesValue))
        pValue = `${pValue} <br /><span style="font-Size:9px"> (< ${diff})</span>`
      } else if(prdSalesValue < compSalesValue){
        const diff = Number(compSalesValue - prdSalesValue).toFixed(2);
        pValue = `${pValue} <br /><span style="font-Size:9px"> (> ${diff})</span>`
      }
      // pValue = <Button onClick={e => handleBrand(pRow)}><span dangerouslySetInnerHTML={{__html: pValue}} /></Button>
      pValue = <Button sx={{color:'inherit', padding:0}} onClick={e => handleBrand(pRow, brandName)}><span dangerouslySetInnerHTML={{__html: pValue}} /></Button>
      return pValue
    }

    useEffect(() => {
      const tempProductColumn = [...productColumn];
      marketPlaceLabel.map((item, index) =>{
        const obj = {
          id: `competitorMaxPrice${item}`,
          label: `${item}`,
          minWidth: 100,
          isEnable:true,
          align: 'center',
          format: (value) => value ? `${!isEmpty(currency) ? currency.currency_symbol ? currency.currency_symbol : currency.currency : '$' } ${value.toFixed(2)}` : 'N/A'
        }
        const searchMarketColumn = tempProductColumn.find(productItem => {return productItem.id === obj.id})
        if(!searchMarketColumn) {
          tempProductColumn.splice(3+index, 0, obj)
        }
        return obj;
      })
      setProductColumn(tempProductColumn);
      
    }, [marketPlaceLabel]) //eslint-disable-line
    
    const handleModifySuccess = () =>{
      setEditedProduct({
        isVisible: false,
        productInformation: null
      })
      // setModifyPopOption(false);
    }
    const handleModifyFailed = () =>{
      setModifyPopOption(false);
    }
    const showModifiPop = (item) =>{
      window.open(item, '_blank');
    }
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    const handleSuccess = () =>{
      const pop = {...deletePop};
      pop.isVisible = false;
      setDeletePop(pop);
      setDeleteMessage(DELETE_MESSAGE);
      actions.handleDeleteProduct(pop.productId)
      setDeleteMessagePop(true);
    }
    const handleFailed = () =>{
      setDeletePop({isVisible:false, productId:null});
    }
    const showDeletePop = (pid) =>{
      setDeletePop({isVisible:true, productId:pid});
    }
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    const generateActionData = (items) =>{
      const actionInformation = 
      (<Grid item xs={12} sx={{display:'flex', justifyContent:'flex-start'}}>
        <IconButton aria-label="deleteicon" size="small" onClick={() => showDeletePop(items.prd_id)}>
          <CloseIcon id='deleteicon' sx={{fontSize:14, color:'text.secondary'}}/>
        </IconButton>
        <IconButton aria-label="editicon" size="small" onClick={() =>handleProduct(items)}>
          <EditIcon id='editicon' sx={{ fontSize:14, color:'text.secondary'}}/>
        </IconButton>
        {items.prd_url && items.prd_url !== '' && 
        <IconButton aria-label="linkicon" size="small" onClick={() =>showModifiPop(items.prd_url)}>
        <LinkIcon id='linkicon' sx={{fontSize:14, color:'text.secondary'}} />
      </IconButton>
        }
        
      </Grid>
      )
      return actionInformation
    }
    function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function EnhancedTableHead(props) {
      const { order, orderBy, onRequestSort } = props;
      const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
      };
      
      return (
        <TableHead>
          <TableRow>
            {productColumn.map((column) => (
                <TableCell
                key={column.id}
                align={column.align}
                sx={{ minWidth: column.minWidth, border:'none', textTransform:'capitalize', color:'#e3e3e3', fontSize:13, padding:'5px', background:'#314055' }}
                sortDirection={orderBy === column.id ? order : false}
                >
                  {column.isEnable ?
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  : <>
                    {column.label}
                    </>
                  }
                  
                {/* {column.label} */}
                </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }
    
    EnhancedTableHead.propTypes = {
      onRequestSort: PropTypes.func.isRequired,
      order: PropTypes.oneOf(['asc', 'desc']).isRequired,
      orderBy: PropTypes.string.isRequired,
    };
    
    const getURLLink = (pName) =>{
      
        const filtered = productData.filter(item => {
         
          return String(item.prd_mod_name).toLowerCase() === String(pName).toLowerCase()} 
        )
         return !isEmpty(filtered) && <div style={{display:'flex', alignItems:' center'}}>
          <Link style={{ textDecoration:'none' }} 
            to= {`/myproducts/${filtered[0].prd_id}`} >
               <Typography variant='body2' sx={{color:'text.secondary'}}>{filtered[0].prd_name}</Typography>
          </Link>
          {Number(userRole) === 2 && filtered[0].prd_qa_approved && <CheckCircleIcon sx={{ml:1, fontSize:20, color:'#1d9e42'}} />}</div>
      }
      const handlePrevPage = () =>{
        const splitStr = data?.previous.split('?')
        if(splitStr[1].indexOf('page_size') === -1){
          getProductList(`${splitStr[1]}&page_size=${rowsPerPage}`)
        } else {
          getProductList(`${splitStr[1]}`)
        }
      }
      const handleNextPage = () =>{
        let splitStr = data?.next.split('?')
        if(splitStr[1].indexOf('page_size') === -1){
          getProductList(`${splitStr[1]}&page_size=${rowsPerPage}`)
        } else {
          getProductList(`${splitStr[1]}`)
        }
      }
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0)
        getProductList(`page=1&page_size=${event.target.value}`)
      };
      const checkLabelValue = (label, value, prdSalesPrice) =>{
        if(label === 'filtered_sales_price'){
          if(value === 0) return 'transparent';
          return '#71AEFF';
        } else if(label.indexOf('competitorMaxPrice') !== -1){
          const percent = 20;
          // console.log('prdSalesPrice ', prdSalesPrice, value)
          if(value < prdSalesPrice && prdSalesPrice > 0 && value > 0  ){
            const higherDifference = ((value)/prdSalesPrice)*10
            // console.log('higherDifference ', higherDifference)
            const colorVal = higherDifference < percent ? '#fe8383' : '#ff6464';
            return colorVal
          } else if(value === prdSalesPrice && prdSalesPrice>0){
            return '#FFA132'   //'#DAC934';
          } else if (value > prdSalesPrice && prdSalesPrice > 0 && value > 0  ){
            const lowerDifference = ((prdSalesPrice)/value) * 10
            // console.log('lowerDifference ', lowerDifference)
            const colorVal = lowerDifference < percent ? '#78d587' : '#389c60';
            return colorVal;
          }
        }
        return 'transparent'
      }

    return (
      <Grid item xs={12}>
        {deleteMessagePop && <MessageDialogSlide>
            {deleteMessage}
          </MessageDialogSlide>  }
        {deletePop.isVisible && <AlertDialogSlide isAlert={false} handleSuccess={handleSuccess} handleFailed={handleFailed}/>}
        {brandProduct.isVisible && <BrandProductPop handleClose={handleBrandClose} productInformation={brandProduct}  />}
        {editedProduct.isVisible  && 
          <EditProduct 
            productInformation={editedProduct.productInformation} 
            handleClose={handleModifySuccess} modalOpen={editedProduct.isVisible}
          />}
        {modifyPopOption && <ModifyPop handleSuccess={handleModifySuccess} handleFailed={handleModifyFailed}/>}
        <TableContainer className='custom-scrollbar' sx={{ width:'-webkit-fill-available', maxHeight: 443, height:'auto', overflowX:'scroll' }}>
            <Table stickyHeader aria-label="sticky table" sx={{backgroundColor:'background.paper'}}>
            
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
            {/* { console.log('conss ', productList.length, productData.length) } */}
            {productList.length === productData.length && stableSort(productList, getComparator(order, orderBy))
                // {productList
                .slice(0, rowsPerPage)
                .map((row) => {
                    return (
                    <TableRow hover role="productTable" tabIndex={-1} key={`td_${row.prd_id}`}>
                        {productColumn.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell sx={{backgroundColor: checkLabelValue(column.id, value, row.filtered_sales_price),
                            fontSize:column.id === 'filtered_sales_price' ? 12 : 11, fontFamily:'Inter', textTransform:'capitalize', border:'none', padding:'6px', 
                            color: (column.id === 'filtered_sales_price' || column.id.indexOf('competitorMaxPrice') !== -1) && value > 0 ? '#272727' : 'text.secondary', fontWeight:400}} 
                            key={column.id} 
                            align={column.align}>
                              {/* {console.log('column.id ', column.id, value, typeof(value))} */}
                            {column.format && typeof(value) === 'number'
                              ? value > 0 ? column.id.indexOf('competitorMaxPrice') !== -1
                              ? addDifference(column.format(value), row, column.id)
                              : column.format(value) : 'N/A'
                              : value ? column.id ==='prd_mod_name' 
                              ? getURLLink(value)
                              : value : 'N/A' 
                            }
                          </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                })}
            </TableBody>
            </Table>
        </TableContainer>

        {
         // productList.length>5 && 
        
        <Grid item xs={12} sx={{mt:1, width:'-webkit-fill-available'}}  >
          <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Grid item xs={12} md={4} lg={4}>
                <Select size='small' value={rowsPerPage} onChange={handleChangeRowsPerPage} variant='outlined' 
                  sx={{borderColor:'#E4E3E3', fontSize:11, textTransform:'none'}}>
                <MenuItem style={{fontSize:11}} value={5}>5</MenuItem>
                  <MenuItem style={{fontSize:11}} value={10}>10</MenuItem>
                  <MenuItem style={{fontSize:11}} value={25}>25</MenuItem> 
                  <MenuItem style={{fontSize:11}} value={100}>100</MenuItem> 
                </Select>
              </Grid>
              <Grid item  xs={12} md={8} lg={8} sx={{display:'flex', alignItems:'center'}}>
                <Button disabled={data?.previous === null} onClick={handlePrevPage} variant='outlined' 
                  sx={{borderColor:'text.primary', fontSize:12, textColor:'text.primary', textTransform:'none'}}>Previous</Button>
                <Typography variant='body2' sx={{fontSize:12, mx:1}}>Page {page+1} of {Math.ceil(data?.count/rowsPerPage)}</Typography>
                <Button variant='outlined' disabled={data?.next === null} onClick={handleNextPage} 
                  sx={{borderColor:'text.primary', fontSize:12, color:'text.primary', textTransform:'none'}}>Next</Button>
              </Grid>
            </Grid>
        </Grid>
        }
      </Grid>
    )
}
export default Index;