import React,{ useState, useEffect } from 'react';
import {Grid, Box, List, Accordion, AccordionSummary, FormControlLabel, Typography, AccordionDetails, Radio, RadioGroup } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import CheckIcon from '@mui/icons-material/Check';
import { styled } from "@mui/material/styles";
import { isEmpty } from 'lodash'
// import SearchBox from '../../../components/searchBox/'

const LeftPanel = props => {

  const { locationData, updateProduct, selectedLoc } = props;
   const [locationFilterData, setLocationFilterData] = useState([])
   const [locationValue, setLocationValue] = useState('');
   const [isExpanded, setIsExpanded] = useState(false)
  // const {loc} = [...productData.loc];
  //  console.log('locationData ', locationData)
    useEffect(() =>{
      if(!isEmpty(locationData)){
        locationData.map(item => {
          item.locChecked = false;
          return item;
        })
         setLocationFilterData(locationData)
      }
    }, [locationData])

  const StyledAccord = styled(Accordion)(({ theme }) =>{
    return {
      
      '.MuiAccordionSummary-root.Mui-expanded':{
        minHeight:'48px !important'
      },
      '.MuiAccordionSummary-content.Mui-expanded':{
       margin:'12px 0' 
      },
      '.MuiAccordionDetails-root':{
        padding: '8px 0px'
      }
    }
  })
  const accordWidth = 150;
    const handleLocationValue = (e) => {
        setLocationValue(e.target.value);
      // const tempData = [...locationFilterData]
      // tempData.map(item => {
      //   console.log(item.loc.loc_zip, e.target.value)
      //   Number(item.loc.loc_zip) === Number(e.target.value) ? item.locChecked = true : item.locChecked = false   
      //   return item;
      // })
        // setLocationFilterData(tempData);
        // console.log(e.target.value);
        updateProduct(e.target.value);
    }
    
    const handleIsExpanded = () => {
      setIsExpanded(prevState => !prevState);
    }


    return (
        <Grid xs={2} item sx={{ paddingLeft:3, minHeight: 'calc(100vh - 70px)', maxWidth:'15% !important', paddingTop:1, backgroundColor:'background.paper'}}>
          <Box sx={{ overflow: 'auto', paddinLeft:2, paddingTop:2 }}>
              {/* <Button variant='text' 
                onClick={clearAllFilter}
               sx={{fontSize:12, textDecoration:'underline', color:'text.secondary', fontWeight:400, textTransform:'inherit'}}>Clear filters</Button> */}
                <StyledAccord expanded={isExpanded}
                  onChange={handleIsExpanded}
                disableGutters sx={{background:'transparent', boxShadow:'none', marginLeft:1, width:`${accordWidth}px`}}>
                <AccordionSummary sx={{margin:0, paddingLeft:0, paddingRight:0}}
                    expandIcon={<ExpandMoreIcon sx={{color:'text.secondary'}} />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography sx={{fontSize:12, color:'text.secondary'}}>Location</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List sx={{display:'flex', flexFlow:'column'}}>
                      <RadioGroup 
                          aria-labelledby="demo-radio-buttons-group-label"
                          value= {locationValue}
                          onChange={handleLocationValue}
                          name="radio-buttons-group">
                        {locationFilterData.map((item, index) => {
                          // console.log(Number(item.loc?.loc_zip), Number(selectedLoc), item, selectedLoc)
                          return (<FormControlLabel key={`check${index}`} value={Number(item.loc?.loc_zip)} sx={{fontSize:11, color:'text.secondary'}} 
                            control={<Radio id={String(item.loc?.loc_id)} checked={Number(item.loc?.loc_zip) === Number(selectedLoc) ? true:false} sx={{padding:'5px'}} size="small" />} 
                            label= {<Typography variant='body2' sx={{}}>{item.loc?.loc_zip}</Typography>} />)
                        })}
                        </RadioGroup>
                      {/* <RadioGroup 
                      aria-labelledby="demo-radio-buttons-group-label"
                        value= {locationValue}
                        onChange={handleLocationValue}
                        name="radio-buttons-group"
                      >
                      {locationData.map((item, index) => {
                        return (<FormControlLabel key={`loc${index}`} value={item.value} disableRipple sx={{fontSize:11, color:'text.secondary', fontFamily:'Inter'}} 
                          control={<Radio id={item.id} checked={item.checked} size='small' sx={{padding:'8px', color:'text.secondary', backgroundColor:'transparent'}} />} 
                          label= {<Typography variant='body2' sx={{}}>{item.loc_zip}</Typography>} />)
                      })}
                      </RadioGroup> */}
                    </List>
                  </AccordionDetails>
                </StyledAccord>
            </Box>
        </Grid>
    )
}

export default LeftPanel;