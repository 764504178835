import * as constants from '../constants/marketplace.constants';

export const getMarketPlace = (payload) => ({
  type: constants.GET_MARKET_PLACE,
  payload
});

export const getMarketPlaceSuccessful = (response) => ({
  type: constants.MARKET_PLACE_SUCCESSFUL,
  response
});

export const getMarketPlaceFailed = (payload) => ({
  type: constants.MARKET_PLACE_FAILED,
  payload
});

export const getMarketPlaceReset = (payload) => ({
  type: constants.MARKET_PLACE_RESET,
  payload
});
