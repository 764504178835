import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
  import Typography from '@mui/material/Typography';
  import Box from '@mui/material/Box';
import { } from './circularProgress.css';

const CircularPrgs = (props) =>{

    function CircularProgressWithLabel(props) {
        return (
          <Box sx={{ position: 'relative', display: 'inline-flex', flexFlow:'column', alignItems:'center'}} {...props.sx}>
            <CircularProgress style={{ color: props.sx.backcolor}}  size={props.size} variant="determinate" thickness={props.thickness} value={100} />
            <CircularProgress style={{ color: props.sx.color , position:'absolute', padding:0, margin:0}} thickness={props.thickness} size={props.size} variant="determinate" {...props} />
            
            <Box
              sx={{
                marginBottom:props.marginbottom,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" className='text-center'
                sx={{color:'text.secondary', opacity:0.7}}>
                {`${props.labelvalue}`}
              </Typography>
            </Box>
             <Typography variant="caption" component="div" 
                sx={{marginTop:'1em', color:'text.secondary', fontSize:11, width:'100%'}}>
                {`${props.label}`}
              </Typography>
          </Box>
        );
      }
      
return (
     <CircularProgressWithLabel value={props.value} {...props}  />
)      



}

export default CircularPrgs