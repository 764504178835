import * as React from 'react';
import {FormControl, Typography, InputLabel, InputBase,} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import { styled } from '@mui/material/styles';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const passwordSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
})


const AlertDialogSlide = props => {
    const {handleSuccess, handleFailed, isAlert} = props;
    const [open, ] = React.useState(true);

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
      'label + &': {
        marginTop: theme.spacing(2),
      },
      '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        border: '1px solid #ced4da',
        fontSize: 11,
        width: '100%',
        padding: '10px 12px',
        color: theme.palette.text.primary,
        transition: theme.transitions.create([
          'border-color',
          'background-color',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily:'Inter',
        '&:focus': {
          borderColor: theme.palette.primary.main,
        },
      },
    }));

  const handleOkay = (values) => {
    handleSuccess(values);
    //setOpen(false);
  }

  const handleClose = () => {
    //setOpen(false);
    handleFailed(false)
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle />
        <DialogContent>
            <Formik
                // enableReinitialize
                    initialValues={{password: '' }}
                    validationSchema={passwordSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form style={{}} className='loginform'>
                      {isAlert && 
                        <Typography variant='h6' component={'h6'} >Are you sure to delete?</Typography>
                      }
                     { !isAlert &&
                        <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                            <InputLabel shrink htmlFor='password' sx={{color:'text.primary'}}>
                                Password
                                </InputLabel>
                            <BootstrapInput 
                                type='password'
                                value={values.password} 
                                placeholder='Enter your password '
                                id='password'
                                onChange={e => setFieldValue('password', e.target.value)}
                            />
                            <Typography color="red" variant="subtitle">{errors.password}</Typography>
                        </FormControl> }
                        <FormControl sx={{ m: 0, mt:3, mb:2, display:'flex', justifyContent:'space-evenly', flexFlow:'row' }}>
                            <Button sx={{ width:140, backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} onClick={() => handleOkay(values)}> Delete </Button>
                            <Button sx={{width:140, backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} onClick={() => handleClose()}>Cancel</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>
        </DialogContent>
        
      </Dialog>
    </div>
  );
}
export default AlertDialogSlide