import React from 'react';
import { Container, Grid, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Divider } from '@mui/material';
import MailLockIcon from '@mui/icons-material/MailLock';



const Notification = props => {

return (
    <Container maxWidth={'xl'} className='maincontainer' sx={{padding:0, marginTop:2}}>
        <Grid container spacing={0} sx={{display:'flex', pb:2}}>
            <Grid item xs={12} sx={{ backgroundColor:'background.paper', display:'flex', flexFlow:'column', justifyContent:'center', padding:5}}>
                <Typography variant='h4' component={'h4'} sx={{fontSize:16, textAlign:'center' }}>Notifications</Typography>
                <List>
                    <ListItem sx={{my:1.5}}>
                        <ListItemAvatar>
                        <Avatar> <MailLockIcon sx={{fontSize:30}} /> </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Subject1"
                            secondary={'This is a text mail'}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem sx={{my:1.5}}>
                        <ListItemAvatar>
                        <Avatar> <MailLockIcon sx={{fontSize:30}} /> </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary="Subject1"
                            secondary={'This is a text mail'}
                        />
                    </ListItem>
                </List>



            </Grid>
        </Grid>
    </Container>
)

}

export default Notification;