import React from 'react';

const Logo = () => {

return (
    <img src={'./assets/logo.png'} alt='OttixHow' className='logo' />
)

}

export default Logo;