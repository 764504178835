import * as constants from '../constants/marketLocation.constants';

export const getMarketLocation = (payload) => ({
  type: constants.GET_MARKET_LOCATION,
  payload
});

export const marketLocationSuccessful = (response) => ({
  type: constants.MARKET_LOCATION_SUCCESSFUL,
  response
});

export const marketLocationFailed = (payload) => ({
  type: constants.MARKET_LOCATION_FAILED,
  payload
});
export const marketLocationReset = (payload) => ({
  type: constants.MARKET_LOCATION_RESET,
  payload
});
