import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { utils, writeFile } from 'xlsx';
import Button from '@mui/material/Button'

import * as xlsAction from '../../redux/actions/downloadxls.actions';
import downloadxlsSelector from '../../redux/selectors/downloadxls.selector'



const DownloadXls = props => {

const dispatch = useDispatch();
const {downloadxlsDetails} = useSelector(downloadxlsSelector)

const {data, isSuccessful} = downloadxlsDetails;

console.log('isSuccessful ', isSuccessful)

useEffect(() =>{
    if(data){
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Dates");
        writeFile(workbook, 'product.xlsx', {compression:true});
        dispatch(xlsAction.downloadXlsReset());
    }

}, [data, isSuccessful, dispatch])


const handleDownLoadXLS = ( ) => {
    dispatch(xlsAction.handleDownloadXls())
}

return (
    <>
    <Button sx={{m: 1, mt:0, mb:2, backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}} 
        onClick={handleDownLoadXLS}>Download XLS
    </Button>
    {/* <a id="download" href= "" /> */}
    </>
)

}

export default DownloadXls;