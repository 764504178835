import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/productDetails.actions';
import * as constants from '../constants/productDetails.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    // headers,
    Authorization: headers.Authorization
  }
}


export function* getProductDetails(request = {}) {

  const { payload = {} } = request;
  // console.log('getProductDetails ', payload)

  const apiConfig = getApiConfig()
  const userRole = getItems(['role'])
  try {
    const response = yield call(NetworkApi.get, 
      Number(userRole) !== 2 ? `${paths.myProductURL}${payload}` 
      : `${paths.qaProductURL}${payload}`, 
    {}, apiConfig, );
    // console.log(response);

    if (response && !response?.detail) {
        yield put(actions.productDetailsSuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.productDetailsFailed(message));
    }

  } catch (error) {
  //  console.log('dashboard ', error)
    const message = error?.response?.data?.detail ? (
        error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    console.log('message ', message)
    yield put(actions.productDetailsFailed(message));
  }
}

export function* fetchProductDetails() {
  yield takeLatest(constants.PRODUCTDETAILS_GET, getProductDetails);
}
