import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

import selectMarketPlaceDetails from '../../redux/selectors/marketplace.selector'
import * as actions from '../../redux/actions/marketplace.actions'
import AddMarketPlace from './addMarketPlace';

const MarketPlace = props =>{
const dispatch = useDispatch()
const {value, handleChange} = props;
const { marketPlaceDetails } = useSelector(selectMarketPlaceDetails);
const [selectValue, setSelectValue] = useState(value);
const [addMarketBool, setAddMarket] = useState(false)
const {data} = marketPlaceDetails;
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
      border: '1px solid #ced4da',
      fontSize: 11,
      width: '100%',
      padding: '8px 12px',
      color: theme.palette.text.primary,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily:'Inter',
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    },
  }));
useEffect(() =>{
if(value){
    setSelectValue(value);
}

}, [value]) // eslint-disable-line

useEffect(() =>{
    let marketLocalData = localStorage.getItem('marketData');
    if(marketLocalData !== null && data !== null){
        marketLocalData = JSON.parse(marketLocalData);
        const { market_place_id } = marketLocalData
        if(market_place_id !== null){
            // setSelectValue(market_place_id);
            // handleChange(market_place_id)
        }
    }
}, [data])

const handleSelectChange = (e) =>{
    if(e.target.value === 'addmarket'){
        setAddMarket(true)
        return null;
    }
    setSelectValue(e.target.value);
    handleChange(e.target.value)
}

const handleClose = (pData='') =>{
    setAddMarket(false);
    if(pData !== ''){
        dispatch(actions.getMarketPlace());
    }
}


return (
    <>
        {addMarketBool && <AddMarketPlace marketData = {data}  modalOpen={addMarketBool} handleClose={handleClose} />}
    <Select size='small' variant='outlined' onChange={handleSelectChange} value={selectValue}
         input={<BootstrapInput/>}
         sx={{...props.style, width:'100%', fontSize:11, fontFamily:'Inter', color:'text.secondary', fontWeight:400}}
    >
        <MenuItem value='marketplace'>Select Market</MenuItem>
        { data && data.map((item, index) =>{
            const {market_place_id, market_place_name} = item;
            return <MenuItem key={`market${index}`} value={market_place_id}>{market_place_name}</MenuItem>
        })
        }
        <MenuItem value='addmarket'>Add Market</MenuItem>
    </Select>
    </>
)}

export default MarketPlace;