import * as constants from '../constants/dashboardPriceVariation.constants';

export const getDashboardPriceVariation = (payload) => ({
  type: constants.DASHBOARDPRICE_GET,
  payload
});
export const dashboardPriceVariationSuccessful = (response) => ({
    type: constants.DASHBOARDPRICE_SUCCESSFULL,
    response
  });
  
  export const dashboardPriceVariationFailed = (payload) => ({
    type: constants.DASHBOARDPRICE_FAILED,
    payload
  });
  export const dashboardPriceVariationReset = (payload) => ({
    type: constants.DASHBOARDPRICE_RESET,
    payload
  });
