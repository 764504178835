import * as constants from '../constants/updatelocation.constants';

export const handleUpdateLocation = (payload) => ({
  type: constants.UPDATE_LOCATION,
  payload
});

export const updateLocationSuccessful = (response) => ({
  type: constants.UPDATE_LOCATION_SUCCESSFUL,
  response
});

export const updateLocationFailed = (payload) => ({
  type: constants.UPDATE_LOCATION_FAILED,
  payload
});
