import * as constants from '../constants/addbulkproduct.constants';

export const handleBulkProduct = (payload) => ({
  type: constants.VALIDATE_BULK_PRODUCT,
  payload
});

export const bulkProductSuccessful = (response) => ({
  type: constants.BULK_PRODUCT_SUCCESSFUL,
  response
});

export const bulkProductFailed = (payload) => ({
  type: constants.BULK_PRODUCT_FAILED,
  payload
});

export const resetBulkProduct = (payload) => ({
  type: constants.BULK_PRODUCT_RESET,
  payload
});
