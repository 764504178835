import * as constants from '../constants/competitors.constants';

export const initialState = {
  
  productCompetitorDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    competitorData: null,
    message: null,
  },
  isSessionExpired:false
};

export const productCompetitorReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.VALIDATE_COMPETITOR:
      return {
        ...state,
        productCompetitorDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          competitorData: null,
          message: null
        },
      };
    case constants.COMPETITOR_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        productCompetitorDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          competitorData: action.response
        }
      };
    case constants.COMPETITOR_FAILED:
      return {
        ...state,
        productCompetitorDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          competitorData: null
        }
      };
      case constants.COMPETITOR_RESET:
      return {
        ...state,
        productCompetitorDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: null,
          message: null,
          competitorData: null
        }
      };
    default:
      return state;
  }
};
