import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Grid, Paper, Typography, Button, Box, FormControl, InputBase,InputLabel } from '@mui/material';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { styled } from '@mui/material/styles';

// import { CustomThemeContext } from '../../theme/CustomThemeProvider';

import UserProfile from './useLogin';
import { } from './style.css';


const SignInSchema = Yup.object().shape({
    username: Yup.string().min(3, 'Too short - should be 6 chars minimum!').required('Required'),
    password: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })

const LoginScreen = (props) => {
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    // const {currentTheme } = useContext(CustomThemeContext)
    
    const {
        handleSubmit, isFailure
    } = UserProfile()

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: '#E4E3E3',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
        //   '&:focus': {
        //     borderColor: theme.palette.primary.main,
        //   },
        },
      }));

    const handleFormSubmit = (values) =>{
        if(values.username !== '' && values.password !== ''){
            setError(false);
            handleSubmit(values);
        } else {
            setError(true)
        }
    }

  return (  <Grid container>
        {props.user && navigate('/dashboard')}
        <Grid item xs={12} sx={{display:'flex'}}>
            <Grid item xs={12} sm={12} md={7} sx={{display:'flex'}}>
                {/* <img src='/assets/loignImage.jpg' alt='login' width={'100%'} /> */}
            </Grid>
            <Grid item xs={12} sm={12} md={5} 
                sx={{backgroundColor:'transparent', flexDirection:'column', justifyContent:'center' , minHeight:'calc(94vh)', margin: '0px auto', padding:'0px 80px', display:'flex', alignItems:'center'}}>
                <Box>
                <Paper style={{background:'none', boxShadow:'none'}}>
                    <Grid item sx={{display:'flex', mb:1.5, justifyContent:'center'}}>
                    <img src='/assets/logo.png' style={{width:'90%', mb:1}} alt='logo' />
                    </Grid>
                    <Typography variant='h4' component='h4' sx={{color:'#E4E3E3'}}> Login</Typography>
                    <Typography variant='body2' component='span' sx={{color:'#E4E3E3'}}> Welcome back! Please enter your details</Typography>
                    <Grid item xs={12} sx={{display:'flex', flexDirection:'column'}}>
                    <Formik
                    // enableReinitialize
                        // initialValues={{ username: 'orbioadmin', password: 'admin@123' }}
                        initialValues={{ username: '', password: '' }}
                        validationSchema={SignInSchema}
                        //onSubmit = {(values) => {onLoginSubmit(JSON.stringify(values)); console.log(JSON.stringify(values))}}
                        >
                    {({ errors, 
                        setFieldValue,
                        //handleSubmit,
                        values 
                    }) => (
                        <Form style={{}} className='loginform'>
                            <FormControl variant="standard" sx={{mt:1, width:'100%'}} >
                                <InputLabel shrink htmlFor='username' sx={{color:'#E4E3E3'}}>
                                    User email
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.username} 
                                    placeholder='Enter your user name'
                                    id='username'
                                    onChange={e => setFieldValue('username', e.target.value)}
                                    onKeyUp={(e) => {if(e.key === 'Enter')handleFormSubmit(values)}}
                                />
                                <Typography color="red" variant="subtitle">{errors.username}</Typography>
                            </FormControl>

                            <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor='password' sx={{color:'#E4E3E3'}}>
                                    Password
                                    </InputLabel>
                                <BootstrapInput 
                                    type='password'
                                    value={values.password} 
                                    placeholder='Enter your password '
                                    id='password'
                                    onChange={e => setFieldValue('password', e.target.value)}
                                    onKeyUp={(e) => {if(e.key === 'Enter')handleFormSubmit(values)}}
                                />
                                <Typography color="red" variant="subtitle">{errors.password}</Typography>
                            </FormControl>
                        <Grid item xs={12} style={{}}>
                            <Link style={{color:'#E4E3E3'}} to="/forgot">Forgot password?</Link>
                        </Grid>
                        {isFailure && <Typography variant='body2' sx={{color: '#E4E3E3'}}>The user name and password can be wrong. Check and try again.</Typography>}
                        { error && <Typography variant='body2' sx={{color: '#E4E3E3'}}>The user name and password can not be left blank.</Typography>}
                            <FormControl sx={{ m: 0, mt:3, mb:2 }}>
                                <Button sx={{backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} onClick={() => handleFormSubmit(values)}>Login</Button>
                            </FormControl>
                          
                        </Form>
                    )}
                    </Formik>
                        <Typography variant='body2' component={'p'} sx={{color:'#E4E3E3'}} >Don't have an account? Contact 
                            <a style={{color:'#E4E3E3'}} rel="noreferrer" href={'https://www.orbiosolutions.com/contacts/'} target='_blank'> Orbio Solutions</a></Typography>
                    </Grid>
                </Paper>
                </Box>
                <Grid item xs={12} sx={{display:'flex', width:'40%', bottom:20, pt:1,pb:1, position:'fixed', justifyContent:'center', ml:0}}>
                    <img src='/assets/orbioLogo.png' alt='orbio logo' style={{width:30, marginRight:8}} />
                    <Typography sx={{color:'#E4E3E3'}}> Powered By <a href='https://www.orbiosolutions.com/' target='_blank' rel='noreferrer' style={{ color:'inherit'}}>Orbio Solutions</a>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    </Grid> )
};

export default LoginScreen;
