import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors';

// Normal or default theme
const theme = createTheme({
    palette: {
      type:'light',
      primary: {
        main: '#272727',
      },
      secondary: {
        main: '#A70237',
      },
      text: {
        primary:"#5A5A5A",
        secondary:'#272727',
        default: "#5A5A5A",
        main:"#470015",
      },
      action:{
        active: "#5A5A5A"
      },

      buttonText:"#A70237",
      buttonBackground:"#FFFFFF",
      buttonTextHover:"#FFFFFF",
      buttonBackgroundHover:"#a70237",
      error: {
        main: red.A400,
      },
      background: {       //Getting main background changes
        default: '#eaecee',
        paper:'#FFFFFF',
        
      },
      tablehead:'#D9D9D9',
      sectionBackgroundColor:"#fbf5f7",
      aboutSectionBackgroundColor:"#FFFFFF",
      footerBackgroundColor:"#FFFFFF",
      backgroundColor:'#FFF',
      collabrationBackground:"#f8ebef",
      titleColor:"#470015",
      
      titleBar: {
        main: '#eeeeee',
        contrastText: '#222222',
      },
    },
    typography:{
      fontFamily:'Inter',
      fontSize:10,
    }
    
  })
  
  export default theme;