import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const LinearPrgs = (props) =>{
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 4,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: theme.palette.type === 'dark'?'#515151' : '#CECECE'
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 4,
          backgroundColor: props.barColor
        },
      }));


return (
    <div style={{display:'flex', width:'100%', alignItems:'center', margin:'24px 24px' }}>
        <Box sx={{ minWidth: 110, textAlign:'left' }}>
        {/* fontSize:15 */}
            <Typography variant="body2" sx={{fontWeight:500, fontSize:12, color:'text.secondary'}}>{props.label}</Typography>
        </Box>
        <BorderLinearProgress variant="determinate" sx={{width:'100%', marginLeft:1, marginRight:'24px'}}
           value={props.value} {...props} />
        <Box sx={{ minWidth: 90 }}>
            <Typography variant="body2" sx={{textAlign:'left', color:'text.secondary'}}>{props.labelValue}</Typography>
        </Box>
    </div>
)


}

export default LinearPrgs;