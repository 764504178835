import * as constants from '../constants/editProduct.constants';

export const editProduct = (payload) => ({
  type: constants.PRODUCT_EDIT,
  payload
});
export const editProductSuccessful = (response) => ({
    type: constants.PRODUCT_EDIT_SUCCESSFULL,
    response
  });
  
  export const editProductFailed = (payload) => ({
    type: constants.PRODUCT_EDIT_FAILED,
    payload
  });
  export const editProductReset = (payload) => ({
    type: constants.PRODUCT_EDIT_RESET,
    payload
  });

  
  

  



