import * as constants from '../constants/sellers.constants';

export const getSellers = (payload) => ({
  type: constants.SELLER_GET,
  payload
});

export const getSellersSuccessful = (response) => ({
  type: constants.SELLER_SUCCESSFUL,
  response
});

export const getSellersFailed = (payload) => ({
  type: constants.SELLER_FAILED,
  payload
});
export const getSellersReset = (payload) => ({
  type: constants.SELLER_RESET,
  payload
});
