import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// Dark theme
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: "#E4E3E3",    //dark
    },
    secondary: {
      main: '#FFB74D',
    },
    text:{
      primary: "#E4E3E3",
      secondary:'#FFFFFF',
      default: "#E4E3E3"
    },
    background: {
        default: '#1a222d',
        paper:'#232D3B',
        
    },
    action:{
      active: "#E4E3E3"
    },
    tablehead:'#314055',
    backgroundColor:'#1a222d',
    titleColor:"#cfcece",
    titleBar: {
      main: '#FFFFFF',
      contrastText: '#ffffff',
    },
    error: {
      main: red.A400,
    },
  },
  typography:{
    fontFamily:'Inter', 
    fontSize:11,
  },
})

export default theme