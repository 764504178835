import React, { useEffect, useState } from 'react';
 import { trim } from 'lodash';
import { Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend, } from "chart.js";
import { Bar} from 'react-chartjs-2';
ChartJS.register(ArcElement, CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend);



const BarChart = props => {

    const {barRecordData, matchLabelData, currentTheme} = props;
    const [barFilterData, setBarFilterData] = useState([])
    // console.log('barRecordData ', barRecordData)   
    // console.log('currentTheme ', currentTheme)
    useEffect(() => {
        if(barRecordData && !barRecordData.status){
            setBarFilterData(Object.values(barRecordData));
        }
    }, [barRecordData])

    const labels = [["100%", "Match"], ["90-100%","Match"], ["80-90%","Match"], ["70-80%","Match"], ["60-70%","Match"], ["<60%","Match"]];
    const chartColors = ['#A8CBFF', '#4F8EEC', '#355FBE' ]
    const barData = {
        labels,
        datasets:barFilterData.map((barItem, index) => {
            const barlabel = matchLabelData[index]
            const barValues = barItem.map(bard => Object.values(bard))
            // console.log(barValues)
            return ({
                label: trim(barlabel),
                data: barValues.flat(),
                backgroundColor: chartColors[index],
                stack: String(index)
            })
        })
        // datasets: [
        //     {
        //       label: 'Site 1',
        //       data: isEmpty(barRecordData) ? 0 : labels.map(() => Math.random()*100),
        //       backgroundColor: '#A8CBFF',
        //       stack: '0'
        //     },
        //     {
        //       label: 'Site 2',
        //       data: isEmpty(barRecordData) ? 0 : labels.map(() => Math.random()*100),
        //       backgroundColor: '#4F8EEC',
        //       stack: '1'
        //     },
        //     {
        //       label: 'Site 3',
        //       data: isEmpty(barRecordData) ? 0 : labels.map(() => Math.random()*100),
        //       backgroundColor: '#355FBE',
        //       stack: '2'
        //     },
        //   ],
    };

    //  console.log(barData.datasets)
    const option = {
        responsive: true,
        maintainAspectRatio: false,
        barThickness: 15, 
        interaction: {
        intersect: false
        },
        scales: {
            x: {
                stacked: true,
                grid:{
                    drawOnChartArea:false
                },
                ticks:{
                    color: currentTheme === 'dark'? '#FFFFFF' : '#272727',
                    maxRotation: 0,
                    autoSkip: false,
                },border:{
                    display:true,
                    color:'#515151'
                  },
            },
            y: {
                stacked: true,
                labelAngle: 0,
                grid:{
                    drawOnChartArea:false
                },
                ticks:{
                    color: currentTheme === 'dark'? '#FFFFFF' : '#272727',
                    maxRotation: 0,
                    autoSkip: false,
                },border:{
                    display:true,
                    color:'#515151'
                  },
            },
            
        },
        plugins: {
            legend: {
              display: false
            },
            datalabels: {
                display: false
            }
            
          }
    }


return (
    <Bar options={option} data={barData} width={650} height={230} />
)

}
export default BarChart;