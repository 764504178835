import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/authentication.actions';
import * as constants from '../constants/authentication.constants';
import NetworkApi from '../../api/NetworkApi';
import { setItems } from '../../utils/storage';
import paths from '../../api/path';


export function* validateLogin(request = {}) {
  const { payload = {} } = request;
  const {
    username,
    password
  } = payload;
  try {
    const response = yield call(NetworkApi.post, paths.validateLoginURL, {
        username,
        password,
    }, );
    if (response && response.message.toLocaleLowerCase() === 'ok') {
      const {
        token,productsCreated, currency, role
      } = response;
      // console.log(token.length);
      if (token.length> 1) {
        setItems([
          {
            key: 'token',
            value: token
          },
          {
            key:'role',
            value:role
          },
          {
            key: 'productsCreated',
            value: productsCreated
          },
          {
            key: 'currency',
            value: JSON.stringify(currency)
          }
        ]);
        yield put(actions.userValidationSuccessful(response));
      } else {
        
      }
    } else {
      console.log('error ', response)
      const message = response?.non_field_errors ? (
        response?.non_field_errors
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.userValidationFailed(message));
    }

  } catch (error) {
    const message = error?.response?.non_field_errors ? (
      error.response.non_field_errors
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.userValidationFailed(message));
  }
}


export function* fetchLogin() {
  yield takeLatest(constants.VALIDATE_LOGIN, validateLogin);
}
