import * as constants from '../constants/dashboard.constants';

export const getDashboardDetails = (payload) => ({
  type: constants.DASHBOARD_GET,
  payload
});
export const dashboardSuccessful = (response) => ({
    type: constants.DASHBOARD_SUCCESSFULL,
    response
  });
  
  export const dashboardFailed = (payload) => ({
    type: constants.DASHBOARD_FAILED,
    payload
  });
  
  export const dashboardReset = (payload) => ({
    type: constants.DASHBOARD_RESET,
    payload
  });
  