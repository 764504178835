import * as constants from '../constants/brands.constants';

export const getBrands = (payload) => ({
  type: constants.BRAND_GET,
  payload
});

export const getBrandsSuccessful = (response) => ({
  type: constants.BRAND_SUCCESSFUL,
  response
});

export const getBrandsFailed = (payload) => ({
  type: constants.BRAND_FAILED,
  payload
});
export const getBrandsReset = (payload) => ({
  type: constants.BRAND_RESET,
  payload
});
