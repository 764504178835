import * as constants from '../constants/addsingleproduct.constants';

export const handleSingleProduct = (payload) => ({
  type: constants.VALIDATE_SINGLE_PRODUCT,
  payload
});

export const singleProductSuccessful = (response) => ({
  type: constants.SINGLE_PRODUCT_SUCCESSFUL,
  response
});

export const singleProductFailed = (payload) => ({
  type: constants.SINGLE_PRODUCT_FAILED,
  payload
});

export const resetProduct = (payload) => ({
  type: constants.SINGLE_PRODUCT_FAILED,
  payload
});
