import axios from 'axios';
// import { toast } from 'react-toastify';
// import { get } from 'lodash';
//import ToastMessage from '../components/ToastMessage';
//import selectUserDetails from '../../redux/selectors/authenticate.selector';

const NetworkApi = {
  requestTimeout: 30000,
  getDefaultHeaders: () => ({
  //  'Access-Control-Allow-Origin': 'http://3.19.221.200',
    Accept: 'application/json',
    'Access-Control-Request-Headers': 'Content-Type, x-requested-with',
    // 'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
    //'x-nc-app-name': 'Trader_Desktop'
  }),

  getHeaders: () => {
    const headers = {
      ...NetworkApi.getDefaultHeaders()
    };
    return headers;
  },

  get: (route, params, headers) =>
    new Promise((resolve, reject) => {
      NetworkApi.prepareConfig(route, params, 'get', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    }),
  post: (route, params, headers, hidePopError) =>
    new Promise((resolve, reject) => {
      NetworkApi.prepareConfig(route, params, 'post', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      }, hidePopError);
    }),
  patch: (route, params, headers) =>
    new Promise((resolve, reject) => {
      NetworkApi.prepareConfig(route, params, 'patch', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    }),
    put: (route, params, headers) =>
    new Promise((resolve, reject) => {
      NetworkApi.prepareConfig(route, params, 'put', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    }),
  delete: (route, params, headers) =>
    new Promise((resolve, reject) => {
      NetworkApi.prepareConfig(route, params, 'delete', headers, (err, res) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(res);
      });
    }),
  prepareConfig: async (url, data, methodType, headers, callback, hidePopError) => {
    const config = {
      method: methodType,
      url,
      data,
      headers: headers || NetworkApi.getDefaultHeaders()
      // timeout: NetworkApi.requestTimeout
    };
    NetworkApi.call(config, callback, hidePopError);
  },
  call: (config, callback, hidePopError) => {
    // console.log('api ', config)
    axios(config)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
        // console.log('error', error);
        if(error?.response) {
          // const errMsg = get(error?.response?.data,'non_field_errors','') || get(error,'response','');
          
          // if(errMsg?.includes('Unable to log in with provided credentials.')) {
          //   toast('Unable to log in with provided credentials.',{
          //     autoClose: false,
          //     type:'error'
          //   });
          // }
          // else if(!hidePopError) {
          //   toast(
          //     error?.response?.data.non_field_errors,
          //     // <ToastMessage errorMessage={error?.message==='Request failed with status code 500'?"Something went wrong. Please try again":error?.message} showRefresh />,
          //     {
          //       type:'error',
          //       theme: 'colored'
          //     }
          //   );
          // }
        }
         else if(error?.message){
          // const errMsg = get(error?.message?.data,'non_field_errors','') || get(error,'message','');
          // toast(errMsg,{
          //   autoClose: false,
          //   type:'error'
          // });
        }
      });
  }
};

export default NetworkApi;
