//const WORKFLOW_URL = `http://3.19.221.200`;
import axios from "axios";

let WORKFLOW_URL = `https://demo.ottixhow.app`;
let API_URL = '/api';
const PROD_URL = '/myproducts/'
//const REFRESH_URL = 'http://18.188.130.132/api';

let paths = {};
const setPathData = (pPath, pAPI) => {
  // console.log('pPath ',pPath)
  WORKFLOW_URL = pPath;

  paths = {
    validateLoginURL: `${pPath}/login/`,
    qaUserURL: `${pPath}${API_URL}/qa/clients/`, 
    dashBoardURL: `${pPath}${API_URL}/dashboard/`,
    productpriceVariationURL: `${pPath}${API_URL}/mongo/get_price_variants/`,
    dashboardpriceVariationURL: `${pPath}${API_URL}/mongo/get_price_variance_dashboard/`,
    myProductURL: `${pPath}${API_URL}/product_list/`,
    qaProductURL: `${pPath}${API_URL}/qa/`,
    qaUpdateCompetitorURL: `${pPath}${API_URL}/qa/update_competitor/`,
    productPrevNextURL: `${pPath}${API_URL}/qa/previous_next/`,
    productURL: `${pPath}${API_URL}${PROD_URL}`,
    updateProductURL:`${pPath}${API_URL}${PROD_URL}update_product/`,
    qaUpdateProductURL: `${pPath}${API_URL}/qa/update_product/`,
    getProductCompetitorURL: `${pPath}${API_URL}${PROD_URL}competitors/`,
    addBulkProductURL: `${pPath}${API_URL}${PROD_URL}upload/`,
    locationsURL: `${pPath}${API_URL}/locations/`,
    sellersURL: `${pPath}${API_URL}/seller/`,
    productLocationURL: `${pPath}${API_URL}/locations/get_prd_loc/`,
    categoriesURL: `${pPath}${API_URL}/category/`,
    qaCategoriesURL: `${pPath}${API_URL}/qa/category/`,
    departmentURL: `${pPath}${API_URL}/department/`,
    departmentsURL: `${pPath}${API_URL}/departments/`,
    qaDepartmentsURL: `${pPath}${API_URL}/qa/department/`,
    brandsURL:`${pPath}${API_URL}${PROD_URL}brands/`,
    qaBrandsURL:`${pPath}${API_URL}/qa/brands/`,
    qaApproveURL:`${pPath}${API_URL}/qa/approve_all/`,
    
    competitorURL: `${pPath}${API_URL}/competitors/`,
    matchRateURL: `${pPath}${API_URL}/competitors/update_prd_score/`,
    
    marketplaceURL: `${pPath}${API_URL}/market_place/`,
    deletemarketplaceURL: `${pPath}${API_URL}/market_place/delete/`,
  
    marketLocationURL: `${pPath}${API_URL}/settings/mp_locations/`,
    addMarketLocURL: `${pPath}${API_URL}/settings/add_mp_locations/`,
    updateLocationURL: `${pPath}${API_URL}/locations/set_default_loc/`,
  
    ruleURL: `${pPath}${API_URL}/settings/`,
    updateRuleURL: `${pPath}${API_URL}/settings/update_settings/`,
    currencyURL: `${pPath}${API_URL}/settings/currency`,
  
    passwordURL: `${pPath}/changepassword/`,
    refreshURL: `${pPath}${API_URL}/competitors/refresh/`,
  
    deleteAllProductURL: `${pPath}${API_URL}${PROD_URL}delete_all/`,
    // downloadXlsURL: `${pPath}${API_URL}${PROD_URL}export_excel_file/`,
    downloadXlsURL: `${pPath}${API_URL}${PROD_URL}export_json_file/`,
    productNotifyURL: `${pPath}${API_URL}${PROD_URL}enable_notification/`,
    seettingsNotifyURL: `${pPath}${API_URL}/settings/enable_notification/`,
    
  };
  // console.log(paths)
  return paths;
}



axios.get('path.json')
.then(res => {
  // console.log(res.data);
  // WORKFLOW_URL = res.data.BASE_URL
  // API_URL = res.data.API_URL
  setPathData(res.data.BASE_URL)
})
.catch(err => console.log('err ', err))

// console.log('WORKFLOW_URL ', WORKFLOW_URL)
setPathData(WORKFLOW_URL);
export default paths;
