import * as constants from '../constants/productLocations.constants';

export const getProductLocations = (payload) => ({
  type: constants.PRODUCT_LOCATION_GET,
  payload
});

export const getProductLocationsSuccessful = (response) => ({
  type: constants.PRODUCT_LOCATION_SUCCESSFUL,
  response
});

export const getProductLocationsFailed = (payload) => ({
  type: constants.PRODUCT_LOCATION_FAILED,
  payload
});
export const getProductLocationsReset = (payload) => ({
  type: constants.PRODUCT_LOCATION_RESET,
  payload
});
