import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/dashboard.actions';
import * as constants from '../constants/dashboard.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
// import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* getDashboardDetails(request = {}) {

  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  // const {
  //   username,
  //   password
  // } = payload;
  console.log(payload);
  try {
    const response = yield call(NetworkApi.post, paths.dashBoardURL, 
      payload,
      apiConfig, );
    // console.log(response);

    if (response) {
      
      yield put(actions.dashboardSuccessful(response));
    } else {
      console.log('error ', response)
      const message = response?.data?.detail ? (
        response?.data?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.dashboardFailed(message));
    }

  } catch (error) {
    // console.log('dashboard ', error?.response?.data?.detail)
    const message = error?.response?.data?.detail ? (
        error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    console.log('dashboard ', message)
    yield put(actions.dashboardFailed(message));
  }
}

export function* fetchDashboardDetails() {
  yield takeLatest(constants.DASHBOARD_GET, getDashboardDetails);
}
