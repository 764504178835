import * as constants from '../constants/competitors.constants';

export const getCompetitors = (payload) => ({
  type: constants.VALIDATE_COMPETITOR,
  payload
});

export const getCompetitorsSuccessful = (response) => ({
  type: constants.COMPETITOR_SUCCESSFUL,
  response
});

export const getCompetitorsFailed = (payload) => ({
  type: constants.COMPETITOR_FAILED,
  payload
});
export const getCompetitorsReset = (payload) => ({
  type: constants.COMPETITOR_RESET,
  payload
});
