import * as constants from '../constants/productDetails.constants';

export const initialState = {
  
  products: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    isFirstTime: false,
    data: null,
    message: null,
  }
};

export const myproductReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.MYPRODUCT_GET:
      return {
        ...state,
        products: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          message: null
        }
      };
    case constants.MYPRODUCT_SUCCESSFULL:
      return {
        ...state,
        products: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          isFirstTime: true,
          message: null,
          data: action.response
        }
      };
    case constants.MYPRODUCT_FAILED:
      return {
        ...state,
        products: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
        }
      };
      case constants.MYPRODUCT_RESET:
      return {
        ...state,
        products: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          message: null,
          data: null
        }
      };
    default:
      return state;
  }
};
