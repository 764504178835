import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import Input from '../../../components/Input/index'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModifyPop = props => {
    const {handleSuccess, handleFailed} = props;
    const [open, ] = useState(true);
    const [productURL, setProductURL] = useState('')

  const handleClickOpen = () => {
    //setOpen(true);
  };

  const handleOkay = () => {
    handleSuccess();
    //setOpen(false);
  }

  const handleClose = () => {
    //setOpen(false);
    handleFailed(false)
  };
  const onProductURLChange=(e) =>{
    setProductURL(e.target.value)
  }


  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Modiy URL</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Input placeHolderValue='Enter Product URL' fontcolor='text.primary' labelName='Name' value={productURL} onChange={onProductURLChange} />    
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkay}>Modify</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ModifyPop;