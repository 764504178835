import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/qaapprove.actions';
import * as constants from '../constants/qaaprove.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* approveAll(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  // console.log('approveAll', payload)
  try {
    const response = yield call(NetworkApi.put, paths.qaApproveURL, payload, apiConfig);
    //  console.log( 'approve ',response)
    if (response && response.status === 'success') {
      yield put(actions.approveAllSuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.status ? (
        response?.status
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.approveAllFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.status ? (
      error?.response?.data?.status
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.approveAllFailed(message));
  }
}

export function* handleApproveAll() {
  yield takeLatest(constants.QA_APPROVE, approveAll);
}
