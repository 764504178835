import * as constants from '../constants/deletemarketplace.constants';

export const deleteMarketPlace = (payload) => ({
  type: constants.DELETE_MARKET_PLACE,
  payload
});

export const deleteMarketPlaceSuccessful = (response) => ({
  type: constants.DELETE_MARKET_PLACE_SUCCESSFUL,
  response
});

export const deleteMarketPlaceFailed = (payload) => ({
  type: constants.DELETE_MARKET_PLACE_FAILED,
  payload
});

export const deleteMarketPlaceReset = (payload) => ({
  type: constants.DELETE_MARKET_PLACE_RESET,
  payload
});
