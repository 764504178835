import * as constants from '../constants/addmarketplace.constants';

export const initialState = {
  
  addMarketplaceDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
  isSessionExpired:false
};

export const addMarketplaceReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.ADD_MARKET_PLACE:
      return {
        ...state,
        addMarketplaceDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          errorMessage: null
        },
      };
    case constants.ADD_MARKET_PLACE_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        addMarketplaceDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          errorMessage: null,
          data: action.response
        }
      };
    case constants.ADD_MARKET_PLACE_FAILED:
      return {
        ...state,
        addMarketplaceDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          errorMessage: payload,
          data: null
        }
      };
      
    default:
      return state;
  }
};
