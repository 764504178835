import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
import * as actions from '../actions/productnotify.actions';
import * as constants from '../constants/productnotify.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}
export function* handleProductNotify(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  // console.log(payload)
  try {
    const response = yield call(NetworkApi.put, paths.productNotifyURL, payload, apiConfig);
    // console.log( 'add location ',response)
    if (response && response?.status === 'success') {
      yield put(actions.productNotifySuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.status ? (
        response?.status
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.productNotifyFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.status ? (
      error?.response?.data?.status
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.productNotifyFailed(message));
  }
}

export function* updateProductNotification() {
  yield takeLatest(constants.PRODUCT_NOTIFICATION, handleProductNotify);
}
