import React, { useState, useCallback, useContext } from 'react';
import { Grid, Typography, Paper, Button, IconButton, TextField, Select, MenuItem, InputBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {styled} from '@mui/material/styles';
import moment from 'moment';
import EastIcon from '@mui/icons-material/East';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import LineChart from './LineChart';
import * as variationactions from '../../redux/actions/dashboardPriceVariation.actions';
import selectDashboardPriceVariationDetails from '../../redux/selectors/dashboardPriceVariation.selector'
import { isEmpty } from 'lodash';
import { CustomThemeContext } from '../../theme/CustomThemeProvider';
import Loader from '../loader';


const DashboardVariationChart = props => {
    // const {id, competitorInformation} = props;
    const currentTheme = useContext(CustomThemeContext);
    const [selectedOption, setSelectedOption ] = useState(7);
    const [showError, setShowError] = useState({bool:false, msg:''});
    const [showChartOption, setShowChartOption] = useState(false);
    const DARK_TEXT_COLOR = '#E4E3E3';
    const LIGHT_TEXT_COLOR = '#272727'; 
    const [customDates, setCustomDates] = useState({
        from_date: '',
        to_date: '',
    })
    const { dashboardPriceVariationDetails } = useSelector(selectDashboardPriceVariationDetails);
    const { data, isLoading, isFailure } = dashboardPriceVariationDetails;
    const colorArr = ['#EAD18F', '#FF7272', '#4F8EEC']
    // const userCurrency = getItem('currency');

    const dispatch = useDispatch();
    // console.log(competitorInformation[0].market_place.market_place_id);
    // const [chartView, setChartView] = useState(false);
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
    }));
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: currentTheme.currentTheme === 'dark' ? '#232D3B' : '#FFFFFF',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
      }));

    const getPriceVariation = useCallback(({frmDate, toDate}) =>{

        dispatch(variationactions.getDashboardPriceVariation({from_date: frmDate, to_date: toDate}))
    }, [dispatch]); // eslint-disable-line

    const handlePriceVariantOption = (pTarget) => {
        // const { value } = pTarget.target;
        const { value } = pTarget.target;
        setSelectedOption(value);
        setShowError({bool: false, msg: ''});
        // console.log('value ', value)
        if(value !== -1 && value !== 0){
            const getPeriod = moment().subtract(value, 'days').format('YYYY-MM-DD');
            // console.log(getPeriod, moment().format('YYYY-MM-DD'));
            getPriceVariation({toDate: moment().format('YYYY-MM-DD'), frmDate: getPeriod})
        }
    }
    const handleCustomSubmit = () => {
        const currentDate = moment().format('YYYY-MM-DD');
        const { from_date, to_date } = customDates;
        if(isEmpty( from_date) || isEmpty( to_date)){
            setShowError({bool:true, msg: "Dates can not be left blank."})
            return;
        }
        
        // const diff = moment.duration(moment(currentDate).diff(moment(from_date)));
        // console.log(isEmpty( from_date), 'diff ' , diff._data.days, diff);
        if(from_date > currentDate || to_date > currentDate){
            setShowError({bool:true, msg: "From or To date should not be greater than current date."});
            return;
        }
        getPriceVariation({frmDate: from_date, toDate: to_date})
    }
    const handleCustomPriceVariant = (pTarget) => {
        setShowError({bool: false, msg: ''});
        const {value, id} = pTarget.target;
        const dateData = {...customDates};
        dateData[id] = value;
        setCustomDates(dateData);
    }
    const showGraphPanel = () =>{
        setShowError({bool: false, msg: ''});
        setShowChartOption(true);
        getPriceVariation({toDate: moment().format('YYYY-MM-DD'), frmDate: moment().subtract(7, 'days').format('YYYY-MM-DD')})

    }

        return (
            <Grid id="container" item sx={{padding:0, mt:2, ml:2}}>
                <Item id="container1" sx={{padding:0, pt:2}}>
                    <Grid id="container2" item xs={12} sx={{display:'flex', width: '100%'}}>
                        <Grid item xs={12} md={3} lg={3}> 
                            <Typography variant='h5' className='typo' component={'h5'} 
                                sx={{ml:2, textAlign:'left', color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>Price variance
                            </Typography>
                        </Grid>
                        {showChartOption && <Grid item xs={12} md={9} lg={9} sx={{display:'flex', justifyContent:'flex-end', flexFlow:'column-reverse', mr: 2}}>
                            { showError.bool && <Typography sx={{mt:1, position:'absolute', marginTop:'7em'}} variant="body2">{showError.msg}</Typography>}
                            <Grid item sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                
                                <Grid item>
                                    {/* <Typography variant='body2' sx={{mb:1}}>Select Option</Typography> */}
                                    <Select size='small'
                                        input={<BootstrapInput sx={{marginTop:'0px !important'}}/>}
                                        value= {selectedOption} onChange={handlePriceVariantOption}>
                                        <MenuItem value={7}>Last Week</MenuItem>
                                        <MenuItem value={30}>Last Month</MenuItem>
                                        <MenuItem value={0}>Select Period</MenuItem>
                                    </Select>
                                </Grid>
                                { selectedOption === 0 && <Grid item sx={{ ml:0, display:'flex', alignItems:'center' }}>
                                    <TextField id="from_date" placeholder='Start Date' value={customDates.from_date} onChange={handleCustomPriceVariant} sx={{ml:1}} size='small' type='date' />
                                    <EastIcon sx={{ml:1, color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}} />
                                    <TextField id="to_date" placeholder='End Date' value={customDates.to_date} onChange={handleCustomPriceVariant} sx={{ml:1}} size='small' type='date' />
                                    <IconButton 
                                        sx={{
                                            ml:1,
                                            padding:'2px',
                                            backgroundColor: currentTheme.currentTheme === 'dark' ? '#1A2027' : '#FFFFFF', 
                                            borderColor:'#1C3B6E', 
                                            color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR
                                        }}
                                        onClick={handleCustomSubmit}>
                                            <ExpandCircleDownOutlinedIcon sx={{transform:'rotate(265deg)', fontSize:'22px'}}/>
                                        </IconButton>
                                    </Grid>
                                }
                            
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} md={12} xl={12} sx={{width:'auto',mt: '50px', position:'absolute', }}>
                        {isEmpty(data) && !isLoading && isFailure && <Typography variant='body2' 
                            sx={{mb:2, fontSize:'1.2em', color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>There is no data available.</Typography>
                        }
                        {isEmpty(data) && isLoading && (
                            <Loader />
                        )  }
                        {!showChartOption && 
                            <Button 
                                onClick={showGraphPanel}
                                sx={{zIndex:100, margin:'0px 0px 0px -5em', backgroundColor:'#559EFF', fontSize:11, textTransform:'none' , borderColor:'#1C3B6E', color:'#000000'}}>Refresh</Button>
                        }
                     </Grid>
                
                    <Grid item sx={{mx:2, display:'flex', flexDirection:'column', filter:showChartOption && !isEmpty(data) ? 'blur(0px)' : 'blur(2px)'}} >
                    <Grid item xs={12} sx={{mt:'1em', mr:1, display:'flex', justifyContent:'flex-end'}}>
                            {
                                !isEmpty(data) 
                                ? <div style={{display:'flex',alignItems:'flex-start',flexDirection:'row'}}>
                                    {/* {console.log(Object.keys(Object.values(data[0])[0]))} */}
                                    {Object.keys(Object.values(data[0])[0]).map((item, pIndex) => 
                                        <Grid key={`chartTitle_${pIndex}`} item sx={{display:'flex', alignItems:'center', mr:1}}>
                                            <div style={{backgroundColor:colorArr[pIndex % 3], width:'10px', height:'10px', borderRadius:'50%'}} />
                                            <Typography className='chartLegendLabel' component={'p'} variant='body2' sx={{ml:1, color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>{item}</Typography>
                                        </Grid>
                                        )}
                                </div>
                                :
                                <>
                                    <div style={{display:'flex', alignItems:'center', marginLeft:8}}>
                                        <div style={{backgroundColor:'#4F8EEC',width:'16px', height:'16px', borderRadius:'50%'}} />
                                        <Typography className='chartLegendLabel' component={'p'} variant='body2'>Competitor variance 1</Typography>
                                    </div>
                                    <div style={{display:'flex', alignItems:'center', marginLeft:8}}>
                                        <div style={{backgroundColor:'#A8CBFF',width:'16px', height:'16px', borderRadius:'50%'}} />
                                        <Typography className='chartLegendLabel' component={'p'} variant='body2'>Competitor variance 2</Typography>
                                    </div>
                                </>
                            }
                                
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className='chartLegendLabel' component={'p'} variant='body2' 
                                sx={{
                                        width:'90%', marginTop:'2em', textAlign:'left', marginLeft:'0em !important',
                                        color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR
                                    }}>
                                    Price</Typography>    
                                <div style={{maxWidth:'90%', width:'100%', marginTop:'1em'}}>
                                    <LineChart showChartOption={showChartOption} colorArr = {colorArr} currentTheme={currentTheme} pricevariantData = {data} {...props} />
                                </div>
                                <Typography className='chartLegendLabel' component={'p'} variant='body2' 
                                    sx={{ mt:'-1em', mb:'1em', pr:1, width:'100%', textAlign:'right !important', color:currentTheme.currentTheme === 'light'? LIGHT_TEXT_COLOR:DARK_TEXT_COLOR}}>Date</Typography>
                        </Grid>
                        
                    </Grid>
                
                  
                </Item>
                
                
            </Grid>
    )
}

export default DashboardVariationChart;