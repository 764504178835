import * as constants from '../constants/changepassword.constants';

export const initialState = {
  
  passwordDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
  isSessionExpired:false
};

export const changePasswordReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.PASSWORD_MODIFY:
      return {
        ...state,
        passwordDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          ruledata: null,
          errorMessage: null
        },
      };
    case constants.PASSWORD_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        passwordDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          errorMessage: null,
          ruledata: action.response
        }
      };
    case constants.PASSWORD_FAILED:
      return {
        ...state,
        passwordDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          errorMessage: payload,
          ruledata: null
        }
      };
      
    default:
      return state;
  }
};
