import * as constants from '../constants/categories.constants';

export const getCategories = (payload) => ({
  type: constants.CATEGORY_GET,
  payload
});

export const getCategoriesSuccessful = (response) => ({
  type: constants.CATEGORY_SUCCESSFUL,
  response
});

export const getCategoriesFailed = (payload) => ({
  type: constants.CATEGORY_FAILED,
  payload
});
export const getCategoriesReset = (payload) => ({
  type: constants.CATEGORY_RESET,
  payload
});
