import React from 'react';
import {Grid, Box, Button } from '@mui/material';
// import CircleIcon from '@mui/icons-material/Circle';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';


const LeftPanel = props => {

  
const {setProduct, productLabel} = props;

const handleClick = (newLabel) =>{
  setProduct(newLabel)
}

const btnStyle = {fontSize:12, textDecoration:'none', color:'text.secondary', textTransform:'inherit'}

    return (
        <Grid xs={2} item sx={{minHeight: 'calc(94vh - 70px)', paddingLeft:3, maxWidth:'15% !important', paddingTop:1, backgroundColor:'background.paper'}}>
          <Box sx={{ overflow: 'auto', pl:2, pt:2 }}>
              <Button variant='text' sx={{...btnStyle}} onClick={() => {handleClick('bulk')}}
                endIcon={productLabel === 'bulk' && <DoubleArrowIcon sx={{ ml:2, fontSize:'12px !important', color:'text.secondary'}} />}
                >Bulk Upload </Button>
              <Button variant='text' sx={{...btnStyle}}  onClick={() => {handleClick('single')}}
                endIcon={productLabel === 'single' && <DoubleArrowIcon sx={{ fontSize:'12px !important', color:'text.secondary'}} />}
              >Single Product</Button>
            </Box>
        </Grid>
    )
}

export default LeftPanel;