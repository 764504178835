import { useEffect, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import useActions from '../../redux/use-actions';
import { getItems } from '../../utils/storage';
import selectDashboardDetails from '../../redux/selectors/dashboard.selector';
import * as actions from '../../redux/actions/dashboard.actions';
import * as locationActions from '../../redux/actions/locations.actions';
import * as marketPlaceActions from '../../redux/actions/marketplace.actions'

const UseDashboard = () => {

    const dispatch = useDispatch();
    const [userToken] = getItems(['token']);
    //const actions = useActions({ getDashBoard });
    const { dashboardDetails} = useSelector(selectDashboardDetails);
    const {data, isSuccessful, isFailure} = dashboardDetails;
   // const [dashboardSummaryDetails, setDashboardSummaryDetails] = useState(data)

    useEffect(() =>{
        if(userToken && !data){
          dispatch(locationActions.getLocations())
          dispatch(marketPlaceActions.getMarketPlace())
          dispatch(actions.getDashboardDetails({"loc_id":[14,15]}))
        }
    }, [dispatch, userToken]) //eslint-disable-line

    return {dashboardDetails: data, isSuccessful, isFailure}
}

export default UseDashboard;