import * as constants from '../constants/settingsnotify.constants';

export const handleSettingsNotify = (payload) => ({
  type: constants.SETTING_NOTIFICATION,
  payload
});

export const settingsNotifySuccessful = (response) => ({
  type: constants.SETTING_NOTIFICATION_SUCCESSFUL,
  response
});

export const settingsNotifyFailed = (payload) => ({
  type: constants.SETTING_NOTIFICATION_FAILED,
  payload
});

export const settingsNotifyReset = (payload) => ({
  type: constants.SETTING_NOTIFICATION_RESET,
  payload
});
