import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/deleteallproduct.actions';
import * as constants from '../constants/deleteallproduct.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}

export function* handleDeleteAllProduct(request = {}) {
  const apiConfig = getApiConfig()
  const { payload = {} } = request;
  //const obj = {prd_id:payload};
  console.log(payload, apiConfig);
  
  // console.log('handleDeleteproduct ', payload);
  try {
    const response = yield call(NetworkApi.delete, paths.deleteAllProductURL, payload, apiConfig);
    console.log(response)
    if (response && !response?.detail) {
        console.log('kki ',response)
        yield put(actions.deleteAllProductSuccessful(response));
      
    } else {
      console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.deleteAllProductFailed(message));
    }

  } catch (error) {
    console.log('net erro error ', error?.response)
    const message = error?.response?.statusText ? (
      error?.response?.statusText
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.deleteAllProductFailed(message));
  }
}

export function* deleteAllProduct() {
  yield takeLatest(constants.DELETE_ALL_PRODUCT, handleDeleteAllProduct);
}
