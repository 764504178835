import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { Grid, Typography, Collapse, Divider, IconButton, Button, InputBase, FormControl, InputLabel, Alert } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import selectAddSellerDetails from '../../../redux/selectors/addseller.selector'
import { handleAddSeller, addSellerReset } from '../../../redux/actions/addseller.actions'
import useActions from '../../../redux/use-actions';
import CloseIcon from '@mui/icons-material/Close'


import CompModal from '../../../components/Modal'
import { isEmpty } from 'lodash';

const marketSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })



const AddSeller = props =>{

    const dispatch = useDispatch()
    const {sellerData } = props;
    const { addSellerDetails } = useSelector(selectAddSellerDetails);
// const [selectValue, setSelectValue] = useState('marketPlace');
const { data, isSuccessful, isFailure} =  addSellerDetails;
const actions = useActions({ handleAddSeller, addSellerReset });
const [errorBool, setErrorBool] = useState(false)

// console.log('addcompetitor', data, isSuccessful)
useEffect(()=>{
  let times = null;
    if(isSuccessful){
      times = setTimeout(() =>{
        setErrorBool(false)
        props.handleClose(data)
        dispatch(actions.addSellerReset())
        clearTimeout(times);
      }, 2000)
    }
    return () => clearTimeout(times);
}, [isSuccessful]) // eslint-disable-line

    const formStyle = {width:'100%'};

    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));
    
    const checkName = (pName) => {
        return sellerData.find(item => item.seller_name !== '' && item.seller_name === pName)
    }
    const handleSubmit = (values) =>{
       console.log(values);
    //    console.log(sellerData)
       const name = checkName(values.seller_name)
    //  console.log(isEmpty(name));
       if(isEmpty(name)){
        setErrorBool(false)
        actions.handleAddSeller(values);
       } else {
        setErrorBool(true)
       }
    
    }
    return (
        <CompModal {...props} modalOpen={true}>
                <Typography variant="h5" component={'h5'}>Add Seller</Typography>
                <Divider />
                <Collapse in={errorBool}>
                
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorBool(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 1 }}
        >
          This seller is already taken.
        </Alert>
      </Collapse>

                <Grid item>
                <Formik
                   // enableReinitialize
                    initialValues={{ seller_name: '', seller_rating: 5,  seller_tot_reviews: 0, loc:props.loc_id
                    }}
                    validationSchema={marketSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form className='loginform' style={{width:'100%'}}>
                        {isFailure && <Typography variant="body2" component={'p'}>There is some issue with adding seller. </Typography> }
                        {isSuccessful && <Typography variant="body2" component={'p'}>Seller added successfully.</Typography> }
                        <Grid item xs={12} sx={{display:'flex',}}>
                            <Grid item xs={12} md={12} xl={12} sx={{mr:1}}>
                                <FormControl variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='sellername' sx={{color:'text.primary'}}>
                                    Seller Name
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.seller_name} 
                                    placeholder='Enter Seller name'
                                    id='sellername'
                                    onChange={e => setFieldValue('seller_name', e.target.value)}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.seller_name}</Typography>
                            </FormControl>

                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='rating' sx={{color:'text.primary'}}>
                                    Seller Rating
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.seller_rating} 
                                    placeholder='Enter seller rating'
                                    id='rating'
                                    onChange={e => setFieldValue('seller_rating', e.target.value)}
                                />
                            </FormControl>
                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='review' sx={{color:'text.primary'}}>
                                    Seller Rating
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.seller_tot_reviews} 
                                    placeholder='Enter seller review'
                                    id='review'
                                    onChange={e => setFieldValue('seller_tot_reviews', e.target.value)}
                                />
                            </FormControl>
                            </Grid>
                        </Grid>
                    
                        <FormControl sx={{ m: 0, mt:3, mb:2, width:'100%', alignItems:'center' }}>
                            <Button sx={{width:'70%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                            onClick={() => handleSubmit(values)}>Add</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>

                </Grid>
        </CompModal>
    )
}

export default AddSeller;