import * as constants from '../constants/addlocations.constants';

export const initialState = {
  
  addLocationDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    locData: null,
    message: null,
  },
  isSessionExpired:false
};

export const addLocationReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.ADD_LOCATION:
      return {
        ...state,
        addLocationDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          locdata: null,
          errorMessage: null
        },
      };
    case constants.ADD_LOCATION_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        addLocationDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          errorMessage: null,
          locdata: action.response
        }
      };
    case constants.ADD_LOCATION_FAILED:
      return {
        ...state,
        addLocationDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          errorMessage: payload,
          locdata: null
        }
      };
      case constants.ADD_LOCATION_RESET:
        return {
          ...state,
          addLocationDetails: {
            isLoading: false,
            isSuccessful: false,
            isFailure: false,
            errorMessage: null,
            locdata: null
          }
        };
      
    default:
      return state;
  }
};
