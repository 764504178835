import * as constants from '../constants/countrystate.constants';

export const getCountryState = (payload) => ({
  type: constants.COUNTRY_STATE_GET,
  payload
});

export const getCountryStateSuccessful = (response) => ({
  type: constants.COUNTRY_STATE_SUCCESSFUL,
  response
});

export const getCountryStateFailed = (payload) => ({
  type: constants.COUNTRY_STATE_FAILED,
  payload
});
