import * as constants from '../constants/deletecompetitor.constants';

export const handleDeleteCompetitor = (payload) => ({
  type: constants.DELETE_COMPETITOR,
  payload
});

export const deleteCompetitorSuccessful = (response) => ({
  type: constants.DELETE_COMPETITOR_SUCCESSFUL,
  response
});

export const deleteCompetitorFailed = (payload) => ({
  type: constants.DELETE_COMPETITOR_FAILED,
  payload
});
