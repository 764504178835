import React from 'react';
import { Grid, Typography } from '@mui/material';
// import htmlContent from './contactform.html';


const Contact = props => {

    return (
        <Grid sx={{display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
            <Grid item sm={12} xs={6} md={6} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Typography variant='h3' component={'h2'}>Contact Us</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={6}>
                {/* <div dangerouslySetInnerHTML={{ __html: "<iframe title='zsfeedbackFrame' id='zsfeedbackFrame' width='600px' height='520px' name='zsfeedbackFrame' scrolling='no' allowtransparency='false' frameBorder='0' border='0' src='/contactform.html'>"}} /> */}
                <iframe title='zsfeedbackFrame' id='zsfeedbackFrame' src="https://docs.google.com/forms/d/e/1FAIpQLScM2ym2RGxo0YOmicw9rvDMFrJwp17U_U_bBqsuPFrKR29AhQ/viewform?embedded=true" width="640" height="475" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            {/* <iframe title='zsfeedbackFrame' id="zsfeedbackFrame" width="600px" height="520px" name="zsfeedbackFrame" scrolling="no" allowtransparency="false" frameBorder="0" border="0" src="/contactform.html"></iframe> */}
            {/* <iframe title='zsfeedbackFrame' id="zsfeedbackFrame" width="600" height="520" name="zsfeedbackFrame" scrolling="no" allowtransparency="false" frameborder="0" border="0" src="https://desk.zoho.in/support/fbw?formType=AdvancedWebForm&fbwId=edbsn9cf5965a42e1815e5ab56d96cddf2334a8f7378a7d67279319f76a3d5a2b4db6&xnQsjsdp=edbsn54d355583d7af52e31cbdfb37896985b&mode=showNewWidget&displayType=iframe"></iframe> */}
            </Grid>
            
            {/* <iframe src='/assets/contact.html' style={{width:'100%', height:500, border:0, position:'absolute', bottom:0}} /> */}
            {/* <iframe src='https://ottixhow.atlassian.net/servicedesk/customer/portal/1/group/1/create/1'  */}
            {/* style={{border:'unset'}} /> */}
        </Grid>
    )


}

export default Contact;
