import React, { useEffect } from 'react';
// import { useParams} from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Typography, Grid, Divider, Button, FormControl, InputBase,InputLabel } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import {editProduct, editProductReset} from '../../redux/actions/editProducts.actions'
import selectEditedProducts from '../../redux/selectors/editProduct.selector'
import useActions from '../../redux/use-actions';


import CompModal from '../Modal'

const editProductSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })

const EditKeyword = props => {
     const { productInformation, handleClose } = props;
    // const params = useParams()
    // const { id } = params;
    const dispatch = useDispatch();
    const { editedProduct } = useSelector(selectEditedProducts);
    const { isSuccessful, isFailure, message} = editedProduct;
    //  console.log('addedcompete', isSuccessful)
    const actions = useActions({ editProduct, editProductReset });
    
    const {prd_id, prd_name, prd_desc, prd_brand, prd_rating, prd_qa_approved, prd_offers, prd_offer, prd_is_match_enabled, prd_is_crawl_enabled, prd_is_active, prd_matched, prd_tot_reviews,  prd_spec, prd_notification_enabled, category, prd_variants, keyword, prd_medium_image, prd_small_image, dept, prd_upc, prd_asin, prd_uom, prd_url, prd_no_of_pieces, prd_size, activeLocator, locations } = productInformation;

    // const abc= [{'fender': 'Ibanez Axion Label RGD71ALPA Electric Guitar - Charcoal Burst Black Flat'}, {'guitar center': 'Ibanez Axion Label RGD71ALPA Electric Guitar - Charcoal Burst Black Flat'}];
    // console.log(abc);
    console.log(JSON.parse(keyword));
    // console.log(Array(productInformation.keyword.substring(1, productInformation.keyword.length-1)))
    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));


    useEffect(() =>{
        if(isSuccessful){
            setTimeout(() =>{
                dispatch(actions.editProductReset())
                handleClose();
                window.location.reload()
            }
            , 1000)
        }
    }, [handleClose, dispatch, isSuccessful, actions])

    useEffect(() =>{
        if(isFailure){
            setTimeout(() =>{
                dispatch(actions.editProductReset())
            }
            , 1000)
        }
    }, [dispatch, actions, isFailure ])
    
    const handleSubmit = (values) =>{
        values.keyword = JSON.stringify(values.keywords);
         console.log('values ', values);
        // e.preventDefault();
        // e.stopPropogation();
        values.prd_qa_approved = true;
        actions.editProduct(values)
    }
      

    return (
        <CompModal {...props}>
            <Typography variant="h5" component={'h5'}>Edit Keyword</Typography>
            <Divider />
            <Grid item sx={{mt:1}}>
            {isFailure && <Typography variant='body2'>{message}</Typography>}
            </Grid>
            <Grid item sx={{mt:1}}>
                {isSuccessful && <Typography variant='body2'>Keyword updated successfully.</Typography>}
            <Formik enableReinitialize
            initialValues={{
                prd_id: prd_id || '',
                prd_name: prd_name || '',
                prd_desc: (prd_desc && prd_desc.toLowerCase() !== 'nan' && prd_desc) || '',
                prd_medium_image: prd_medium_image || '',
                prd_small_image: prd_small_image || '',
                prd_brand: prd_brand || '',
                category_id: category.category_id || '',
                dept_id : dept.dept_id || '',
                loc_id: locations[0].loc.loc_id || 'loc',
                prd_variants:prd_variants || '',
                // productZip: '040404',
                keywords: (keyword.toLowerCase() !== 'nan' && JSON.parse(keyword)) || '',
                prd_url: prd_url || '',
                prd_upc: (prd_upc.toLowerCase() !== 'nan' && prd_upc) || '',
                prd_asin: (prd_asin.toLowerCase() !== 'nan' && prd_asin) || '',
                prd_uom: prd_uom || '',
                prd_size: prd_size || '',
                prd_rating: prd_rating || 0, 
                prd_is_match_enabled: prd_is_match_enabled || true, 
                prd_is_crawl_enabled: prd_is_crawl_enabled || true, 
                prd_is_active: prd_is_active || true, 
                prd_matched: prd_matched || 0,
                prd_tot_reviews: prd_tot_reviews || 0,  
                prd_spec: prd_spec || '',
                prd_qa_approved: prd_qa_approved || false, 
                prd_notification_enabled: prd_notification_enabled || true,
                prd_no_of_pieces: prd_no_of_pieces || '',
                // keys: keys || '',
                prd_mrp: activeLocator.filtered_mrp || 0,
                prd_sales_price: activeLocator.filtered_sales_price || 0,
                prd_norm_price: activeLocator.filtered_norm_price || 0,
                prd_offers: prd_offers || '',
                prd_offer: prd_offer || '',
                seller_id: locations[0].seller.seller_id
                
            }}
            validationSchema={editProductSchema}
            >
                {({ errors, 
                    setFieldValue,
                    //handleSubmit,
                    values 
                }) => (
                <FormGroup sx={{flexFlow:'column'}}>
                    <Grid item xs={12} sx={{display:'flex'}}>
                        <Grid item xs={12} sx={{mr:2, width:'100%'}}>
                             {/* {console.log(values.keyword)} */}
                        {
                            values.keywords.map((item, index) => {
                                const itemsp = Object.entries(item)[0];
                                
                            return <FormControl variant="standard" sx={{mt:2, width:'100%'}} >
                                <InputLabel shrink htmlFor={`keyword${index}`} sx={{color:'text.primary'}}> {itemsp[0]} </InputLabel>
                                <BootstrapInput 
                                    defaultValue={itemsp[1]} 
                                    placeholder='Enter keyword'
                                    id={`keyword${index}`}
                                    sx={{width:'100%'}}
                                    onChange={e => {
                                        const obj = {};
                                        obj[itemsp[0]] = e.target.value;
                                        const tempKeys = [...values.keywords]
                                        tempKeys[index] = obj;
                                        setFieldValue('keyword', tempKeys)
                                    }}
                                />
                                <Typography color="red" variant="subtitle">{errors.productName}</Typography>
                            </FormControl>
                            })
                        }
                        </Grid>
                    </Grid>
                    
                    <Grid item xs={12} sx={{mt:2, display:'flex', width:'100%', justifyContent:'center'}}>
                
                        <Button sx={{ display:'flex', alignSelf:'center', width:'50%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}}
                        onClick={e => handleSubmit(values)} >Update</Button>
                    </Grid>
                </FormGroup>
                )}
            </Formik>
            
        </Grid>
        </CompModal>
    )

}


export default EditKeyword;