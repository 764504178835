import * as constants from '../constants/addmarketplace.constants';

export const handleAddMarketplace = (payload) => ({
  type: constants.ADD_MARKET_PLACE,
  payload
});

export const addMarketplaceSuccessful = (response) => ({
  type: constants.ADD_MARKET_PLACE_SUCCESSFUL,
  response
});

export const addMarketplaceFailed = (payload) => ({
  type: constants.ADD_MARKET_PLACE_FAILED,
  payload
});

export const addMarketplaceReset = (payload) => ({
  type: constants.ADD_MARKET_PLACE_RESET,
  payload
});
