import * as constants from '../constants/addcompetitors.constants';

export const handleAddCompetitor = (payload) => ({
  type: constants.ADD_COMPETITOR,
  payload
});

export const addCompetitorSuccessful = (response) => ({
  type: constants.ADD_COMPETITOR_SUCCESSFUL,
  response
});

export const addCompetitorFailed = (payload) => ({
  type: constants.ADD_COMPETITOR_FAILED,
  payload
});
