import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Switch, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
// import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
// import { styled } from '@mui/material/styles';




import * as notifyActions from '../../redux/actions/productnotify.actions'
import selectProductsNotifyDetails from '../../redux/selectors/productnotify.selector'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Notification = props => {
const {item, type} = props;
const {prd_id, prd_notification_enabled} = item;
const dispatch = useDispatch();
const { productNotifyDetails } = useSelector(selectProductsNotifyDetails);
const {isSuccessful, isFailure, message} = productNotifyDetails;
const [checkId, setCheckId] = useState('');
const checkStyle = {fontSize:14, borderRadius:1, border:'1px solid', borderColor:'text.primary', marginRight:0.5, }

const checkedIcons = () => {
    return <CheckIcon sx={{...checkStyle, color:'text.primary', backgroundColor:'background.default'}} />
}
const uncheckedIcons = () => {
    return <CheckIcon sx={{...checkStyle, color:'transparent', backgroundColor:'none'}} />
}

// console.log('isSuccessful', isSuccessful, isFailure, message)

useEffect(() =>{
    let timer = null;
    if(isSuccessful && prd_id === checkId){
        item.prd_notification_enabled = !item.prd_notification_enabled; 
     timer = setTimeout(() =>{
        dispatch(notifyActions.productNotifyReset())
        }, 1000)
        return (() => {setCheckId(''); clearTimeout(timer)})
    }
}, [isSuccessful]) // eslint-disable-line

useEffect(() =>{
    let timer = null;
    if(isFailure && prd_id === checkId){
     timer = setTimeout(() =>{
        dispatch(notifyActions.productNotifyReset())
        }, 1000)
        return (() => {setCheckId(''); clearTimeout(timer)})
    }
}, [isFailure]) // eslint-disable-line



const handleNotificationEnabled = (pId) =>{
    // setCheckId(pId)
    // dispatch(notifyActions.handleProductNotify({prd_id:pId}))
}

return (
    <>
    
    
    {
        type ==='select'
        ? <Switch size='small' checked={prd_notification_enabled} onChange={e=> handleNotificationEnabled(prd_id)} />
        : <Checkbox checkedIcon={checkedIcons()} checked={prd_notification_enabled} icon={uncheckedIcons()} 
            sx={{padding:'8px', color:'text.secondary', backgroundColor:'transparent'}} 
            onChange={e=> handleNotificationEnabled(prd_id)}
            />
    }
    {/* {isSuccessful && prd_id === checkId && <CheckRoundedIcon />} */}
    {isFailure && prd_id === checkId && message && <Dialog
        open={isFailure}
        TransitionComponent={Transition}
        keepMounted
        onClose={null}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{display:'flex', flexDirection:'column'}}>
          {<Typography variant='h5' component='h5'>{message}</Typography>}
        </DialogContent>
      </Dialog>
      }
    </>
    )
}

export default Notification

