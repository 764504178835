import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/productDetails.actions';
import * as constants from '../constants/productDetails.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';
//import { toast } from 'react-toastify';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
    Authorization: headers.Authorization
  }
}


export function* getMyProducts(request = {}) {
  const { payload = {} } = request;
  const{pPath, user_id} = payload;
  // console.log('payload ', payload)
   
  const apiConfig = getApiConfig()

  let productPath = payload !== ''? `${(paths.myProductURL)}?${pPath}` : `${paths.myProductURL}`
  const userRole = getItems(['role'])
  
  // console.log(pPath, user_id, Number(userRole));
  if(Number(userRole) === 2){
    productPath = pPath !== ''? `${paths.qaProductURL}?${pPath}` : `${paths.qaProductURL}`
  }
  try {
    const response = Number(userRole) === 2 ? 
    yield call(NetworkApi.get,productPath, [{user_id:1}], apiConfig ) : 
      yield call(NetworkApi.get, productPath, {}, apiConfig, ); 
    // console.log('res', response);

    if (response && !response.detail) {
      // console.log('inside')
          yield put(actions.productTotalSuccessful(response));
    } else {
      // console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
      );
      yield put(actions.productTotalFailed(message));
    }
  } catch (error) {
    // console.log('uuu ', error)
    const message = error?.response?.status ? (
        error?.response?.status
    ) : (
      ''
    );
    yield put(actions.productTotalFailed(message));
  }
}

export function* fetchMyProducts() {
  yield takeLatest(constants.MYPRODUCT_GET, getMyProducts);
}
