import * as constants from '../constants/productDetails.constants';

export const getQATotalProducts = (payload) =>({
  type: constants.MYPRODUCT_GET,
  payload
})
export const getTotalProducts = (payload) => ({
  type: constants.MYPRODUCT_GET,
  payload
});
export const productTotalSuccessful = (response) => ({
    type: constants.MYPRODUCT_SUCCESSFULL,
    response
  });
  
  export const productTotalFailed = (payload) => ({
    type: constants.MYPRODUCT_FAILED,
    payload
  });
  export const productTotalReset = (payload) => ({
    type: constants.MYPRODUCT_RESET,
    payload
  });
  
  export const getProductDetails = (payload) => ({
    type: constants.PRODUCTDETAILS_GET,
    payload
  });
  export const productDetailsSuccessful = (response) => ({
    type: constants.PRODUCTDETAILS_SUCCESSFULL,
    response
  });
  
  export const productDetailsFailed = (payload) => ({
    type: constants.PRODUCTDETAILS_FAILED,
    payload
  });
  export const productDetailsReset = (payload) => ({
    type: constants.PRODUCTDETAILS_RESET,
    payload
  });
  

  export const getCompetitors = (payload) => ({
    type: constants.COMPETITORS_GET,
    payload
  });
  export const competitorsSuccessful = (response) => ({
    type: constants.COMPETITORS_SUCCESSFULL,
    response
  });
  export const competitorsReset = (response) => ({
    type: constants.COMPETITORS_RESET,
    response
  });
    

  
  export const competitorsFailed = (payload) => ({
    type: constants.COMPETITORS_FAILED,
    payload
  });
  
  export const getLocations = (payload) => ({
    type: constants.LOCATIONS_GET,
    payload
  });
  export const locationsSuccessful = (response) => ({
    type: constants.LOCATIONS_SUCCESSFULL,
    response
  });
  
  export const locationsFailed = (payload) => ({
    type: constants.LOCATIONS_FAILED,
    payload
  });
  


  



