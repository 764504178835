import { call, put, takeLatest  } from 'redux-saga/effects';  //takeLatest
// import { isEmpty, } from 'lodash';
import * as actions from '../actions/deletecompetitor.actions';
import * as constants from '../constants/deletecompetitor.constants';
import NetworkApi from '../../api/NetworkApi';
import { getItems } from '../../utils/storage';
import paths from '../../api/path';

const getApiConfig = () => {
  const [
    token
  ] = getItems([
    'token',
  ]);

  const headers = {
    Authorization: `token ${token}`,
  };

  return {
   // headers,
    Authorization: headers.Authorization
  }
}

// export function* resetProduct(){}


export function* handleDeleteCompetitor(request = {}) {
  
  const { payload = {} } = request;
  const apiConfig = getApiConfig();
  // console.log('handleDeleteCompetitor ', payload);
  
  try {
    const response = yield call(NetworkApi.delete, `${paths.competitorURL}${payload}/`, {}, apiConfig);
     console.log('delete competitor response', response)
    if (response && !response?.detail) {
        yield put(actions.deleteCompetitorSuccessful(response));
    } else {
      console.log('error ', response)
      const message = response?.detail ? (
        response?.detail
      ) : (
        ''
        // <FormattedMessage id="notProcess" />
      );
      yield put(actions.deleteCompetitorFailed(message));
    }

  } catch (error) {
    const message = error?.response?.data?.detail ? (
      error?.response?.data?.detail
    ) : (
      ''
      // <FormattedMessage id="notProcess" />
    );
    yield put(actions.deleteCompetitorFailed(message));
  }
}

export function* deleteCompetitor() {
  yield takeLatest(constants.DELETE_COMPETITOR, handleDeleteCompetitor);
}
