import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Grid, Box, Stack, Paper, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import {styled} from '@mui/material/styles'

import { handleDeleteCompetitor, deleteCompetitorFailed  } from '../../../../redux/actions/deletecompetitor.actions';
import deleteCompetitorSelector from '../../../../redux/selectors/deletecompetitor.selector'
import useActions from '../../../../redux/use-actions';
import AlertDialogSlide from '../../../../components/Dialog/';


// import UseCompetitor from './useCompetitor';
import PriceVariationChart from '../../../../components/charts/PriceVariationChart';
import ComeptitorItem from './competitorItem';


const Competitor = props => {

    const { competitorInformation, handleReload } = props;
    const actions = useActions({ handleDeleteCompetitor, deleteCompetitorFailed });
    const [value, setValue] = useState('1');
    const [marketLabel, setMarketLabel] = useState([])
    const [deletePop, setDeletePop] = useState({isVisible:false, deleteId:null})
    const { deleteCompetitorDetails } = useSelector(deleteCompetitorSelector)
    const { isSuccessful } = deleteCompetitorDetails;
    // const [marketList, setMarketList] = useState([]);
    

 useEffect(() => {
    if(isSuccessful){
    setTimeout(()=>{
        actions.deleteCompetitorFailed();
        handleReload();
    }, 1000)
 }
 }, [isSuccessful, actions]) // eslint-disable-line

    const updateCompetitor = (marketName) =>{
        const filteredProducts = competitorInformation.filter(
            (product) => product.market_place.market_place_name === marketName
        );
        filteredProducts.sort(
        (a, b) =>  {
            if (a.comp_prd_score === b.comp_prd_score) {
              return b.comp_prd_sales_price - a.comp_prd_sales_price;
            } else {
              return b.comp_prd_score - a.comp_prd_score;
            }
          }
        );
        return filteredProducts;
    }
    

    useEffect(() => {
        if(!isEmpty(competitorInformation)){
            const marketLabel = [...new Set(competitorInformation.map(competitor => competitor.market_place_name).flat())]
            setMarketLabel(marketLabel.sort());
        }
    }, [competitorInformation]) //

    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#232D3B',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: '#FFFFFF',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        boxShadow:'none'
      }));

    const Tabe = styled(Tab)(({theme}) => {
        return {
            '.MuiTab-root':{
                '& .Mui-selected': {
                    backgroundColor: '#559EFF',
                    color:'#0E2B5A'
                }
            }
        }
    })
    

    
    const itemStyle ={marginTop:1, display:"flex", flexFlow:'column', width:"100%", padding:'25px 0px 25px 20px', justifyContent:'space-between', alignItems:'start'}


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteFailed = () =>{
    setDeletePop({isVisible:false, deleteId:null});
  }
  const handleDeleteSuccess = () =>{
    // console.log('handleDeleteSuccess ');
    actions.handleDeleteCompetitor(deletePop.deleteId)
    setDeletePop({isVisible:false, deleteId:null});
  }
  const handleDelete = (pId) =>{
    // actions.handleDeleteCompetitor(pId)
    setDeletePop({isVisible:true, deleteId:pId});
  }

  
    return (
        <Grid>
            {deletePop.isVisible && <AlertDialogSlide isAlert={true} handleSuccess={handleDeleteSuccess} handleFailed={handleDeleteFailed}/>}
            { isSuccessful && <Typography variant='body2'>Competitor deleted successfully.</Typography>}
            {isEmpty(competitorInformation) ?
                <Grid item xs={12} sx={{height:200, display:'flex', alignItems:'center', justifyContent:'center'}} >
                    <Typography>No Competior Available</Typography>
                </Grid>
            :
            <TabContext value={value}>
            <Box sx={{ borderBottom: 2, borderColor: '#515151' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {marketLabel.map((item, index) => {
                       return <Tabe key={`tab_${index}`} 
                            sx={{color:'text.secondary', border:'none'}} 
                            label={item} value={`${Number(index+1)}`} 
                        />
                    })}
                </TabList> 
            </Box>
            {marketLabel.map((compet, index) => {
                const updatedArr = updateCompetitor(compet)
                return <TabPanel key={`competitor_${index}`} value={`${Number(index+1)}`} sx={{padding:2}} >
                    <Stack spacing={0} xs={10} sx={{marginTop:0}}>
                    {updatedArr.map(item => 
                        {
                            return <Item key= {`competitor${Math.random()}`} sx={{...itemStyle}}>
                                <ComeptitorItem competitordata={item} handleDelete={handleDelete} setDeletePop={setDeletePop} {...props}/>
                            </Item>
                    })
                    }
                    <PriceVariationChart marketValue= {value} marketName={compet} {...props} />
                </Stack>
                </TabPanel>
            })}
        </TabContext>

            }
            
        </Grid>
    )
}

export default Competitor;