import * as constants from '../constants/productLocations.constants';

export const initialState = {
  
  productlocationDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    prdlocdata: null,
    message: null,
  },
};

export const productLocationsReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.PRODUCT_LOCATION_GET:
      return {
        ...state,
        productlocationDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          prdlocdata: null,
          errorMessage: null
        },
        productsCreated: false
      };
    case constants.PRODUCT_LOCATION_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        productlocationDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          prdlocdata: action.response
        }
      };
    case constants.PRODUCT_LOCATION_FAILED:
      return {
        ...state,
        productlocationDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          prdlocdata: null
        }
      };
      case constants.PRODUCT_LOCATION_RESET:
      return {
        ...state,
        productlocationDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: false,
          message: null,
          prdlocdata: null
        }
      };
    default:
      return state;
  }
};
