import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Divider, Button, InputBase, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';

import CountryState from '../../components/CountryState';
import selectCountryStateDetails from '../../redux/selectors/countrystate.selector'

import * as actions from '../../redux/actions/addlocations.actions'
import selectAddLocationDetails from '../../redux/selectors/addlocations.selector'

import CompModal from '../Modal'

const locationSchema = Yup.object().shape({
    name: Yup.string().min(6, 'Too short - should be 6 chars minimum!').required('Required'),
    url: Yup.string().min(8, 'Too short - should be 8 chars minimum!').required('Required')
  })



const AddLocations = props =>{
    const dispatch = useDispatch()
    const { countryStateDetails } = useSelector(selectCountryStateDetails);
    const { countrydata } = countryStateDetails;
    const {addLocationDetails} = useSelector(selectAddLocationDetails)
    const { isSuccessful } = addLocationDetails
    const [cntyState, setCntyState] = useState([])
    const [stateValue, setStateValue] = useState('state')

    const formStyle = {width:'100%'};

    useEffect(() =>{
        if(isSuccessful){
            setTimeout(()=>{
                dispatch(actions.addLocationFailed())
            }, 1000)
        }
      }, [isSuccessful, dispatch])


    const BootstrapInput = styled(InputBase)(({ theme }) => ({
        'label + &': {
          marginTop: theme.spacing(2),
          width: '100%',
        },
        '& .MuiInputBase-input': {
          borderRadius: 4,
          position: 'relative',
          backgroundColor: 'transparent', //theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
          border: '1px solid #ced4da',
          fontSize: 11,
          width: '100%',
          padding: '10px 12px',
          color: theme.palette.text.primary,
          transition: theme.transitions.create([
            'border-color',
            'background-color',
          ]),
          // Use the system font instead of the default Roboto font.
          fontFamily:'Inter',
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
        },
      }));
    
    //   console.log('countryStateDetails ', countryStateDetails)

    const handleSelectCountry = (e, setFieldValue) =>{
        // console.log('country_name ', e.target.value);
        setFieldValue('country_name ', e.target.value);
        const result = countrydata.filter(item => item.name === e.target.value)
         console.log('result ', result);
         setFieldValue('country_short_name', result[0].iso3)
         setCntyState(result[0].states)
    }
     const handleSelectState = (e, setFieldValue) =>{
        setFieldValue('state_name', e.target.value)
        const result = cntyState.filter(item => item.name === e.target.value)
        setFieldValue('state_short_name', result[0].state_code)
        setStateValue(e.target.value);
    }
 
    const handleSubmit = (values) =>{
        console.log('submit ', values)
        dispatch(actions.handleAddLocation(values))
    }
    return (
        <CompModal {...props} modalOpen={true}>
                <Typography variant="h5" component={'h5'}>Add Location</Typography>
                <Divider />
                {isSuccessful && <Typography variant="body2" component={'p'}>Location added successfully.</Typography>}
                <Grid item sx={{width:'100%'}}>
                <Formik
                   // enableReinitialize
                    initialValues={{ country_name: '', country_short_name: '',  state_short_name: '', state_name: '',
                    loc_zip:'', loc_name:'' 
                    }}
                    validationSchema={locationSchema}
                    >
                {({ errors, 
                    setFieldValue,
                    values 
                }) => (
                    <Form className='loginform' style={{width:'100%'}}>
                        {/* {isSuccessful && <Typography variant="body2" component={'p'}>Location added successfully.</Typography> } */}
                        <Grid item xs={12} sx={{display:'flex',}}>
                            <Grid item xs={12} md={12} xl={12} sx={{mr:1}}>
                                <FormControl variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='countryname' sx={{position:'relative',color:'text.primary'}}>
                                    Country
                                    </InputLabel> 
                                <CountryState handleChange={e => {
                                    // console.log('e ', e.target.value);
                                    handleSelectCountry(e, setFieldValue)
                                }}/>
                                
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_name}</Typography>
                            </FormControl>

                            <FormControl id={'urls'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='url' sx={{position:'relative', color:'text.primary'}}>
                                    State
                                    </InputLabel>
                                <Select variant='outlined' value={stateValue} size='small' onChange={e => handleSelectState(e, setFieldValue)}>
                                    <MenuItem value='state'>Select State</MenuItem>
                                    {
                                        cntyState.map(item => {
                                            return <MenuItem value={item.name}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.market_place_url}</Typography>
                            </FormControl>
                            <FormControl id={'brands'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='markgetlogo' sx={{color:'text.primary'}}>
                                    Location name 
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.loc_name} 
                                    placeholder='Write location name '
                                    id='markgetlogo'
                                    onChange={e => setFieldValue('loc_name', e.target.value)}
                                />
                            </FormControl>
                            <FormControl id={'categories'} variant="standard" sx={{mt:2}} style={formStyle}>
                                <InputLabel shrink htmlFor='size' sx={{color:'text.primary'}}>
                                    Zip code
                                    </InputLabel>
                                <BootstrapInput 
                                    value={values.loc_zip} 
                                    placeholder='Enter location zip '
                                    id='size'
                                    onChange={e => setFieldValue('loc_zip', Number(e.target.value))}
                                />
                                <Typography color="red" variant="subtitle" sx={{width:'70%'}}>{errors.brand}</Typography>
                            </FormControl>
                            </Grid>
                        </Grid>
                    
                        <FormControl sx={{ m: 0, mt:3, mb:2, width:'100%', alignItems:'center' }}>
                            <Button sx={{width:'70%', backgroundColor:'#559EFF', borderColor:'#1C3B6E', color:'#000000'}} 
                            onClick={() => handleSubmit(values)}>Add</Button>
                        </FormControl>
                    </Form>
                )}
                </Formik>

                </Grid>
        </CompModal>
    )
}

export default AddLocations;