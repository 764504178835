import * as constants from '../constants/productnotify.constants';

export const handleProductNotify = (payload) => ({
  type: constants.PRODUCT_NOTIFICATION,
  payload
});

export const productNotifySuccessful = (response) => ({
  type: constants.PRODUCT_NOTIFICATION_SUCCESSFUL,
  response
});

export const productNotifyFailed = (payload) => ({
  type: constants.PRODUCT_NOTIFICATION_FAILED,
  payload
});

export const productNotifyReset = (payload) => ({
  type: constants.PRODUCT_NOTIFICATION_RESET,
  payload
});
