import * as constants from '../constants/productnotify.constants';

export const initialState = {
  
  productNotifyDetails: {
    isLoading: false,
    isSuccessful: false,
    isFailure: false,
    data: null,
    message: null,
  },
  isSessionExpired:false
};

export const productNotificationReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    
    case constants.PRODUCT_NOTIFICATION:
      return {
        ...state,
        productNotifyDetails: {
          isLoading: true,
          isSuccessful: false,
          isFailure: false,
          data: null,
          message: null
        },
      };
    case constants.PRODUCT_NOTIFICATION_SUCCESSFUL:
    //  console.log('reducer', action)
      return {
        ...state,
        productNotifyDetails: {
          isLoading: false,
          isSuccessful: true,
          isFailure: false,
          message: null,
          data: action.response
        }
      };
    case constants.PRODUCT_NOTIFICATION_FAILED:
      return {
        ...state,
        productNotifyDetails: {
          isLoading: false,
          isSuccessful: false,
          isFailure: true,
          message: payload,
          data: null
        }
      };
      case constants.PRODUCT_NOTIFICATION_RESET:
        return {
          ...state,
          productNotifyDetails: {
            isLoading: false,
            isSuccessful: false,
            isFailure: false,
            message: null,
            data: null
          }
        };
      
    default:
      return state;
  }
};
